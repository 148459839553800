// @mui
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
import {
    Card,
    Box, CardHeader, Stack,Divider,Grid
}
from '@mui/material';
import Iconify from '../../../components/Iconify';
import {
    MatchDonutChart
} from '.';

DonutChartFunction.propTypes = {
    directionCount: PropTypes.array,
    header: PropTypes.array,
};

export default function DonutChartFunction({
    header,
    directionCount,
}) {
    
    return (
        <Card style={{marginTop:"2%",marginLeft:"2%",width:'100%'}} xs={12} md={12} lg={12}>
              <Grid container spacing={2}  >
                <Grid item xs={12} sm={12} md={8}>
                <CardHeader title={header} />
                </Grid>
                <Grid item xs={12} sm={12} md={4} sx={{marginTop:'3%',ml:{xs:'5%',sm:'5%',md:'0%'}}}>
                <Iconify
                    icon={'akar-icons:circle-fill'}
                    sx={{ width: 16, height: 16, ml: 1, color: "#21a925;"}}
                /><span>10</span>
                <Iconify
                    icon={'akar-icons:circle-fill'}
                    sx={{ width: 16, height: 16, ml: 1, color: "#22bdae;"}}
                /><span>9</span>
                <Iconify
                    icon={'akar-icons:circle-fill'}
                    sx={{ width: 16, height: 16, ml: 1, color: "#d5be1f;"}}
                /><span>8</span>
                <Iconify
                    icon={'akar-icons:circle-fill'}
                    sx={{ width: 16, height: 16, ml: 1, color: "#1963bf;"}}
                /><span>7</span>
                <Iconify
                    icon={'akar-icons:circle-fill'}
                    sx={{ width: 16, height: 16, ml: 1, color: "#bf2819;"}}
                /><span>Less than 6</span>
                </Grid>
            </Grid>
            <Box sx={{ p: 3, pb: 1,
                // backgroundImage:`url(${`/static/shoot/paper_target.jpg`})`,backgroundRepeat:'no-reapeat',backgroundSize:'100%' 
            }} dir="ltr" >
            <Grid item xs={12} md={12} lg={12}>
                
                {directionCount.length >0 ? 
                  <div style={{marginLeft:"6%"}}>
                      {/* {console.log(directionCount)} */}
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 1, md: 6 }} >
                        {directionCount.map((item,index) =>{return(item.name === 'A' ?<MatchDonutChart sx={{backgroundColor:'transparant'}} Details={directionCount[index]} name="Top Left"/>:null)})}
                        <Divider orientation="vertical" flexItem />
                        {directionCount.map((item,index) =>{return(item.name === 'B'?<MatchDonutChart Details={directionCount[index]} name="Top Right"/>:null)})}
                    </Stack>
                    <Divider variant="middle" style={{marginTop:'2%',width:'80%'}}/>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 1, md: 6 }}>
                        {directionCount.map((item,index) =>{return(item.name === 'C'? <MatchDonutChart Details={directionCount[index]} name="Bottom Left"/>:null)})}
                        <Divider orientation="vertical" flexItem />
                        {directionCount.map((item,index) =>{return(item.name === 'D'?<MatchDonutChart Details={directionCount[index]} name="Bottom Right"/>:null)})}
                    </Stack>
                </div>
                  :null }
            </Grid>
            </Box>
        </Card> 
    );
}
