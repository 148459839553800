import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useState, useEffect,useRef } from 'react';
import axios from "axios";
import toast from "react-hot-toast";
import Scrollbar from "react-scrollbar";
// material
import CompetitionDashboard from "./CompetitionDashboard";
import {
  Container,Stack,Grid,Button,Tab,Box,Tabs,Typography,
  TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
  TextField
} from '@mui/material';
// components
import Page from '../components/Page';
import Paper from '@mui/material/Paper';
import Iconify from '../components/Iconify';
import Toast from "../components/Toast";
import Logo from '../components/Logo';
import "./style.css"
import {url} from '../constants'


const ShootersList = () => {

  const { competitionId } = useParams()
  const [loading, setLoading] = React.useState(false);
  const [valueTab, setValueTab] = React.useState(0);
  const [user, setUser] = React.useState([{displayName:'',academy:[{displayName:''}]}]);
  const [coach, setCoach] = React.useState([{displayName:'',academy:[{displayName:''}]}]);
  const [academy, setAcademy] = React.useState([{displayName:'',academy:[{displayName:''}]}]);
  const [searchFocus, setSearchFocus] = React.useState(false);
  const [newData, setNewData] = React.useState({value:''});

  useEffect(() => {
    setLoading(true);
    getUsers();
}, []);

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
const { children, value, index, ...other } = props;
return (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`vertical-tabpanel-${index}`}
    aria-labelledby={`vertical-tab-${index}`}
    {...other}
  >
    {value === index && (
      <Box sx={{ p: 3 }}>
        {children}
      </Box>
    )}
  </div>
);
}

const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

const getUsers = () =>{
  axios
      .post(`/api/user/userRoleWise`, {role:"student"})
      .then((response) => {
        // console.log("search *** ",response.data.data)
        setUser(response.data.data)
        getCoach();
        getAcademy();
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response.data);
      });
}

const getCoach = () =>{
  axios
      .post(`/api/user/userRoleWise`, {role:"coach"})
      .then((response) => {
        // console.log("search *** ",response.data.data)
        setCoach(response.data.data)
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response.data);
      });
}

const getAcademy = () =>{
  axios
      .post(`/api/user/userRoleWise`, {role:"academy"})
      .then((response) => {
        // console.log("search *** ",response.data.data)
        setAcademy(response.data.data)
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response.data);
      });
}

const onsearch = (props) => (event)=>{
  setNewData({...newData,value:event.target.value})
}

const Search = (e) =>{
  console.log(e.target)
  let search = newData.value
  let role = 'student'
  if (e.target !== undefined) {
    search = e.target.value
  }
  if(valueTab == 0){
    role = 'student'
  }else if(valueTab == 1){
    role = 'coach'
  }
  // console.log(filterValues)
  setNewData({...newData,value:search})
  // console.log(search)
  axios
    .post(`api/user/userFilterRoleWise`, {displayName:search,role:role})
    .then((response) => {
      // console.log(response.data.data)
      if(valueTab == 0){
        setUser(response.data.data)
      }else if(valueTab == 1){
        setCoach(response.data.data)
      }
    })
    .catch((error) => {
      console.log(error)
      toast.error(error.message);
    });
}

const table = (data) => {  
  return (
        <div>
        {/* <Scrollbar smoothScrolling={true} style={{ height: window.location.href.indexOf("public") > -1?"55vh":"60vh", width: "100%", paddingRight: "1rem"}} >  */}
        {data.length >0 ? 
        <div>
        <TableContainer component={Paper}  sx={{}}>
          <Table stickyHeader sx={{ minWidth: 350 }} size="small" aria-label="a dense table" >
            <TableHead stickyHeader>
              <TableRow >
                <TableCell align="left" sx={{fontSize:"0.975rem"}}>Name</TableCell>
                {/* <TableCell align="left" sx={{fontSize:"0.975rem"}}>Unique Name</TableCell> */}
                <TableCell align="left" sx={{fontSize:"0.975rem"}}>Academy</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data
              .map((row,index) => {
                return (
                <TableRow tabIndex={-1} key={row._id} sx={{ "&:hover": { cursor: row.uniqueName != undefined && row.uniqueName != ''?"pointer":"cursor" } }}
                  onClick={() => {if(row.uniqueName != undefined && row.uniqueName != ''){
                    window.open(`${url}/${row.uniqueName}`, '_blank');}}}>                 
                  <TableCell align="left" sx={{fontSize:"0.875rem"}}>{row.displayName}</TableCell>       
                  {/* <TableCell align="left" sx={{fontSize:"0.875rem"}}>{row.uniqueName}</TableCell> */}
                  <TableCell align="left" sx={{fontSize:"0.875rem"}}>{row.academy[0] !=undefined ? row.academy[0].displayName.toUpperCase():"NA"}</TableCell>
                </TableRow>
                )
              })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      :<p>No data</p>}
      {/* </Scrollbar> */}
      </div>
  )}


  return (
    <Page title="Shooters List">
      <Toast />
      {/* <Container> */}
      <Grid container>
      <Grid item xs={1}>
          <Logo disabledLink={true} user={undefined} sx={{display:{xs:"none",sm:"none",md:"block"}}}/>
        </Grid>
        <Grid item xs={11} mt={{md:1,sm:2,xs:2}} >
        <Typography variant="h3" sx={{textAlign:'center'}}>
        Intelliverse Data
        </Typography> 
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              variant="scrollable"
              value={valueTab}
              onChange={handleChangeTab}
              aria-label="basic tabs example"
            // sx={{ borderRight: 1, borderColor: 'divider',width:{xs:250,sm:100,md:100} }}
            >
              <Tab wrapped label="User" {...a11yProps(0)} />
              <Tab wrapped label="Coach" {...a11yProps(1)} />
              <Tab wrapped label="Academy" {...a11yProps(2)} />
            </Tabs> 
          </Box>

          <marquee style={{color:'red'}}>
              Update your Unique Name in your profile to view our page
            </marquee>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
            <Paper
              component="form"
              sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: {xs:350,sm:500,md:700},ml:{xs:2,sm:7,md:8} }}
            >
          <TextField
             id="filled-multiline-static"
             label="search by name"
              value={newData.value}
              fullWidth
              size="small"
              autoFocus={searchFocus}
              onChange={onsearch()}
            />
          <Button onClick = {(e) => { Search(newData);} }>Search</Button>
          </Paper>
          </Stack>
          <TabPanel value={valueTab} index={0}>            
            {table(user)}
          </TabPanel>
          <TabPanel value={valueTab} index={1}>
            {table(coach)}
          </TabPanel>
          <TabPanel value={valueTab} index={2}>
            {table(academy)}
          </TabPanel>
        </Grid>
      </Grid>
      {/* </Container> */}
    </Page>
  );
}

 
export default ShootersList;