import * as React from 'react';
import axios from "axios";
import toast from "react-hot-toast";
import Scrollbar from "react-scrollbar";
import { useState, useEffect } from 'react';
// material
import {
  Stack,
  Button,
  Typography,
  Container, Modal, Box, Card, CardContent, Grid
} from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import Toast from "../components/Toast";
import "./style.css"
import {userDetails} from '../constants'
import { stubFalse } from 'lodash';
import { AppWidgetSummary} from '../sections/@dashboard/app';

const Analytics = () => {

  const [user, setUser] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState({});
  const data = [{
    name: "Trend Analysis",
    score: "100 Points"
  }, {
    name: "Shot Analysis",
    score: "500 Points"
  }, {
    name: "Shot Direction Analysis",
    score: "500 Points"
  }, {
    name: "Potential Score Analysis",
    score: "300 Points"
  }, {
    name: "comparative Analysis",
    score: "200 Points"
  }, {
    name: "Consistency Analysis",
    score: "250 Points"
  }, {
    name: "Summary Analysis",
    score: "100 Points"
  }, {
    name: "Variation Analysis",
    score: "150 Points"
  }]

  useEffect(() => {
    getUser();
  }, []);

  const getUser = () => {
     axios
       .post(`/api/user/getParticularUserByEmail`, {
         "emailID":userDetails.email
       })
       .then((response) => {
         const userData = response.data.data;
         setUser(userData);
       })
       .catch((error) => {
         console.log(error)
         toast.error(error.response.data);
       });
  }

  return (
    <Page title="Analytics">
      < Toast />
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>        
          <Typography variant="h4" gutterBottom>
            Analytics
          </Typography>         
        </Stack> 
      <div>
        <Scrollbar style={{ height: "61vh", width: "100%", paddingRight: "1rem" }} > 
        <Grid container spacing={2} mb={2} >
        {data.length >0 ? data.map((item,index) => {
          return (
            <Grid item xs={6} sm={3} md={3} >
            <AppWidgetSummary title= {item.name} score={item.score} sx={{"&:hover": { cursor: "pointer",color:"blue" }}} icon={'ant-design:apple-filled'} onClick={()=>{setOpen(true),setSelectedItem(item)}}/>
            </Grid>
          )
        }):
        <Box sx={{marginTop:{xs:'20%',sm:'12%',md:'1%'},marginLeft:{sm:'20%',md:'29%'}}}>
          <Typography variant="h4" sx={{marginLeft:{xs:'23%',sm:'17%',md:'17%'}}} >No Data</Typography> 
          <img src="/static/illustrations/empty_bin.png" alt="No Details"  style={{width:'300px'}}/>
        </Box>
        }
        </Grid>
        </Scrollbar>
        </div>
       <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            component="form"
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '60%',
              bgcolor: 'background.paper',
              p: 4,
            }}
            noValidate
            autoComplete="off"
          >          
          <Typography variant="h5" gutterBottom>
            For {selectedItem.name} Report, please contact support@intellishoot.com
          </Typography>
          <Stack
              alignItems="center"
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
              mt={2}
              mb={2}
            >             
            <Button variant = "outlined" color = "error" onClick = {() => {setOpen(false)}} >
              Close
            </Button>
            </Stack>
        </Box>
        </Modal>
      </Container>
    </Page>
  );
}
export default Analytics;