import * as React from 'react';
import axios from "axios";
import toast from "react-hot-toast";
import Scrollbar from "react-scrollbar";
import { useState, useEffect } from 'react';
// material
import { DataGrid } from '@mui/x-data-grid';
import {
  Stack,
  Button,
  Typography,
  Container,Modal,
  Card, Box, Grid, TextField,CardContent,CardActions,CardHeader,
  TableContainer, Table, Paper, TableHead, TableRow, TableCell, TableBody, TablePagination,
  ToggleButtonGroup, ToggleButton,
  FormControlLabel, Checkbox, 
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { EditorState, convertToRaw, convertFromHTML,ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import Toast from "../components/Toast";
import "./style.css"
import {competitionCategory,Type,userDetails,teamType,Gender,matchType,shooterCategory} from '../constants'


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const competition = () => {

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [alignment, setAlignment] = React.useState('card');
  const [open, setOpen] = React.useState(false);
  const [CompSave, setCompSave] = React.useState(false);  
  const [addNext, setAddNext] = React.useState(false);
  const [gameList, setGameList] = React.useState(false);
  const [userRole, setUserRole] = React.useState(['competition']);
  const [user, setUser] = React.useState([{academyId:"",role:[]}]);
  const [Course, setCourse] = React.useState([]);
  const [courseDetails, setCourseDetails] = React.useState([]);
  const [competition, setCompetition] = React.useState([]);
  const [isSubmitting, setisSubmitting] = React.useState(false);
  const [category, setCategory] = React.useState(competitionCategory);
  const [collatedTable, setCollatedTable] = React.useState([]);  
  const [selectedItem,setSelectedItem] = React.useState([]);
  const [edit,setEdit] = React.useState(false);
  const [values, setValues] = React.useState({
    name: '',
    description:'',
    email:'',
    contactNo:'',
    type: "",
    teamType: teamType,
    level: '',
    level1: '',
    gender1: '',
    gender: Gender,
    competitionFrom: `${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`,
    competitionTo: `${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`,
    registrationStarts: `${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`,
    registrationEnds: `${new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split(':')[0]}:${new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split(':')[1]}`,
    cccRegistration: `${new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split(':')[0]}:${new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split(':')[1]}`,
    location: '',
    matchType:matchType,
    shooterCategory:shooterCategory,
    individualFees: 0,
    mixedFees: 0,
    teamFees: 0
  });
  const [state, setState] = React.useState({});
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorStatePayment, setEditorStatePayment] = useState(EditorState.createEmpty());
  const [selectedGameType, setSelectedGameType] = React.useState([]); 
  const [addResponseResult,setAddResponseResult] = React.useState({events:[]})
  const [addResponse,setAddResponse] = React.useState({events:[]})
  // const [addResponse,setAddResponse] = React.useState({events:[
  //   [ 'Game type', 'Category', 'Gender', 'Type', 'Level', 'ID' ],
  //   [ 'Air Rifle 10m', 'Sub Youth', 'Men', 'Individual', 'NR', 'S76' ],
  //   [ 'Air Rifle 10m', 'Under 14', 'Men', 'Individual', 'NR', 'S77' ],
  //   [ 'Air Rifle 10m', 'Under 14', 'Women', 'Individual', 'NR', 'S84' ]
  // ]})
  let columns = [{field: "gameType", headerName: 'Game Type', width: 180},{field: "category", headerName: 'Category', width: 180}
    ,{field: "gender", headerName: 'Gender', width: 120},{field: "type", headerName: 'Type', width: 140},{field: "level", headerName: 'Level', width: 100},{field: "shooterCategory", headerName: 'Shooter Category', width: 100},{field: "id", headerName: 'Id', width: 100}
    ]
//   let addResponse=[{gameType:"Air Rifle 10m",category:"Sub Youth",gender:"Men",type:"Individual",level:"NR",id:"S91"},{gameType:"Air Rifle 10m",category:"Sub Youth",gender:"Women",type:"Individual",level:"NR",id:"S98"}
// ,{gameType:"Air Rifle 10m",category:"Sub Youth",gender:"Men",type:"Individual",level:"NR",id:"S57"},{gameType:"Air Rifle 10m",category:"Sub Youth",gender:"Men",type:"Individual",level:"NR",id:"S50"},{gameType:"Air Rifle 10m",category:"Sub Youth",gender:"Women",type:"Individual",level:"NR",id:"S45"}
// ,{gameType:"Air Rifle 10m",category:"Sub Youth",gender:"Men",type:"Individual",level:"NR",id:"S58"},{gameType:"Air Rifle 10m",category:"Sub Youth",gender:"Men",type:"Individual",level:"NR",id:"S51"},{gameType:"Air Rifle 10m",category:"Sub Youth",gender:"Women",type:"Individual",level:"NR",id:"S46"}
// ,{gameType:"Air Rifle 10m",category:"Sub Youth",gender:"Men",type:"Individual",level:"NR",id:"S62"},{gameType:"Air Rifle 10m",category:"Sub Youth",gender:"Men",type:"Individual",level:"NR",id:"S53"},{gameType:"Air Rifle 10m",category:"Sub Youth",gender:"Women",type:"Individual",level:"NR",id:"S47"}
// ,{gameType:"Air Rifle 10m",category:"Sub Youth",gender:"Men",type:"Individual",level:"NR",id:"S63"},{gameType:"Air Rifle 10m",category:"Sub Youth",gender:"Men",type:"Individual",level:"NR",id:"S55"},{gameType:"Air Rifle 10m",category:"Sub Youth",gender:"Women",type:"Individual",level:"NR",id:"S48"}
// ,{gameType:"Air Rifle 10m",category:"Sub Youth",gender:"Men",type:"Individual",level:"NR",id:"S64"},{gameType:"Air Rifle 10m",category:"Sub Youth",gender:"Men",type:"Individual",level:"NR",id:"S56"},{gameType:"Air Rifle 10m",category:"Sub Youth",gender:"Women",type:"Individual",level:"NR",id:"S49"}
// ,{gameType:"Air Rifle 10m",category:"Sub Youth",gender:"Men",type:"Individual",level:"NR",id:"S65"}]
  
var statusOption =["close"]

  useEffect(() => {
    getCompetitions();
    getUserDetail();
    getCourse();
  }, []);

  const getUserDetail = () => {
    if (userDetails.email === undefined){
      setUserRole(['competition'])
    }else{
      axios
        .post(`/api/user/getParticularUserByEmail`, {
          "emailID": userDetails.email
        })
        .then((response) => {
          const userData = response.data.data;
          setUserRole(userData[0].role);
          setUser(userData)
        })
        .catch((error) => {
          console.log(error)
          toast.error(error.response.data);
        });
    }
  }

  const getCourse = () => {
    axios
      .get(`/api/course/getAll`)
      .then((response) => {
        const courseData = response.data.data;
        setCourseDetails(courseData);
        const value = [];
        courseData.map((item, index) => {
          const value1 = {
            value: `${item.type}-${item.range}`,
            label: `${item.displayName} ${item.range}m`,
          }
          value.push(value1)
        })
        setCourse(value);
        setValues({
          ...values,
          "type": value
        });
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response.data);
      });
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeToggle = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const onEditorStateChange = (editorState) => {
    // console.log(editorState)
      setEditorState(editorState);
  }

  const onEditorStatePayment = (editorStatePayment) => {
    console.log(editorStatePayment)
      setEditorStatePayment(editorStatePayment);
  }

  const getCompetitions = () => {
    axios
      .get(`/api/competition/getAll`)
      .then((response) => {
        // console.log("***** comp",response.data.data)
        let matchData = response.data.data
        sortFunction(matchData)
        setCompetition(matchData);
      })
      .catch((error) => { 
        toast.error(error.response.data);
      });
  }

  const sortFunction = (data) => {
    data.sort(function (a, b) {
      return new Date(b.competitionFrom) - new Date(a.competitionFrom);
    })
  }

  const handleChange = (props) => (event) => {
    
    setValues({
      ...values,
      [props]: event.target.value
    });
  };

  // const handleChangeCheckBox = (event) => {
  //   console.log(event)
  //   setState({
  //     ...state,
  //     [event.target.name]: event.target.checked,
  //   });
  //   if(event.target.checked === true){
  //     selectedGameType.includes(event.target.name)?null:selectedGameType.push(event.target.name)
  //   }
  //   else if(event.target.checked === false){
  //     selectedGameType.includes(event.target.name)?selectedGameType.splice(selectedGameType.indexOf(event.target.name),1):null      
  //   }
  //   setSelectedGameType(selectedGameType)
  // };

  const handleChangeCheckBox = (event) => {

      // selectedGameType.includes(event[0])?selectedGameType.splice(selectedGameType.indexOf(event[0]),1):selectedGameType.push(event[0])
    
    setSelectedGameType(event)
  };

  const handleClose = ()=>{
    setValues({...values,
      name: '',
      description:setEditorState(EditorState.createEmpty()),
      type: "",
      teamType: teamType,
      level: '',
      level1: '',
      gender1: '',
      gender: Gender,
      competitionFrom: `${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`,
      competitionTo: `${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`,
      registrationStarts: `${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`,
      registrationEnds: `${new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split(':')[0]}:${new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split(':')[1]}`,
      cccRegistration: `${new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split(':')[0]}:${new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split(':')[1]}`,
      location: '',
      matchType:matchType,
      shooterCategory:shooterCategory,
      gameType: '',
      individualFees: 0,
      mixedFees: 0,
      teamFees: 0

    })
  }

  const handleCompetition =() =>{
    // setisSubmitting(true)
    // console.log("****",draftToHtml(convertToRaw(editorStatePayment.getCurrentContent())))
    
    if (values.name === '' || values.email === '' || values.contactNo === '' || category.length === 0 || values.level === '' || values.type === '' || draftToHtml(convertToRaw(editorState.getCurrentContent())).length <= 8) {
      toast.error("Enter all the fields before save");
    }else{
      let cate = []; let type= []; let teamType = []; let gend=[]; let match=[]; let shootcat=[];
      category.map((item)=>{cate.push(item.label)})
      values.type.map((item)=>{type.push(item.label)})
      values.teamType.map((item)=>{teamType.push(item.label)})
      values.gender.map((item)=>{gend.push(item.label)})
      values.matchType.map((item)=>{match.push(item.label)})      
      values.shooterCategory.map((item)=>{shootcat.push(item.label)})
      axios
        .post(`/api/competition/add`, {
          name: values.name,  
          description: draftToHtml(convertToRaw(editorState.getCurrentContent())), 
          paymentMethod: draftToHtml(convertToRaw(editorStatePayment.getCurrentContent())), 
          email:values.email,
          contactNo:values.contactNo, 
          category: cate,
          gameType: type,
          matchType: match,
          shooterCategory:shootcat,
          teamType: teamType,
          gender: gend,
          level: values.level,
          location: values.location,
          competitionFrom: `${values.competitionFrom.split('T')[0]}T00:00:00.000+00:00`,
          competitionTo: `${values.competitionTo.split('T')[0]}T18:30:00.000+00:00`,
          registrationStarts: `${values.registrationStarts.split('T')[0]}T00:00:00.000+00:00`,
          registrationEnds: `${values.registrationEnds.split('T')[0]}T18:30:00.000+00:00`,
          cccRegistration: `${values.cccRegistration.split('T')[0]}T18:30:00.000+00:00`,
          createdBy: userDetails.email,
          createrAcademy: user[0].academyId,
          createrName: user[0].displayName,
          fees:{
            individual:values.individualFees,
            team:values.teamFees,
            mixed:values.mixedFees
          }
        })
        .then((response) => {
          let arr = [] 
          let row = []
          
          setAddResponseResult(response.data.data)
          response.data.data.events.map((val,index)=>{
            if(index != 0){
              arr.push(val[6])
              row.push({gameType:val[0],category:val[1],gender:val[2],type:val[3],level:val[4],shooterCategory:val[5],id:val[6]})
            }
            if(index+1 == response.data.data.events.length){
              setSelectedGameType(arr)
              setAddResponse(row)
            }
          })
          
          setGameList(true)
          setisSubmitting(false)
          setOpen(false);
          setAddNext(false)
          getCompetitions();
        })
        .catch((error) => {
         setisSubmitting(false)
          toast.error(error);
        });
    }
  }

  const valuesDefine = (data) =>{
    
    let gender =[]
    data.gender.map((item)=>{gender.push({label:item})})
    let category =[]
    data.category.map((item)=>{category.push({label:item,value:item})})
    let teamType =[]
    data.teamType.map((item)=>{teamType.push({label:item})})
    let matchType =[]
    data.matchType.map((item)=>{matchType.push({label:item})})
    let shooterCategory =[]
    data.shooterCategory.map((item)=>{shooterCategory.push({label:item})})
    let gameType =[]
    data.gameType.map((item)=>{gameType.push({label:item,value:item})})
    setCategory(category)
    setValues({...values,
      name: data.name,
      description:setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(data.description)))),
      paymentMethod:setEditorStatePayment(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(data.paymentMethod)))),
      email:data.email,
      contactNo:data.contactNo,
      type: gameType,
      teamType: teamType,
      level:data.level,
      level1: {"label" :data.level,value:data.level},
      gender1: '',
      gender: gender,
      competitionFrom: `${data.competitionFrom.split(":")[0]}:${data.competitionFrom.split(":")[1]}`,
      competitionTo: data.competitionTo != undefined ?`${data.competitionTo.split(":")[0]}:${data.competitionTo.split(":")[1]}`:`${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`,
      registrationStarts: data.registrationStarts != undefined ? `${data.registrationStarts.split(":")[0]}:${data.registrationStarts.split(":")[1]}`:`${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`,
      registrationEnds: data.registrationEnds != undefined ? `${data.registrationEnds.split(":")[0]}:${data.registrationEnds.split(":")[1]}`:`${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`,
      cccRegistration: data.cccRegistration != undefined ? `${data.cccRegistration.split(":")[0]}:${data.cccRegistration.split(":")[1]}`:`${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`,
      location: data.location,
      matchType:matchType,
      shooterCategory:shooterCategory,
      status:data.status,
      individualFees: data.fees != undefined && data.fees.individual != undefined ?data.fees.individual:0,
      mixedFees: data.fees != undefined && data.fees.mixed != undefined ?data.fees.mixed:0,
      teamFees: data.fees != undefined && data.fees.team != undefined ?data.fees.team:0
    })
    setEdit(true);
    setSelectedItem(data);
  }

  const handleEdit = ()=>{
    setisSubmitting(true)
    
    let cate = []; let type= []; let teamType = []; let gend=[]; let match=[]; let shootcat=[];
      category.map((item)=>{cate.push(item.label)})
      values.type.map((item)=>{type.push(item.label)})
      values.teamType.map((item)=>{teamType.push(item.label)})
      values.gender.map((item)=>{gend.push(item.label)})
      values.matchType.map((item)=>{match.push(item.label)})
      values.shooterCategory.map((item)=>{shootcat.push(item.label)})
      
      axios
        .put(`/api/competition/update`, {
          _id:selectedItem._id,
          name: values.name,  
          description:draftToHtml(convertToRaw(editorState.getCurrentContent())),
          paymentMethod:draftToHtml(convertToRaw(editorStatePayment.getCurrentContent())),
          email:values.email,
          contactNo:values.contactNo,
          category: cate,
          gameType: type,
          matchType: match,
          shooterCategory:shootcat,
          teamType: teamType,
          gender: gend,
          level: values.level,
          location: values.location,
          competitionFrom: `${values.competitionFrom.split('T')[0]}T00:00:00.000+00:00`,
          competitionTo: `${values.competitionTo.split('T')[0]}T18:30:00.000+00:00`,
          registrationStarts: `${values.registrationStarts.split('T')[0]}T00:00:00.000+00:00`,
          registrationEnds: `${values.registrationEnds.split('T')[0]}T18:30:00.000+00:00`,
          cccRegistration: `${values.cccRegistration.split('T')[0]}T18:30:00.000+00:00`,
          status: values.status,
          fees:{
            individual:values.individualFees,
            team:values.teamFees,
            mixed:values.mixedFees
          }
        })
        .then((response) => {
          setValues({name:'',description:setEditorState(EditorState.createEmpty()),paymentMethod:setEditorState(EditorState.createEmpty()),email:'',contactNo:'',location:'',gender:'',teamType:'',matchType:'',shooterCategory:'',type:'',category:[],level:'',individualFees: 0,mixedFees: 0,teamFees: 0,
          competitionFrom:`${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`,
          competitionTo: `${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`,
          registrationStarts: `${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`,
          registrationEnds: `${new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split(':')[0]}:${new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split(':')[1]}`,
          cccRegistration: `${new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split(':')[0]}:${new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split(':')[1]}`,
    
        })
          setisSubmitting(false)
          setEdit(false);
          getCompetitions();
        })
        .catch((error) => {
         setisSubmitting(false)
          toast.error(error.response.data);
        });
  }

  const handleCompetitionExcel = ()=>{
    axios
        .post(`/api/competition/addCompetitionExcel`, {
          gameIdList:selectedGameType,
          id:addResponseResult.result._id
        })
        .then((response) => {
          // console.log("************* ",response)
          setGameList(false);
          getCompetitions();
          setValues({name:'',description:setEditorState(EditorState.createEmpty()),paymentMethod:setEditorState(EditorState.createEmpty()),email:'',contactNo:'',location:'',gender:'',teamType:'',matchType:'',shooterCategory:'',type:'',category:[],level:'',individualFees: 0,mixedFees: 0,teamFees: 0,
            competitionFrom:`${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`,
            competitionTo: `${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`,
            registrationStarts: `${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`,
            registrationEnds: `${new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split(':')[0]}:${new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split(':')[1]}`,
            cccRegistration: `${new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split(':')[0]}:${new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split(':')[1]}`,
          })
        })
        .catch((error) => {
         setisSubmitting(false)
          toast.error(error.response.data);
        });
  }

  const deleteData = (row) =>{
    console.log(row)
  }

  const table = () => {
    
      return (
        <Card>
          {/* <CardHeader title="Competitions" /> */}
          {competition.length>0 ?
            <div>
              
            <TableContainer component={Paper}  sx={{}}>
                      <Table stickyHeader sx={{ minWidth: 350 }} size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">Name</TableCell>
                            {/* <TableCell align="left">Created By</TableCell> */}
                            <TableCell align="left">Competition From</TableCell>
                            <TableCell align="left">Competition To</TableCell>
                            <TableCell align="left">Registration Starts</TableCell>
                            <TableCell align="left">Registration Ends</TableCell>
                            <TableCell align="left">Triple Fees Date</TableCell>
                            <TableCell align="left">Level</TableCell>
                            {user.length >0 && user[0].role.includes('superAdmin') || user[0].role.includes('coach')?<TableCell align="left">Action</TableCell>:null}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {competition.length>0 && competition
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row) => {
                            return (
                            <TableRow tabIndex={-1} key={row._id} sx={{ "&:hover": { cursor: user.length >0 && user[0].role.includes('coach') || user[0].role.includes("superAdmin")? null:"pointer" } }}
                              onClick={() => {user.length >0 && user[0].role.includes('coach') || user[0].role.includes("superAdmin")? null:window.location.assign(`/dashboard/competition/${row._id}`)}} >
                              <TableCell  align="left">{row.name}</TableCell>
                              {/* <TableCell align="left">{row.createrName != undefined && row.createrName !=""?row.createrName:row.createdBy}</TableCell> */}
                              <TableCell align="left">{row.competitionFrom != undefined ?row.competitionFrom.split("T")[0]: row.competitionOn.split("T")[0]}</TableCell>
                              <TableCell align="left">{row.competitionTo != undefined ?row.competitionTo.split("T")[0]: "-"}</TableCell>
                              <TableCell align="left">{row.registrationStarts != undefined ?row.registrationStarts.split("T")[0]: "-"}</TableCell>
                              <TableCell align="left">{row.registrationEnds != undefined ?row.registrationEnds.split("T")[0]: "-"}</TableCell>
                              <TableCell align="left">{row.cccRegistration != undefined ?row.cccRegistration.split("T")[0]: "-"}</TableCell>
                              <TableCell align="left">{row.level}</TableCell>
                              {user.length >0 && user[0].role.includes('coach') || user[0].role.includes("superAdmin")?
                                <TableCell  align="left">
                                  <Iconify icon="ic:baseline-remove-red-eye" sx={{ "&:hover": { cursor: "pointer",color:"blue" } }} onClick={()=>{window.location.assign(`/dashboard/competition/${row._id}`)}} /> 
                                  {user.length>0 && user[0].emailID == row.createdBy?                                
                                    <Iconify icon="fa:edit" sx={{ "&:hover": { cursor: "pointer",color:"blue" } }} ml={1} onClick={()=>{valuesDefine(row)}} /> 
                                  : null}
                                </TableCell>
                                
                              :null}
                            </TableRow>
                            )
                          })
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      colSpan={7}
                      count={competition.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}                      
                    />
          </div> :null}
        </Card>
      )}

  const card = () => {

    return (
      <Grid
        container
        spacing={2}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        
      {competition.length>0 && competition
      .map((row) => {
        return (
          <Grid item xs={12} sm={6} md={4} key={row._id}>
          <Card sx={{ maxWidth: 305,"&:hover": { cursor: user.length >0 && user[0].role.includes('coach') || user[0].role.includes("superAdmin")? null:"pointer" } }}
          onClick={() => {user.length >0 && user[0].role.includes('coach') || user[0].role.includes("superAdmin")? null:window.location.assign(`/dashboard/competition/${row._id}`)}}>
          
          <CardContent>
            <Typography gutterBottom variant="h6">{row.name}</Typography>
            <Table stickyHeader size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="left"></TableCell>
                  <TableCell align="left" sx={{ fontSize:{md:'0.575rem',sm:"0.575rem",xs:"0.656rem"} }}>Starts</TableCell>
                  <TableCell align="left" sx={{ fontSize:{md:'0.575rem',sm:"0.575rem",xs:"0.656rem"} }}>Ends</TableCell>
                </TableRow>
              </TableHead>
              <TableRow >
                <TableCell align="left" sx={{ fontSize:{md:'0.575rem',sm:"0.575rem",xs:"0.656rem"} }}>Registration</TableCell>
                <TableCell align="left" sx={{ fontSize:{md:'0.575rem',sm:"0.575rem",xs:"0.656rem"} }}>{row.registrationStarts != undefined ?row.registrationStarts.split("T")[0]: "-"}</TableCell>
                <TableCell align="left" sx={{ fontSize:{md:'0.575rem',sm:"0.575rem",xs:"0.656rem"} }}>{row.registrationEnds != undefined ?row.registrationEnds.split("T")[0]: "-"}</TableCell>
              </TableRow>
              <TableRow >
                <TableCell align="left" sx={{ fontSize:{md:'0.575rem',sm:"0.575rem",xs:"0.656rem"} }}>Triple Fees</TableCell>
                <TableCell align="left" sx={{ fontSize:{md:'0.575rem',sm:"0.575rem",xs:"0.656rem"} }}>{row.cccRegistration != undefined ?row.cccRegistration.split("T")[0]: "-"}</TableCell>
                <TableCell align="left" sx={{ fontSize:{md:'0.575rem',sm:"0.575rem",xs:"0.656rem"} }}>{row.registrationEnds != undefined ?row.registrationEnds.split("T")[0]: "-"}</TableCell>
              </TableRow>
              <TableRow >
                <TableCell align="left" sx={{ fontSize:{md:'0.575rem',sm:"0.575rem",xs:"0.656rem"} }}>Competition</TableCell>
                <TableCell align="left" sx={{ fontSize:{md:'0.575rem',sm:"0.575rem",xs:"0.656rem"} }}>{row.competitionFrom != undefined ?row.competitionFrom.split("T")[0]: "-"}</TableCell>
                <TableCell align="left" sx={{ fontSize:{md:'0.575rem',sm:"0.575rem",xs:"0.656rem"} }}>{row.competitionTo != undefined ?row.competitionTo.split("T")[0]: "-"}</TableCell>
              </TableRow>
              </Table>

            {/* <Typography gutterBottom variant="body1" >Competition</Typography>
            <Typography gutterBottom variant="body2" color="text.secondary" ml={3}>
              From: {row.competitionFrom != undefined ?row.competitionFrom.split("T")[0]: row.competitionOn.split("T")[0]}              
            </Typography>
            <Typography gutterBottom variant="body2" color="text.secondary" ml={3}>
              To: {row.competitionTo != undefined ?row.competitionTo.split("T")[0]: row.competitionOn.split("T")[0]}
            </Typography>
            <Typography gutterBottom variant="body1" >Registration</Typography>
            <Typography gutterBottom variant="body2" color="text.secondary" ml={3}>
              Starts: {row.registrationStarts != undefined ?row.registrationStarts.split("T")[0]: row.competitionOn.split("T")[0]}
            </Typography>
            <Typography gutterBottom variant="body2" color="text.secondary" ml={3}>
              Ends: {row.registrationEnds != undefined ?row.registrationEnds.split("T")[0]: row.competitionOn.split("T")[0]}
            </Typography> */}
            {/* <Stack direction="row" >
            <Typography gutterBottom variant="body1" >Triple Fees:</Typography> 
            <Typography gutterBottom variant="body2" color="text.secondary" ml={1}>{row.cccRegistration != undefined ?row.cccRegistration.split("T")[0]: row.competitionOn.split("T")[0]}
            </Typography>
            </Stack> */}
            {/* <Stack direction="row" >
            <Typography gutterBottom variant="body1" >Level:</Typography> 
            <Typography gutterBottom variant="body2" color="text.secondary" ml={1}>
              {row.level}
            </Typography>
            </Stack> */}
          {user.length >0 && user[0].role.includes('coach') || user[0].role.includes("superAdmin")?
          <Stack direction="row" spacing={2} mt={1} >
            <Iconify icon="ic:baseline-remove-red-eye" sx={{ "&:hover": { cursor: "pointer",color:"blue" } }} onClick={()=>{window.location.assign(`/dashboard/competition/${row._id}`)}} />
            {user.length>0 && user[0].emailID == row.createdBy?                                
                <Iconify icon="fa:edit" sx={{ "&:hover": { cursor: "pointer",color:"blue" } }} ml={1} onClick={()=>{valuesDefine(row)}} /> 
              : null}
            {/* {user.length>0 && user[0].emailID == row.createdBy?                                
              <Iconify icon="mingcute:delete-line" sx={{ "&:hover": { cursor: "pointer",color:"blue" } }} ml={1} onClick={()=>{deleteData(row)}} /> 
            : null} */}
          </Stack>
          :null}
          </CardContent>
        </Card>
        </Grid>
        )
      })}
      </Grid>
    )}

    const activeComp = () => {
    
      return (
        <Card>
          <CardHeader title="Active Competition" />
          {competition.length>0 ?
            <div>
              
            <TableContainer component={Paper}  sx={{}}>
                      <Table stickyHeader sx={{ minWidth: 350 }} size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">Name</TableCell>
                            {/* <TableCell align="left">Created By</TableCell> */}
                            <TableCell align="left">Competition From</TableCell>
                            <TableCell align="left">Competition To</TableCell>
                            <TableCell align="left">Registration Starts</TableCell>
                            <TableCell align="left">Registration Ends</TableCell>
                            <TableCell align="left">Triple Fees Date</TableCell>
                            <TableCell align="left">Level</TableCell>
                            {user.length >0 && user[0].role.includes('superAdmin') || user[0].role.includes('coach')?<TableCell align="left">Action</TableCell>:null}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {competition.length>0 && competition
                          // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row) => {
                            return (
                            row.status === 'active' && (new Date() <= new Date(row.competitionTo))? 
                            <TableRow tabIndex={-1} key={row._id} sx={{ "&:hover": { cursor: user.length >0 && user[0].role.includes('coach') || user[0].role.includes("superAdmin")? null:"pointer" } }}
                              onClick={() => {user.length >0 && user[0].role.includes('coach') || user[0].role.includes("superAdmin")? null:window.location.assign(`/dashboard/competition/${row._id}`)}} >
                              <TableCell  align="left">{row.name}</TableCell>
                              <TableCell align="left">{row.competitionFrom != undefined ?row.competitionFrom.split("T")[0]: row.competitionOn.split("T")[0]}</TableCell>
                              <TableCell align="left">{row.competitionTo != undefined ?row.competitionTo.split("T")[0]: "-"}</TableCell>
                              <TableCell align="left">{row.registrationStarts != undefined ?row.registrationStarts.split("T")[0]: "-"}</TableCell>
                              <TableCell align="left">{row.registrationEnds != undefined ?row.registrationEnds.split("T")[0]: "-"}</TableCell>
                              <TableCell align="left">{row.cccRegistration != undefined ?row.cccRegistration.split("T")[0]: "-"}</TableCell>
                              <TableCell align="left">{row.level}</TableCell>
                              {user.length >0 && user[0].role.includes('coach') || user[0].role.includes("superAdmin")?
                                <TableCell  align="left">
                                  <Iconify icon="ic:baseline-remove-red-eye" sx={{ "&:hover": { cursor: "pointer",color:"blue" } }} onClick={()=>{window.location.assign(`/dashboard/competition/${row._id}`)}} /> 
                                  {user.length>0 && user[0].emailID == row.createdBy?                                
                                    <Iconify icon="fa:edit" sx={{ "&:hover": { cursor: "pointer",color:"blue" } }} ml={1} onClick={()=>{valuesDefine(row)}} /> 
                                  : null}
                                </TableCell> 
                                
                              :null}
                            </TableRow>
                            :null
                            )
                          })
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {/* <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      colSpan={7}
                      count={competition.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}                      
                    /> */}
          </div> :null}
        </Card>
      )}

    const input = () =>{
      return (
      <Stack direction={{ xs: 'column', sm: 'column' }} alignItems="left" justifyContent="space-between"  xs={12} sm={12} md={6} mt={2}>
        <TextField
          id = "standard-basic"
          defaultValue = {values.name}
          value={values.name}
          varient="standard"
          label="Name"
          type = 'text'
          sx={{mt:2}}
          onChange={handleChange('name')}
          />           
          {/* <TextField
          id = "standard-basic"
          defaultValue = {values.description}
          value={values.description}
          varient="standard"
          label="Description"
          type = 'text'
          multiline
          rows={3}
          sx={{mt:2}}
          onChange={handleChange('description')}
          />  */}
          <TextField
          id = "standard-basic"
          defaultValue = {values.email}
          value={values.email}
          varient="standard"
          label="Email"
          type = 'text'
          sx={{mt:2}}
          onChange={handleChange('email')}
          /> 
          <TextField
          id = "standard-basic"
          defaultValue = {values.contactNo}
          value={values.contactNo}
          varient="standard"
          label="ContactNo"
          type = 'number'
          sx={{mt:2}}
          onChange={handleChange('contactNo')}
          />
        <Autocomplete
          id = "tags-outlined"
          multiple
          disableClearable
          disableCloseOnSelect
          defaultValue={edit==true?category:competitionCategory}
          options={competitionCategory}
          getOptionLabel={(option) => {return option.label}}
          // inputValue={category}
          onInputChange={(event, newInputValue) => {
            console.log( newInputValue)
            // setCategory(...category, category.push(newInputValue))
               
          }}onChange={(event, newValue) => {      
            // console.log("**", newValue, category, newValue[newValue.length-1].value)
            // let aa = category.push(newValue[newValue.length - 1].value)
            setCategory(newValue)
          }}
          renderInput={(params) => <TextField {...params} label="Category" sx={{mt:2}}/>}
        />   
        <Autocomplete
          id = "disable-clearable"
          multiple
          disableClearable
          disableCloseOnSelect
          defaultValue={edit==true?values.type:Course}
          options={Course}
          getOptionLabel={(option) => {return option.label}}
          // inputValue={values.type1}
          onInputChange={(event, newInputValue) => {
               
          }}
          onChange={(event, newValue) => {            
            setValues({
              ...values,
              ['type']: newValue
            });
          }}
          renderInput={(params) => <TextField {...params} label="Game Type" sx={{mt:2}}/>}
        />  
        <Autocomplete
          id = "disable-clearable"
          multiple
          disableClearable
          disableCloseOnSelect
          defaultValue={edit==true?values.teamType:teamType}
          options={teamType}
          getOptionLabel={(option) => {return option.label}}
          onInputChange={(event, newInputValue) => {     
          }}
          onChange={(event, newValue) => {            
            setValues({
              ...values,
              ['teamType']: newValue
            });
          }}
          renderInput={(params) => <TextField {...params} label="Team Type" sx={{mt:2}}/>}
        />  
        <Autocomplete
          id = "disable-clearable"
          multiple
          disableClearable
          disableCloseOnSelect
          defaultValue={edit==true?values.matchType:matchType}
          options={matchType}
          getOptionLabel={(option) => {return option.label}}
          onInputChange={(event, newInputValue) => {     
          }}
          onChange={(event, newValue) => {            
            setValues({
              ...values,
              ['matchType']: newValue
            });
          }}
          renderInput={(params) => <TextField {...params} label="Match Type" sx={{mt:2}}/>}
        /> 
         <Autocomplete
        id = "disable-clearable"
        multiple
        disableClearable
        disableCloseOnSelect
        defaultValue={edit==true?values.shooterCategory:shooterCategory}
        options={shooterCategory}
        getOptionLabel={(option) => {return option.label}}
        onInputChange={(event, newInputValue) => {     
        }}
        onChange={(event, newValue) => {            
          setValues({
            ...values,
            ['shooterCategory']: newValue
          });
        }}
        renderInput={(params) => <TextField {...params} label="Shooter Category" sx={{mt:2}}/>}
      />  
        <Autocomplete
          id = "disable-clearable"
          multiple
          disableClearable
          disableCloseOnSelect
          defaultValue={edit==true?values.gender:Gender}
          options={Gender}
          getOptionLabel={(option) => {return option.label}}
          onInputChange={(event, newInputValue) => {
               
          }}
          onChange={(event, newValue) => {            
            setValues({
              ...values,
              ['gender']: newValue
            });
          }}
          renderInput={(params) => <TextField {...params} label="Gender" sx={{mt:2}}/>}
        /> 
        <Autocomplete
          id = "disable-clearable"
          disableClearable
          disableCloseOnSelect
          defaultValue={edit==true?values.level1:null}
          options={Type}
          getOptionLabel={(option) => {return option.label}}
          inputValue={values.level1}
          onInputChange={(event, newInputValue) => {
                setValues({
                ...values,
                ['level1']: newInputValue
              });
          }}
          onChange={(event, newValue) => {            
            setValues({
              ...values,
              ['level']: newValue.value
            });
          }}
          renderInput={(params) => <TextField {...params} label="Competition Level" sx={{mt:2}}/>}
        />  
        <TextField
          id="datetime-local"
          label="Competition From"
          type="datetime-local"
          value={values.competitionFrom}               
          sx={{mt: { md: 2, xs: 2,sm:2 }}}
          onChange={handleChange('competitionFrom')}
          InputProps = {
            {
              inputProps: {
                min: `${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`
              }
            }
          }
        /> 
        <TextField
          id="datetime-local"
          label="Competition To"
          type="datetime-local"
          value={values.competitionTo}               
          sx={{mt: { md: 2, xs: 2,sm:2 }}}
          onChange={handleChange('competitionTo')}
          InputProps = {
            {
              inputProps: {
                min: `${values.competitionFrom}`
                // min: `${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`
              }
            }
          }
        /> 
        <TextField
          id="datetime-local"
          label="Registration Starts"
          type="datetime-local"
          value={values.registrationStarts}               
          sx={{mt: { md: 2, xs: 2,sm:2 }}}
          onChange={handleChange('registrationStarts')}
          InputProps = {
            {
              inputProps: {
                min: `${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`,
                max: `${values.competitionFrom}`
              }
            }
          }
        /> 
        <TextField
          id="datetime-local"
          label="Registration Ends"
          type="datetime-local"
          value={values.registrationEnds}               
          sx={{mt: { md: 2, xs: 2,sm:2 }}}
          onChange={handleChange('registrationEnds')}
          InputProps = {
            {
              inputProps: {
                min:`${values.registrationStarts}`,
                // min: `${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`,
                max: `${values.competitionFrom}`
              }
            }
          }
        /> 
        <TextField
          id="datetime-local"
          label="CCC Registration"
          type="datetime-local"
          value={values.cccRegistration}               
          sx={{mt: { md: 2, xs: 2,sm:2 }}}
          onChange={handleChange('cccRegistration')}
          InputProps = {
            {
              inputProps: {
                min: `${values.registrationStarts}`,
                max: `${values.registrationEnds}`
              }
            }
          }
        /> 
        <TextField
          id = "standard-basic"
          defaultValue = {values.location}
          value={values.location}
          varient="standard"
          label="Location"
          type = 'text'
          sx={{mt:2}}
          onChange={handleChange('location')}
          />
          <Typography>Description</Typography>
          <Editor 
              editorState={editorState}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              onEditorStateChange={onEditorStateChange}
          /> 
          <Typography>Payment Method</Typography>
          <Editor 
              editorState={editorStatePayment}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              onEditorStateChange={onEditorStatePayment}
          /> 
        {edit === true && open != true ?
          <Autocomplete
          id = "disable-clearable"
          disableClearable
          defaultValue={edit==true?[values.status]:statusOption}
          options={statusOption}
          getOptionLabel={(option) => {return option}}
          onInputChange={(event, newInputValue) => {     
          }}
          onChange={(event, newValue) => {            
            setValues({
              ...values,
              ['status']: newValue
            });
          }}
          renderInput={(params) => <TextField {...params} label="Competition Status" sx={{mt:2}}/>}
          />  
        :null}
        {edit === true && open != true ? 
          <TextField
          id = "outlined-required"
          label="Individual Fees"
          type="number"
          value={values.individualFees}               
          sx={{mt: { md: 2, xs: 2,sm:2 }}}
          onChange={handleChange('individualFees')}
        /> 
        :null}
        {edit === true && open != true ?
        <TextField
          id = "outlined-required"
          label="Team"
          type="number"
          value={values.teamFees}               
          sx={{mt: { md: 2, xs: 2,sm:2 }}}
          onChange={handleChange('teamFees')}
        /> 
        :null}
        {edit === true && open != true ?
        <TextField
          id = "outlined-required"
          label="Mixed"
          type="number"
          value={values.mixedFees}               
          sx={{mt: { md: 2, xs: 2,sm:2 }}}
          onChange={handleChange('mixedFees')}
        />
        :null}
      </Stack>
      )}

  return (
    <Page title="competition">
      <Toast />
      <Container>
        {isSubmitting && (
          <CircularProgress
          size={24}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
          }}
          />
        )}
        {open === true ? null:
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            Competition List
          </Typography> 

          <Stack direction="row" spacing={1}>
          <ToggleButtonGroup
              color="primary"
              value={alignment}
              exclusive
              onChange={handleChangeToggle}
              aria-label="Platform"
            >
              <ToggleButton value="table">Table</ToggleButton>
              <ToggleButton value="card">Card</ToggleButton>
            </ToggleButtonGroup> 
          {userRole.includes('coach') || userRole.includes('superAdmin') ?
          <Button variant="contained" onClick={() => {setOpen(true)}} to="#" startIcon={<Iconify icon="eva:plus-fill" />}>
            New Competition
          </Button>  :null}
          </Stack>
        </Stack> 
        }   

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2} mt={1}>            
          {competition.length === 0 && open === false?
          <Typography variant="h3" gutterBottom component="div">
            No Active Competition
          </Typography> 
          :
          competition.length > 0 && open != true && edit != true ?<div>         
          {activeComp()}
          </div>:null
          }        
        </Stack>   

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2} mt={1}> 
           
          {competition.length === 0 && open === false?
          <Typography variant="h3" gutterBottom component="div">
            No Active Competition
          </Typography> 
          :null}        
        </Stack>

        {open != true && edit != true ?<div>
          <Typography variant="h6" gutterBottom component="div">
           Competitions
          </Typography> 
            {alignment == "table" ?table() :card()}
             {/* {CompSave === true?GroupingTable():table()} */}
          </div>:null}
          
      {open === true && edit != true ?
        <Stack alignItems="left" direction={{ xs: 'column', sm: 'column' }} spacing={{ xs: 1, sm: 1, md: 1 }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add Competition
          </Typography>
          {input()}
          <Stack alignItems="left" direction={{ xs: 'column', sm: 'column',md:'row' }} spacing={{ xs: 1, sm: 1, md: 1 }}>
            {/* <Button variant="outlined" onClick={handleCompetition}>save</Button> */}
            <Button variant="outlined" onClick={()=>{setAddNext(true)}}>Next</Button>
            <Button variant="outlined" color="error" onClick={() => {setOpen(false);handleClose()}}>Cancel</Button>
          </Stack>
          </Stack> :null
        }        

      {edit === true && open != true ?
        <Stack alignItems="left" direction={{ xs: 'column', sm: 'column' }} spacing={{ xs: 1, sm: 1, md: 1 }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Edit Competition
          </Typography>
          {input()}
          <Stack alignItems="left" direction={{ xs: 'column', sm: 'column',md:'row' }} spacing={{ xs: 1, sm: 1, md: 1 }}>
            <Button variant="outlined" onClick={handleEdit}>save</Button>
            <Button variant="outlined" color="error" onClick={() => {setEdit(false);handleClose()}}>Cancel</Button>
          </Stack>
          </Stack> :null
        } 
       
       <Modal
          open={addNext}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          >         
          <Box
            component="form"
            sx = {{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                width:'60%',
            }}
            noValidate
            autoComplete="off"
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Fees for different game type
            </Typography>
            <TextField
              id = "outlined-required"
              label="Individual"
              type="number"
              value={values.individualFees}               
              sx={{mt: { md: 2, xs: 2,sm:2 }}}
              onChange={handleChange('individualFees')}
            /> 
            <TextField
              id = "outlined-required"
              label="Team"
              type="number"
              value={values.teamFees}               
              sx={{mt: { md: 2, xs: 2,sm:2 },ml:2}}
              onChange={handleChange('teamFees')}
            /> 
            <TextField
              id = "outlined-required"
              label="Mixed"
              type="number"
              value={values.mixedFees}               
              sx={{mt: { md: 2, xs: 2,sm:2 },ml:2}}
              onChange={handleChange('mixedFees')}
            /> 
            <Stack alignItems="left" direction={{ xs: 'column', sm: 'column',md:'row' }} spacing={{ xs: 1, sm: 1, md: 1 }} mt={2}>
              <Button variant="outlined" onClick={handleCompetition}>Next</Button>
              <Button variant="outlined" color="error" onClick={() => {setAddNext(false)}}>Cancel</Button>
            </Stack>
          </Box>
      </Modal>

      <Modal
          open={gameList}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          >         
          <Box
            component="form"
            sx = {{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                width:'70%',
            }}
            noValidate
            autoComplete="off"
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Event List
            </Typography>
            {/* <Scrollbar style={{ height: "60vh",width:"100%",  }} >
            <Table>
              <TableBody>
                
              {addResponse.events.map((value,index)=>{
                return(
                  <TableRow tabIndex={-1}>
                  <TableCell align="left">{value[0]}</TableCell>
                  <TableCell align="left">{value[1]}</TableCell>
                  <TableCell align="left">{value[2]}</TableCell>
                  <TableCell align="left">{value[3]}</TableCell>
                  <TableCell align="left">{value[4]}</TableCell>
                  <TableCell align="left">{value[5]}</TableCell>
                  {index == 0 ?null:<FormControlLabel
                    control={<Checkbox checked={state[value[5]]} defaultChecked onChange={handleChangeCheckBox} name={value[5]} />}
                  />}
                </TableRow>
                )
              })}           
              </TableBody>
            </Table>
            </Scrollbar>   
            <Stack alignItems="left" direction={{ xs: 'column', sm: 'column',md:'row' }} spacing={{ xs: 1, sm: 1, md: 1 }} mt={2}>
              <Button variant="outlined" onClick={handleCompetitionExcel}>Save</Button>
            </Stack> */}
            <div style={{ height: 400, width: '100%' }}>
            <DataGrid
              rows={addResponse}
              columns={columns}
              autoPageSize={true}
              checkboxSelection
              density="compact"
              selectionModel={selectedGameType}
              onSelectionModelChange={(row) => { handleChangeCheckBox(row) }}
            />
            </div>
            <Stack alignItems="left" direction={{ xs: 'column', sm: 'column',md:'row' }} spacing={{ xs: 1, sm: 1, md: 1 }} mt={2}>
              <Button variant="outlined" onClick={handleCompetitionExcel}>Save</Button>
            </Stack>
          </Box>
      </Modal>

      </Container>
    </Page>
  );
}

 
export default competition;
