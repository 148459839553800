import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useState, useEffect,useRef } from 'react';
// material
import CompetitionDashboard from "./CompetitionDashboard";
import {
  Container,Stack,Grid,Button
} from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import Toast from "../components/Toast";
import Logo from '../components/Logo';
import "./style.css"


const PubliccompetitionDashboard = () => {

  const { competitionId } = useParams()
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    setLoading(true)
  // window.setTimeout( function() {
  //   window.location.reload();
  // }, 600000); 
}, []);

  return (
    <Page title="Competition Board">
      <Toast />
      {/* <Container> */}
      <Grid container>
      <Grid item xs={1}>
          <Logo disabledLink={true} user={undefined} sx={{display:{xs:"none",sm:"none",md:"block"}}}/>
        </Grid>
        <Grid item xs={11} mt={{md:1,sm:2,xs:2}}>
          <CompetitionDashboard/>
        </Grid>
      </Grid>
      {/* </Container> */}
    </Page>
  );
}

 
export default PubliccompetitionDashboard;