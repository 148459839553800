import * as React from 'react';
import axios from "axios";
import toast from "react-hot-toast";
import PropTypes from 'prop-types';
import Scrollbar from "react-scrollbar";
import { useState, useEffect } from 'react';
// material
import {
  Stack,
  Button,
  Typography, TextField, MenuItem,
  Container,Modal,Box,
  Card, CardContent, Grid,
  Tabs,Tab
} from '@mui/material';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import Toast from "../components/Toast";
import "./style.css"
import {userDetails,Type,DateFilter} from '../constants'
import Switch from '@mui/material/Switch';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const LeaderBoard = () => {

  const [value, setValue] = React.useState(0);
  const [time, setTime] = React.useState(`${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`);
  const [user, setUser] = React.useState([{role:["competition"]}]);  
  const [studentToggle, setStudentToggle] = React.useState(false);
  const [newData, setNewData] = React.useState('');
  const [academyStudent, setAcademyStudent] = React.useState([]);
  const [pistolDataNR, setPistolDataNR] = React.useState([]);
  const [rifleDataNR, setRifleDataNR] = React.useState([]);
  const [openSightDataNR, setOpenSightDataNR] = React.useState([]);
  const [pistolDataISSF, setPistolDataISSF] = React.useState([]);
  const [rifleDataISSF, setRifleDataISSF] = React.useState([]);
  const [openSightDataISSF, setOpenSightDataISSF] = React.useState([]);
  const [fromDate, setFromDate] = React.useState(`${new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().split('T')[0]}`);
  const [toDate, setToDate] = React.useState(`${new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0]}`);
  

  useEffect(() => {
    getAcademyUser(fromDate,toDate);
    getUser();
  }, []);

  const getAcademyUser = (fromDate, toDate) => {
    axios
      .post(`/api/user/academyUser`, {
        emailID: userDetails.email,
        fromDate: fromDate, 
        toDate: toDate
      })
      .then((response) => {
        // console.log(response.data.data)
        setAcademyStudent(response.data.data);
        segregateCategory(response.data.data)
      })
      .catch((error) => {
        toast.error(error.response.data);
      });
  }

  const getUser = () => {

    axios
      .post(`/api/user/getParticularUserByEmail`, {
        "emailID": userDetails.email
      })
      .then((response) => {
        const userData = response.data.data;
        // console.log(userData)
        setUser(userData);
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response.data);
      });
  }

  const sortFunction = (data) =>{
    data.sort(function (a, b) {
      return b.percentage - a.percentage;
    })
  }

  const segregateCategory = (data) =>{
    const pistolNR = []
    const rifleNR = []
    const open_sightNR = []
    const pistolISSF = []
    const rifleISSF = []
    const open_sightISSF = []
    
    data.map((item) => {
      if (item._id.studentLevel === 'NR'){
        if (item.course[0].type === 'rifle') {
          rifleNR.push(item)
        } else if (item.course[0].type === 'pistol') {
          pistolNR.push(item)
        } else if (item.course[0].type === 'open_sight') {
          open_sightNR.push(item)
        }
      }
      else if (item._id.studentLevel === 'ISSF'){
        if (item.course[0].type === 'rifle') {
          rifleISSF.push(item)
        } else if (item.course[0].type === 'pistol') {
          pistolISSF.push(item)
        } else if (item.course[0].type === 'open_sight') {
          open_sightISSF.push(item)
        }
      }
    })

    if (rifleNR.length > 1) {
      sortFunction(rifleNR)
    }
    if (pistolNR.length > 1) {
      sortFunction(pistolNR)
    }
    if (open_sightNR.length > 1) {
      sortFunction(open_sightNR)
    }
    if (rifleISSF.length > 1) {
      sortFunction(rifleISSF)
    }
    if (pistolISSF.length > 1) {
      sortFunction(pistolISSF)
    }
    if (open_sightISSF.length > 1) {
      sortFunction(open_sightISSF)
    }

    setPistolDataNR(pistolNR)
    setRifleDataNR(rifleNR)
    setOpenSightDataNR(open_sightNR)
    setPistolDataISSF(pistolISSF)
    setRifleDataISSF(rifleISSF)
    setOpenSightDataISSF(open_sightISSF)
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const Search = (e,fromDate,toDate) => {
    let search = newData
    if (e.target !== undefined){
      search = e.target.value
    }
    // console.log(search,a)
    setNewData(search)
    axios
      .post(`/api/user/academyUserSearch`, {
        emailID: userDetails.email,
        userName: search,
        fromDate: fromDate, 
        toDate: toDate
      })
      .then((response) => {
        // console.log(response.data.data)
        segregateCategory(response.data.data)
      })
      .catch((error) => {
        toast.error(error.response.data);
      });
  }

  const handleChangeSwitch = (event) => {
    // console.log(event.target.name, "****",event.target.checked)
    setStudentToggle(event.target.checked);
  };

  const handleChangeInput = (props) => (event) => {
    if(props === 'fromDate'){
      setFromDate(event.target.value);
      Search(newData, event.target.value,toDate)
    } else if (props === 'toDate'){
      setToDate(event.target.value);
      Search(newData, fromDate,event.target.value)
    }else{
      search(newData, fromDate, toDate)
    }
  };

  const card = (data) => {
    
    return (
        // <Grid container>
        <div>
        <Scrollbar style={{ height: "61vh", width: "100%", paddingRight: "1rem" }} > 
        {data.length >0 ? data.map((item,index) => {
          return (
            <Box sx={{mt:2}}>
            <Card sx={{cursor: user[0].role.includes('coach')?"pointer":"none"}}                   
              onClick={() => {
                user[0].role.includes('coach')  ?
                window.location.assign(`/dashboard/match/user/${item._id.user}`)
                : null }}                   
            >
            <CardContent>
              <Stack direction={{md:"row",sm:"row",xs:'row'}}>
              <Grid container columns={{ xs: 12, sm: 12, md: 12 }}>
              <Grid item xs={2} sm={2} md={2} >
              <Typography sx={{ }} variant="body1">
                {index+1}
              </Typography>
              </Grid>
              <Grid item xs={8} sm={8} md={8} >
              <Typography variant="body1" >
                {/* {item._id.userName}  */}
                {item.displayName[0]} 
              </Typography>
              </Grid>
              <Grid item xs={2} sm={2} md={2} >
              <Typography variant="body1">
                {item.percentage}%
              </Typography>
              </Grid>
              </Grid>
              </Stack>
            </CardContent>
          </Card>
        </Box>
          )
        }):
        <Box sx={{marginTop:{xs:'20%',sm:'12%',md:'1%'},marginLeft:{sm:'20%',md:'29%'}}}>
          <Typography variant="h4" sx={{marginLeft:{xs:'23%',sm:'17%',md:'17%'}}} >No Data</Typography> 
          <img src="/static/illustrations/empty_bin.png" alt="No Details"  style={{width:'300px'}}/>
        </Box>
        // <p>No Leader Board</p>
        }
        </Scrollbar>
        </div>
        // </Grid>
    )}

    function TabPanel(props) {
      const { children, value, index, ...other } = props;

      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`vertical-tabpanel-${index}`}
          aria-labelledby={`vertical-tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box sx={{ p: 3 }}>
              {/* <Typography>{children}</Typography> */}
              {card(children)}
            </Box>
          )}
        </div>
      );
    }

    TabPanel.propTypes = {
      children: PropTypes.node,
      index: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
      return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
      };
    }

  return (
    <Page title="Leader Board">
      <Toast />
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Leader Board
          </Typography> 
          <FormControl component="fieldset" variant="standard">
            <FormGroup>
              <Stack direction="row" spacing={1} alignItems="center">
              <Typography>NR</Typography>
              <FormControlLabel
                control={
                  <Switch checked={studentToggle} onChange={handleChangeSwitch} name="studentToggle" />
                }
                label="ISSF"
              />
              </Stack>
            </FormGroup>
          </FormControl>

        </Stack>   
        <Stack direction={{md:"row",sm:"row",xs:"column"}} alignItems="center" justifyContent="space-between" mb={1}> 
          <Paper
            component="form"
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: {xs:200,sm:500,md:700},ml:{xs:2,sm:5,md:8} }}
          >
          <InputBase
            fullWidth={true}
            sx={{ ml:2, flex: 1 }}
            placeholder="Search by name"
            onChange = {
              (e) => { //console.log('onChange ',e);
                Search(e,fromDate,toDate);
              }
            }
          />
          <Iconify icon="carbon:search"/>
          </Paper>
          <Grid item xs={6} sm={6} md={2}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" > 
            <TextField
              id="datetime-local"
              label="From Date"
              type="date"
              value={fromDate}  
              sx={{mt: { md: 2, xs: 2,sm:2 }}}
              onChange={handleChangeInput('fromDate')}
            /> 
            <TextField
              id="datetime-local"
              label="To Date"
              type="date"
              value={toDate}  
              sx={{mt: { md: 2, xs: 2,sm:2 }}}
              onChange={handleChangeInput('toDate')}
              InputProps = {
                {
                  inputProps: {
                    min: `${(new Date(new Date(fromDate) - (new Date()).getTimezoneOffset()*60000)).toISOString().split('T')[0]}`
                  }
                }
              }
            /> 
            </Stack>
          </Grid>
        </Stack>
             <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  // orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab wrapped label="pistol" {...a11yProps(0)} />
                  <Tab wrapped label="Rifle" {...a11yProps(1)} />
                  <Tab wrapped label="Open Sight" {...a11yProps(2)} />
                </Tabs>
                <TabPanel value={value} index={0}>
                  {studentToggle === false ?pistolDataNR:pistolDataISSF}
                </TabPanel>
                <TabPanel value={value} index={1}>
                  {studentToggle === false ?rifleDataNR:rifleDataISSF}
                </TabPanel>
                <TabPanel value={value} index={2}>
                  {studentToggle === false ?openSightDataNR:openSightDataISSF}
                </TabPanel>
              </Box>
            </Box>
      </Container>
    </Page>
  );
}

 
export default LeaderBoard;