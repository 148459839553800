import * as React from 'react';
import { forwardRef } from 'react';
import { useRef,useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Container,
    Grid,
    Table,
    TableBody,
    Paper,
    TableRow,TableContainer,TableCell, 
    Stack,
    Divider,
    Card,
    CardHeader, Box, Typography,Button
} from '@mui/material';

import Page from '../components/Page';
import {
    AppWidgetSummary,
    MatchAreaChart,
    MatchHeatMap,
    DonutSummary,
} from '../sections/@dashboard/app';
import MatchComment from './MatchComment';
import MatchAttachmentUpload from './MatchAttachmentUpload';
import {defaultAcademy} from '../constants';

const MatchDashboard = forwardRef(({
            children,
            matchDetails = [],
            shotDetails = [],
            courseDetails = [],
            seriesDataFormated = [],
            series = [],
            directionCount= [],
            academyDetails=[],
             meta,
            ...other
        }, ref) => {
    const [uploadModel, setuploadModel] = React.useState(false);
    const courseDetail = courseDetails.length === 0 ? null :`${courseDetails[0].displayName.toUpperCase()} ${courseDetails[0].range}m`;

    return (
        <Page title="MatchDashboard">
        <Container maxWidth="xl">
            {/* {console.log(courseDetails,matchDetails)} */}
            <Grid container spacing={5}>                                    
                <Grid container spacing={2}>
                    <Grid  item xs={12} sm={4} md={4}>
                        <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 250 }} aria-label="simple table">
                            <TableBody>
                                <TableRow >
                                    <TableCell scope="row">Date</TableCell>
                                    <TableCell scope="row">{matchDetails[0].matchOn?matchDetails[0].matchOn.split("T")[0]:matchDetails[0].createdAt.split("T")[0]}</TableCell>
                                </TableRow>
                                <TableRow >
                                    <TableCell scope="row">Type</TableCell>
                                    <TableCell >{matchDetails[0].type.toUpperCase().replace("_"," ")}</TableCell>
                                </TableRow>                                
                                <TableRow >
                                    <TableCell scope="row">Match</TableCell>
                                    <TableCell >{`${courseDetail}`}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={6} sm={2} md={2}>
                        {/* <Typography>sa</Typography> */}
                        <AppWidgetSummary title="Over All Score" score="" total={matchDetails[0].overAllScore} icon={'ant-design:android-filled'} />
                    </Grid>                    
                    {matchDetails[0].scoreUpdateLevel === 'match' || matchDetails[0].scoreUpdateLevel === 'series' ? null :
                        <Grid item xs={6} sm={2} md={2}>
                            <AppWidgetSummary title="Highest Score" score="" total={Math.max(...shotDetails.map(o => o.score))!==-Infinity?Math.max(...shotDetails.map(o => o.score)):0} color="info" icon={'ant-design:apple-filled'} />
                        </Grid>
                    }

                    {matchDetails[0].scoreUpdateLevel === 'shot' || matchDetails[0].scoreUpdateLevel === 'livescore' ? null :
                        <Grid item xs={6} sm={2} md={2}>
                            <AppWidgetSummary title="No of 10x" score="" total={matchDetails[0].noOf10x} color="warning" icon={'ant-design:windows-filled'} />
                        </Grid>
                    }
                 
                        <Grid item xs={6} sm={2} md={2}>
                            <AppWidgetSummary title="No of 10" score="" total={matchDetails[0].noOf10===undefined?0:matchDetails[0].noOf10} color="warning" icon={'ant-design:windows-filled'} />
                        </Grid>
                    
                    <Grid item xs={6} sm={2} md={2}>
                        <AppWidgetSummary title="Percentage" score="" total={`${Math.round(matchDetails[0].percentage)}%`} icon={'ant-design:bug-filled'} />
                    </Grid>
                    {academyDetails[0].name.toLowerCase() === defaultAcademy?null:
                        <Stack alignItems="center" direction={{ xs: 'row', sm: 'row' }} ml={2} mt={1} spacing={2}>
                            <Button variant="outlined" onClick={()=>{window.open(`/download/${matchDetails[0]._id}`)}}>Download Match Card</Button>  
                            <Button variant="outlined" onClick={()=>{setuploadModel(uploadModel => !uploadModel)
                                // (<MatchAttachmentUpload matchId={matchDetails[0]._id}/>)
                                }}>Add Match Card</Button>  
                        </Stack>
                     }
                    {uploadModel === true?(<MatchAttachmentUpload matchId={matchDetails[0]._id} />):null}
                    
                    <Card style={{marginTop:"2%",marginLeft:"2%",width:'100%'}} xs={12} md={12} lg={12}>
                      <MatchComment matchDetails={matchDetails} />
                    </Card>
                    {matchDetails[0].scoreUpdateLevel === 'match' || matchDetails[0].scoreUpdateLevel === 'series' ? null :
                        <Grid item xs={12} md={12} lg={12}>
                            <MatchAreaChart Details={shotDetails} seriesName='Score'/>
                        </Grid>
                    }

                    {matchDetails[0].scoreUpdateLevel === 'match' ? null :
                        <Grid item xs={12} md={12} lg={12}>
                            <MatchAreaChart Details={series} seriesName='Score' name="series"/>
                        </Grid>
                    }
                    {matchDetails[0].scoreUpdateLevel === 'match' || matchDetails[0].scoreUpdateLevel === 'series' ? null :
                        <Grid item xs={12} md={12} lg={12}>
                            <MatchHeatMap Details={shotDetails} seriesDataFormated={seriesDataFormated}/>
                        </Grid>
                    }
                    {matchDetails[0].scoreUpdateLevel === 'match' || matchDetails[0].scoreUpdateLevel === 'series' || matchDetails[0].scoreUpdateLevel === 'livescore' ? null :
                        <DonutSummary directionCount={directionCount} header="Score of each direction" />                    
                    }                    
                </Grid>
            </Grid>
        </Container>
        </Page>
    // )
)});
MatchDashboard.propTypes = {
    children: PropTypes.node.isRequired,
    matchDetails: PropTypes.array,
    shotDetails: PropTypes.array,
    courseDetails: PropTypes.array,
    seriesDataFormated: PropTypes.array,
    series: PropTypes.array,
    directionCount: PropTypes.array,
    academyDetails: PropTypes.array,
    meta: PropTypes.node,
};
export default MatchDashboard;
