import * as React from 'react';
import { useParams } from 'react-router-dom';
import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import axios from "axios";
import toast from "react-hot-toast";
import Scrollbar from "react-scrollbar";
import { useState, useEffect } from 'react';
// material
import {
  Stack,
  Button,
  Typography,
  Container, Modal, Box, TextField,
  Tabs, Tab,
  Table, TableBody, TableCell, TableContainer, Paper, TableHead, TableRow, TablePagination
} from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import Toast from "../components/Toast";
import {userDetails} from '../constants'

const CompetitionUserRequest = forwardRef(({
            children,
            ...other
        }, ref) => {
    
  const { competitionId } = useParams();
  const [open, setOpen] = React.useState(false);
  const [openApprove, setOpenApprove] = React.useState(false);
  const [user, setUser] = React.useState([{academyId:"",role:[""]}]);
  const [academyDetails, setAcademyDetails] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [selectedFile, setselectedFile] = useState("");
  const [isSubmitting, setisSubmitting] = React.useState(false);
  const [valueTab, setValueTab] = React.useState(0);
  const [value, setValue] = React.useState({
    userId:'',
    tnxId:'',
    tnxDate: `${new Date(new Date().setDate(new Date().getDate())).toISOString().split('T')[0]}`,
    comment:""
  });
  const [selectedItem,setSelectedItem] = useState({});
  const [comment, setComment] = React.useState('');
  const [allRegisterDetail, setAllRegisterDetail] = React.useState([]);
  const [individualGroup, setindividualGroup] = React.useState([]);
  const [teamGroup, setteamGroup] = React.useState([]);
  const [mixedGroup, setmixedGroup] = React.useState([]);

  useEffect(() => {
    getAllRegistration();
    getUserDetail();
  }, []);
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  const getUserDetail = () => {
      axios
        .post(`/api/user/getParticularUserByEmail`, {
          "emailID": userDetails.email
        })
        .then((response) => {
          const userData = response.data.data;
          setUser(userData)
          getDetails(userData[0].academyId)
        })
        .catch((error) => {
          console.log(error)
          toast.error(error.response.data);
        });
  }
  const getDetails = (id) => {
    axios
      .post(`/api/academy/getParticularAcademy`, {
        "_id": id
      })
      .then((response) => {
        const details = response.data.data;        
        setAcademyDetails(details[0]);        
      })
      .catch((error) => {
        console.log(error)
        toast.error(error);
      });
  }
  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  const handleChangeInput = (props) => (event) => {
      setValue({...value, [props]:event.target.value});
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getAllRegistration = () =>{
    axios
    .post(`/api/compUserMapper/getByCompId`, {
      competitionId: competitionId
    })
    .then((response) => {
      if(response.data.data.length > 0){
        // setAllRegisterDetail(response.data.data)        
        getAllPublicRegistration(response.data.data);
      }else{ }
    })
    .catch((error) => {
      console.log(error)
      setisSubmitting(false)
      toast.error(error.response.data);
    });
  }

  const getAllPublicRegistration = (data) =>{
    let dataValue = data
    axios
    .post(`/api/compPublicUserMapper/getByCompId`, {
      competitionId: competitionId
    })
    .then((response) => {
      if(response.data.data.length > 0){
        response.data.data.map((item)=>{
          dataValue.push(item)
        })
        console.log("--------------------------------");
        console.log(dataValue);
        // Define three different arrays for each category
        let individualGroup = [];
        let teamGroup = [];
        let mixedGroup = [];

        // Iterate through the array and group objects based on category
        dataValue.forEach(obj => {
          console.log(obj.request[0].category);
            if (obj.request[0].category === 'individual' || !obj.request[0].category) {
                individualGroup.push(obj);
            } else if (obj.request[0].category === 'team') {
                teamGroup.push(obj);
            } else if (obj.request[0].category === 'mixed') {
                mixedGroup.push(obj);
            }
        });
        console.log(individualGroup);
        console.log(teamGroup);
        console.log(mixedGroup);
        setAllRegisterDetail(dataValue)
        setindividualGroup(individualGroup);
        setteamGroup(teamGroup);
        setmixedGroup(mixedGroup);
      }else{ }
    })
    .catch((error) => {
      console.log(error)
      setisSubmitting(false)
      toast.error(error.response.data);
    });
  }

  const onChangeHandlerForZip = (e) => {
    try {
      let extension = e.target.files[0].name.split(".")[1];
      if (extension.toLowerCase() !== "png") {
        toast.error("Please upload the image in .png format");
      } else if (e.target.files[0].size >= 1 * 1024 * 1024) {
        toast.error("Image size should be less than 1 MB");
      } else {
        setselectedFile(e.target.files[0]);
      }
    } catch {
      console.log("No File selected");
    }
  };

  const handleUpload = (data) => {
    setisSubmitting(true);
    const data1 = new FormData();
    data1.append("requestId", data.id);
    data1.append("file", selectedFile);
    // console.log(user[0].academyId)
    axios
      .post(`/api/userAcademyRequest/uploadFile`, data1, {})
      .then((res) => {
        sendMail(data)
        if (res.status === 200) {
          setisSubmitting(false)
          setselectedFile('')
          setOpen(false);
          setAllRegisterDetail([]);
          getAllRegistration();
          setValue({
            ...value,
            tnxId: "",
            userId: '',
            tnxDate: `${new Date(new Date().setDate(new Date().getDate())).toISOString().split('T')[0]}`
          })
          toast.success(res.data.message);
        } else {
          toast.error("Error in uploading the image");
        }
      })
      .catch((e) => {
        // console.log(e.response);
        toast.error(e.response.data.message);
      });
  };

  const addRequest = ()=>{
    if (value.tnxId==="" || selectedFile.length == 0){
      toast.error("Please provide all the inputs");
    }else{
      let api='';
      if(selectedItem.request[0].userLevel === "public"){
        api = `/api/compPublicUserMapper/update`
      }else{
        api = `/api/compUserMapper/update`
      }
      axios
        .put(api, {
          _id:selectedItem.request[0].id,
          academyId: selectedItem.academy[0][0]._id,
          requestBy: user[0]._id,
          status: "Academy Approved",
          tnxDate: value.tnxDate,
          tnxId: value.tnxId,
          approvalLevel: {"level0":"approved","level1":"pending"}
        })
        .then((response) => {
          // console.log(response.data.data._id)
          if (selectedFile.length != 0){
            handleUpload(selectedItem.request[0])
          }else{
            setselectedFile('')
            setOpen(false);
            setAllRegisterDetail([]);
            getAllRegistration();
            setValue({
              ...value,
              tnxId: "",
              userId: '',
              tnxDate: `${new Date(new Date().setDate(new Date().getDate())).toISOString().split('T')[0]}`,
              comment:''
            })
            sendMail(selectedItem.request[0])
          }
        })
        .catch((error) => {
          console.log(error)
          if (error.message == "Request failed with status code 404"){
            toast.error("Requested user not found")
          }else{
          toast.error(error.message);}
        });
    }
  }

  const sendMail =(data)=>{
    let shooterID ="";
    let userID = "";
    if(selectedItem.request[0].userLevel === "public"){
      shooterID = selectedItem.request[0].emailId
      userID = selectedItem.user[0]
    }else{
      shooterID = selectedItem.user[0][0].emailID
      userID = selectedItem.user[0][0].userName
    }
    axios
      .post(`/api/compUserMapper/sendMail`, {
        shooterId: shooterID,
        emailID: selectedItem.competition[0][0].createdBy,
        requestBy: user[0].emailID,
        userId: userID,
        tnxDate: value.tnxDate,
        tnxId: value.tnxId,
        id:selectedItem.request[0].id,
        competition:selectedItem.competition[0][0].name
      })
      .then((response) => {
        // console.log(response.data.data._id)
        setValue({
          ...value,
          tnxId: "",
          userId: '',
          tnxDate: `${new Date(new Date().setDate(new Date().getDate())).toISOString().split('T')[0]}`
        })
      })
      .catch((error) => {
          toast.error(error.message);
      });
  }

  const imageCheck =(id)=>{
    axios.get(`/paymentPic/${id}.png`, {responseType: 'blob'})
     .then(res => {
         var imageUrl = URL.createObjectURL(res.data);
         window.open(imageUrl, '_blank');
     }).
     catch(e=>{
       toast.error("error in fetching the screenshot/ No Screenshot")
     })  
  }

  const handleApproval =(data)=>{
    if(value.comment === ''){
      toast.error(`Please provide the comment to ${data}`);
    }else{
      let data1 = {
        _id:selectedItem.request[0].id,
        status: data,
        comment:value.comment,
        approvalLevel: {"level0":"approved","level1":data}
      }
      let api='';
      if(selectedItem.request[0].userLevel === "public"){
        api = `/api/compPublicUserMapper/update`
      }else{
        api = `/api/compUserMapper/update`
      }
      axios
        .put(api, data1)
        .then((response3) => {
          setOpenApprove(false);
          setValue({
            ...value,
            comment:""
          })
          setAllRegisterDetail([]);
          getAllRegistration();
          sendMailApproval(data1)
        })
        .catch((error) => {
            console.log(error)
          toast.error(error.message);
        });
    }
  }

  const sendMailApproval = (data) => {
    let shooteremail = selectedItem.user[0][0].emailID;
    let userID = "";
    if(selectedItem.request[0].userLevel === "public"){
      shooteremail = selectedItem.request[0].emailId
      userID = selectedItem.user[0]
    }else{
      shooteremail = selectedItem.user[0][0].emailID
      userID = selectedItem.user[0][0].displayName
    }
    axios
      .post(`/api/compUserMapper/sendMailApproval`, {
        emailID: shooteremail !=undefined ?shooteremail:null,
        user: userID,
        requestBy: selectedItem.requestedUser[0][0].emailID,
        requestedUser: selectedItem.requestedUser[0][0].displayName,
        status: data.status,
        comment: data.comment
      })
      .then((response) => {
        // console.log(response.data.data._id)
        setValue({
          ...value,
          tnxId: "",
          userId: '',
          tnxDate: `${new Date(new Date().setDate(new Date().getDate())).toISOString().split('T')[0]}`,
          comment:""
        })
      })
      .catch((error) => {
        // console.log("**********",error.message)
        toast.error(error.message);
      });
  }
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  function isArrayObjects(arr) {
    return Array.isArray(arr) && arr.every(item => typeof item === 'object');
  }
  return (
    <Page title="User Request">
      <Toast /> 
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>                           
            <Typography variant="body2" mt={1} >
              {allRegisterDetail.length > 0 ?`Total Registration - ${allRegisterDetail.length}` :null}
            </Typography>
            <Tabs
              variant="scrollable"
              value={valueTab}
              onChange={handleChangeTab}
              aria-label="basic tabs example"
            // sx={{ borderRight: 1, borderColor: 'divider',width:{xs:250,sm:100,md:100} }}
            >
              {/* <Tab wrapped label="Info" {...a11yProps(0)} /> */}
              <Tab wrapped label="Individual" {...a11yProps(0)} />
              <Tab wrapped label="Team" {...a11yProps(1)} />
              <Tab wrapped label="Mixed" {...a11yProps(2)} />
            </Tabs> 
          </Box>
      <Container>  
        
        {open === true ?<Stack>
        <Stack alignItems="left" direction={{ xs: 'column', sm: 'column' }}>
           <TextField
            id = "filled-flexible"
            defaultValue = {selectedItem.user[0].length>0 && selectedItem.user[0][0].displayName!=undefined ?selectedItem.user[0][0].displayName: selectedItem.user[0]!=undefined ? selectedItem.user[0] : "-"}
            value={selectedItem.user[0].length>0 && selectedItem.user[0][0].displayName!=undefined ?selectedItem.user[0][0].displayName: selectedItem.user[0]!=undefined ? selectedItem.user[0] : "-"}
            varient="standard"
            label = "Shooter Name"
            type = 'text'
            disabled
            sx={{mt:2,width:'100%'}}
            onChange={handleChangeInput('userId')}
            /> 
            <TextField
            id = "filled-flexible"
            defaultValue = {value.tnxId}
            value={value.tnxId}
            varient="standard"
            label = "Transaction Id"
            type = 'text'
            sx={{mt:2,width:'100%'}}
            onChange={handleChangeInput('tnxId')}
            />
            <TextField
              id="datetime-local"
              label="Transaction Date"
              type="date"
              value={value.tnxDate}  
              sx={{mt: { md: 2, xs: 2,sm:2 }}}
              onChange={handleChangeInput('tnxDate')}
              InputProps = {
                {
                  inputProps: {
                    max: `${(new Date(new Date() - (new Date()).getTimezoneOffset()*60000)).toISOString().split('T')[0]}`
                  }
                }
              }
            /> 
            <Typography id="modal-modal-title" variant="h6" component="h2" mb={2} mt={2}>
              Upload Transaction screenshot
          </Typography>
              <input
                accept = "image/png"
                style={{ display: 'none' }}
                id="raised-button-file"
                onChange={onChangeHandlerForZip}
                type="file"
                name="logo"
              />
                <label htmlFor="raised-button-file">                              
                <Button variant="contained" component="span" mb={2} mt={2} startIcon={<Iconify icon="entypo:upload" />}>
                    Upload
                </Button>
                <Typography variant="body1" mt={1} >
                  {selectedFile ? selectedFile.name : <>select screenshot</>}
                </Typography>
                </label>
        </Stack>
        <Stack alignItems="left" direction={{ xs: 'column', sm: 'column',md:'row' }} spacing={{ xs: 1, sm: 1, md: 1 }} mt={1}>
          <Button variant="outlined" onClick={addRequest}>Request</Button>
          <Button variant="outlined" onClick={()=>{setOpen(false);
            setValue({...value,
                userId: '',
                tnxId: '',
                tnxDate: `${new Date(new Date().setDate(new Date().getDate())).toISOString().split('T')[0]}`
              })
          }}>Close</Button>
        </Stack> </Stack>:<div>
        <TabPanel value={valueTab} index={0}>
          <Scrollbar style={{ height: "61vh", width: "98.5%", paddingRight: "1rem" }} >
          <TableContainer component={Paper} mt={3}>
            <Table stickyHeader sx={{ minWidth: 350 }} size="small" aria-label="a dense table">
              <TableHead>
              <TableRow>
              <TableCell align="left">Shooter Name</TableCell>
              <TableCell align="left">Contact No</TableCell>
              <TableCell align="left">Academy Name</TableCell>
              <TableCell align="left">Match Code</TableCell>
              <TableCell align="left">Status</TableCell>
              <TableCell align="left">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {individualGroup.length >0 && individualGroup.map((item,index)=>{
                return (
                  <TableRow>
                    <TableCell align="left">{item.user[0].length>0 && item.user[0][0].displayName!=undefined ?item.user[0][0].displayName : item.user[0]!=undefined ? item.user[0] : "-"}</TableCell>
                    {/* {console.log(item)} */}
                    <TableCell align="left">{item.user[0].length>0 && item.user[0][0].contactNo!=undefined ?item.user[0][0].contactNo : item.request[0]!=undefined ? item.request[0].contactNo : "-"}</TableCell>
                    <TableCell align="left">{item.academy[0].length>0 && item.academy[0][0].name != 'Academy Not Available' ?item.academy[0][0].name:item.request[0].extAcademyName ? item.request[0].extAcademyName : "-"}</TableCell>
                    
                    <TableCell align="left">
                      {
                        item.request[0].matchCategorySelected.length > 0 ? isArrayObjects(item.request[0].matchCategorySelected) ? (item.request[0].matchCategorySelected.map((item1,inde)=>{return(`${item1.value} ${item.request[0].matchCategorySelected.length == inde+1?"":","} `)})) : item.request[0].matchCategorySelected.join(","):null}
                    </TableCell>
                    
                    <TableCell align="left" sx={{color:item.request[0].status==='approved'?"green":item.request[0].status==='declined'?"red":"orange"}}>{item.request[0].status.toUpperCase()}</TableCell>
                    {item.request[0].status.toLowerCase() == "registered" && (user[0].academyId == item.request[0].academyId || user[0].role.includes("superAdmin")) ?
                    
                    <TableCell align="left">
                      <Iconify icon = "material-symbols:add-task" sx={{cursor: "pointer"}} onClick={()=>{setOpen(true);setSelectedItem(item)}}/>
                    </TableCell>
                    :
                    item.request[0].status.toLowerCase() == "academy approved" && (user[0].emailID == item.competition[0][0].createdBy || user[0].academyId == item.competition[0][0].createrAcademy || user[0].role.includes("superAdmin"))? 
                    <TableCell align="left">
                    <Iconify icon = "ion:attach-sharp" sx={{cursor: "pointer"}} onClick={()=>{imageCheck(item.request[0].id)}}/>
                    <Iconify icon = "mdi:account-tick-outline" sx={{cursor: "pointer"}} onClick={()=>{setOpenApprove(true);setSelectedItem(item)}}/>
                    </TableCell>
                    : <TableCell align="left">-</TableCell>}
                  </TableRow>
                )
              })}
              </TableBody>
            </Table>
            </TableContainer>
          </Scrollbar>
        </TabPanel>
        <TabPanel value={valueTab} index={1}>
          <Scrollbar style={{ height: "61vh", width: "98.5%", paddingRight: "1rem" }} >
          <TableContainer component={Paper} mt={3}>
            <Table stickyHeader sx={{ minWidth: 350 }} size="small" aria-label="a dense table">
              <TableHead>
              <TableRow>
              <TableCell align="left">Team</TableCell>
              <TableCell align="left">Shooter 1</TableCell>
              <TableCell align="left">Shooter 2</TableCell>
              <TableCell align="left">Shooter 3</TableCell>
              <TableCell align="left">Academy Name</TableCell>
              <TableCell align="left">Match Code</TableCell>
              <TableCell align="left">Status</TableCell>
              <TableCell align="left">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {teamGroup.length >0 && teamGroup.map((item,index)=>{
                return (
                  <TableRow>
                  <TableCell align="left">{item.user[0].length>0 && item.user[0][0].displayName!=undefined ?item.user[0][0].displayName : item.user[0]!=undefined ? item.user[0] : "-"}</TableCell>
                  
                  <TableCell align="left">{item.request[0].emailId ? item.request[0].emailId : "-"}</TableCell>  
                  
                  <TableCell align="left">{item.request[0].shooter2 ? item.request[0].shooter2 : "-"}</TableCell>

                  <TableCell align="left">{item.request[0].shooter3 ? item.request[0].shooter3 : "-"}</TableCell>
                    <TableCell align="left">{item.academy[0].length>0 && item.academy[0][0].name != 'Academy Not Available' ?item.academy[0][0].name:item.request[0].extAcademyName ? item.request[0].extAcademyName : "-"}</TableCell>
                    
                    <TableCell align="left">
                      {
                        item.request[0].matchCategorySelected.length > 0 ? isArrayObjects(item.request[0].matchCategorySelected) ? (item.request[0].matchCategorySelected.map((item1,inde)=>{return(`${item1.id} ${item.request[0].matchCategorySelected.length == inde+1?"":","} `)})) : item.request[0].matchCategorySelected.join(","):null}
                    </TableCell>
                    
                    <TableCell align="left" sx={{color:item.request[0].status==='approved'?"green":item.request[0].status==='declined'?"red":"orange"}}>{item.request[0].status.toUpperCase()}</TableCell>
                    {item.request[0].status.toLowerCase() == "registered" && (user[0].academyId == item.request[0].academyId || user[0].role.includes("superAdmin")) ?
                    
                    <TableCell align="left">
                      <Iconify icon = "material-symbols:add-task" sx={{cursor: "pointer"}} onClick={()=>{setOpen(true);setSelectedItem(item)}}/>
                    </TableCell>
                    :
                    item.request[0].status.toLowerCase() == "academy approved" && (user[0].emailID == item.competition[0][0].createdBy || user[0].academyId == item.competition[0][0].createrAcademy || user[0].role.includes("superAdmin"))? 
                    <TableCell align="left">
                    <Iconify icon = "ion:attach-sharp" sx={{cursor: "pointer"}} onClick={()=>{imageCheck(item.request[0].id)}}/>
                    <Iconify icon = "mdi:account-tick-outline" sx={{cursor: "pointer"}} onClick={()=>{setOpenApprove(true);setSelectedItem(item)}}/>
                    </TableCell>
                    : <TableCell align="left">-</TableCell>}
                  </TableRow>
                )
              })}
              </TableBody>
            </Table>
            </TableContainer>
          </Scrollbar>
        </TabPanel>
        <TabPanel value={valueTab} index={2}>
          <Scrollbar style={{ height: "61vh", width: "98.5%", paddingRight: "1rem" }} >
          <TableContainer component={Paper} mt={3}>
            <Table stickyHeader sx={{ minWidth: 350 }} size="small" aria-label="a dense table">
              <TableHead>
              <TableRow>
              <TableCell align="left">Team</TableCell>
              <TableCell align="left">Shooter 1</TableCell>
              <TableCell align="left">Shooter 2</TableCell>
              <TableCell align="left">Academy Name</TableCell>
              <TableCell align="left">Match Code</TableCell>
              <TableCell align="left">Status</TableCell>
              <TableCell align="left">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mixedGroup.length >0 && mixedGroup.map((item,index)=>{
                return (
                  <TableRow>
                    <TableCell align="left">{item.user[0].length>0 && item.user[0][0].displayName!=undefined ?item.user[0][0].displayName : item.user[0]!=undefined ? item.user[0] : "-"}</TableCell>
                    <TableCell align="left">{item.request[0].emailId ? item.request[0].emailId : "-"}</TableCell>  
                  
                    <TableCell align="left">{item.request[0].shooter2 ? item.request[0].shooter2 : "-"}</TableCell>
                    <TableCell align="left">{item.academy[0].length>0 && item.academy[0][0].name != 'Academy Not Available' ?item.academy[0][0].name:item.request[0].extAcademyName ? item.request[0].extAcademyName : "-"}</TableCell>
                    
                    <TableCell align="left">
                      {
                        item.request[0].matchCategorySelected.length > 0 ? isArrayObjects(item.request[0].matchCategorySelected) ? (item.request[0].matchCategorySelected.map((item1,inde)=>{return(`${item1.id} ${item.request[0].matchCategorySelected.length == inde+1?"":","} `)})) : item.request[0].matchCategorySelected.join(","):null}
                    </TableCell>
                    
                    <TableCell align="left" sx={{color:item.request[0].status==='approved'?"green":item.request[0].status==='declined'?"red":"orange"}}>{item.request[0].status.toUpperCase()}</TableCell>
                    {item.request[0].status.toLowerCase() == "registered" && (user[0].academyId == item.request[0].academyId || user[0].role.includes("superAdmin")) ?
                    
                    <TableCell align="left">
                      <Iconify icon = "material-symbols:add-task" sx={{cursor: "pointer"}} onClick={()=>{setOpen(true);setSelectedItem(item)}}/>
                    </TableCell>
                    :
                    item.request[0].status.toLowerCase() == "academy approved" && (user[0].emailID == item.competition[0][0].createdBy || user[0].academyId == item.competition[0][0].createrAcademy || user[0].role.includes("superAdmin"))? 
                    <TableCell align="left">
                    <Iconify icon = "ion:attach-sharp" sx={{cursor: "pointer"}} onClick={()=>{imageCheck(item.request[0].id)}}/>
                    <Iconify icon = "mdi:account-tick-outline" sx={{cursor: "pointer"}} onClick={()=>{setOpenApprove(true);setSelectedItem(item)}}/>
                    </TableCell>
                    : <TableCell align="left">-</TableCell>}
                  </TableRow>
                )
              })}
              </TableBody>
            </Table>
            </TableContainer>
          </Scrollbar>
        </TabPanel>
          </div>
        }
        <Modal
          open={openApprove}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            component="form"
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '46%',
              bgcolor: 'background.paper',
              p: 4,
            }}
            noValidate
            autoComplete="off"
          >
            <Typography variant="h5" gutterBottom component="div">
              Do you need to approve the user request for competition? 
            </Typography>
            <TextField
            id = "filled-flexible"
            defaultValue = {value.comment}
            value={value.comment}
            multiple
            varient="standard"
            label = "Comment"
            type = 'text'
            sx={{mt:2,width:'100%'}}
            onChange={handleChangeInput('comment')}
            /> 
            <Stack
              alignItems="center"
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
              mt={2}
              mb={2}
            >            
              <Button variant="outlined" onClick={()=>{handleApproval("approved")}}>
                Approve
              </Button>
              <Button variant = "outlined" color = "error" onClick={()=>{handleApproval("declined")}} >
                Decline
              </Button>
              <Button variant = "outlined" color = "error" onClick = {() => {setOpenApprove(false)}} >
                close
              </Button>
            </Stack>
          </Box>
        </Modal>
      </Container>
    </Page>
  );
});
CompetitionUserRequest.propTypes = {
    children: PropTypes.node.isRequired,
};
export default CompetitionUserRequest;
