import * as React from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Link as RouterLink } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  Box,
  Modal,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
  Divider,
  Paper,
  TableHead,
  MenuItem,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import Toast from '../components/Toast';
import Scrollbar from '../components/Scrollbar';
import MatchDashboard from './matchDashboard';
import _ from 'lodash';
import { Course, Type, Range, userDetails } from '../constants';
import {
  AppWidgetSummary
} from '../sections/@dashboard/app';

const MatchDetails = () => {
  const { matchId } = useParams();
  const { emailId } = useParams();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [open, setOpen] = React.useState(false);
  const [remove, setDelete] = React.useState(false);
  const [match, setMatch] = React.useState([]);
  const [academyDetails, setAcademyDetails] = React.useState([{name:""}]);
  const [CourseAll, setCourseAll] = React.useState([]);
  const [course, setCourse] = React.useState([]);
  const [courseDetails, setCourseDetails] = React.useState([]);
  const [series, setSeries] = React.useState([]);
  const [directionCount, setDirectionCount] = React.useState([]);
  const [seriesDataFormated, setSeriesDataFormated] = React.useState([]);
  const [seriesLength, setSeriesLength] = React.useState(0);
  const [sliceNumber, setSliceNumber] = React.useState(3);
  const [seriesDetail, setSeriesDetail] = React.useState({});
  const [disable10Button, setDisable10Button] = React.useState(false);
  const [shot, setShot] = React.useState([]);
  const [totalShot, setTotalShot] = React.useState(0);
  const [matchShot, setMatchShot] = React.useState([]);
  const [user, setUser] = React.useState([]);
  const [userId, setUserId] = React.useState('');
  const [score, setScore] = useState('');
  const [direction, setDirection] = useState('');
  const [directionPoint, setDirectionPoint] = useState('');
  const [shotOver, setShotOver] = useState(false);
  const [shotOverDashboard, setShotOverDashboard] = useState(false);
  const [isSubmitting, setisSubmitting] = React.useState(true);
  const [shotvalues, setShotValues] = React.useState([]);
  const [shotNewvalues, setShotNewValues] = React.useState([]);
  const [seriesValues, setSeriesValues] = React.useState([]);
  const [seriesNewvalues, setSeriesNewValues] = React.useState([]);
  const [matchvalues, setMatchValues] = React.useState([]);
  const [values, setValues] = React.useState({
    course: '',
    type: '',
    range: '',
    matchDate: `${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`,
  });
  const [liveData,setLiveData] = React.useState({
    "avg8":0,
    "avg9":0,
    "avg10":0,
    "sum":0,
    "sumDecimal":0
  })
  
  useEffect(() => {
    getMatches();
    getUser();
    getDirectionCount();
    getCourseAll();
  }, []);

  const getMatches = () => {
    axios
      .post(`/api/matches/getParticularMatches`, { _id: matchId })
      .then((response) => {
        const MatchData = response.data.data;
        setisSubmitting(false);
        setMatchValues(MatchData);
        setMatch(MatchData);
        getseries(MatchData);
        getCourse(MatchData);
        if (MatchData[0].status === 'Completed') {
          setShotOverDashboard(true);
        }
      })

      .catch((error) => {
        // console.log(error)
        toast.error(error.response.message);
      });
  };

  const getseries = (MatchData) => {
    axios
      .post(`/api/series/getSeriesByMatchId`, { matchId: matchId })
      .then((response) => {
        if (response.data.data.length > 0) {
          const seriesData = response.data.data;
          setSeries(seriesData);
          setSeriesValues(seriesData);
          // setSeriesDetail(seriesData[seriesLength])
          getMatchShots(MatchData, seriesData);
          seriesData.some((item, index) => {
            if (item.status === 'Inprogress') {
              setSeriesDetail(item);
              setSeriesLength(index);
              getshot(item);
              return true;
            }
            return false;
          });
        }
      })
      .catch((error) => {
        toast.error(error.response.data);
      });
  };

  const getshot = (series) => {
    axios
      .post(`/api/shot/getShotBySeriesId`, { seriesId: series._id })
      .then((response) => {
        if (response.data.data.length > 0) {
          const shotData = response.data.data;
          // console.log("shotData ",shotData)
          setShot(shotData);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data);
      });
  };

  const getMatchShots = (MatchData, seriesData) => {
    axios
      .post(`/api/shot/getShotByMatchId`, {
        matchId: matchId,
      })
      .then((response) => {
        const shotData = response.data.data;
        setMatchShot(shotData);
        setShotValues(shotData);
        setTotalShot(shotData.length);
        liveScoreCalculation(matchId, MatchData, shotData.length)
        const seriesArray = [];
        seriesData.map((item, index) => {
          const obj = {
            name: item.name,
            data: [],
            direction: [],
          };
          shotData.map((shot, index1) => {
            if (item._id === shot.seriesId) {
              obj.data.push(shot.score);
              obj.direction.push(shot.direction);
            }
            return obj;
          });
          seriesArray.push(obj);
          return seriesArray;
        });
        setSeriesDataFormated(seriesArray);
        // if (shotData.length === (MatchData[0].noOfSeries * 10)) {
        //   setShotOverDashboard(true);
        // }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data);
      });
  }; 

  const liveScoreCalculation = (matchId,match,shotLength) => {
    axios
      .post(`/api/shot/liveScoreCalculation`, {
        matchId: matchId,
      })
      .then((response) => {
        const shotData = response.data.data;
        if(shotData.length >0){
          setLiveData({
            ...liveData,
            "sum": shotData[0].sum,
            "sumDecimal": shotData[0].sumDecimal
          })
          liveCalculation(0,match,shotData[0].sum,shotData[0].sumDecimal,shotLength);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data);
      });
  };

  const liveCalculation =(score,match,sum,sumDecimal,shotLength)=>{
    let sumDecimalvalue = Math.round((sumDecimal + score) * 10) / 10;
    let sumvalue = Math.round(sum + parseInt(score));
    let scoreLength = (match[0].noOfSeries * 10) - shotLength
    let avg8 = Math.floor((scoreLength * 8) + sumvalue)
    let avg9 = Math.floor((scoreLength * 9) + sumvalue)
    let avg10 = Math.floor((scoreLength * 10) + sumvalue)
    setLiveData({...liveData,
      "avg8": avg8,
      "avg9": avg9,
      "avg10": avg10,
      "sum": sumvalue,
      "sumDecimal": sumDecimalvalue
    })
  }

  const getDirectionCount = () => {
    axios
      .post(`/api/shot/scoreCountByDirection`, {
        matchId: matchId,
      })
      .then((response) => {
        const value = response.data.data;

        const data = [];
        const sampleData = [];
        sampleData.push({name: "A",data: []}, {name: "B",data: []},{name: "C",data: []}, {name: "D",data: []});
        value.map((item, index) => {
          const obj = {
            name: item._id,
            data: [item.countOf10, item.countOf9, item.countOf8, item.countOf7, item.countLessThan6],
          };
          data.push(obj);
          return data;
        });
        let matchcount=[]
        sampleData.map((item, index) => {
          const match = data.find((e) => e.name === item.name)
          matchcount.push({name:item.name,data:match?.data ?? []})
        })
        // console.log(matchcount)
        // console.log(data)
        setDirectionCount(matchcount);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data);
      });
  };

  const getCourse = (CourseData) => {
    axios
      .post(`/api/course/getParticularCourse`, {
        _id: CourseData[0].courseId,
      })
      .then((response) => {
        const courseData = response.data.data;
        // console.log("course ", courseData, "**** ", CourseData[0], CourseData[0].matchOn)
        let matchdate =
          CourseData[0].matchOn === undefined
            ? `${CourseData[0].createdAt.split(':')[0]}:${CourseData[0].createdAt.split(':')[1]}`
            : `${CourseData[0].matchOn.split(':')[0]}:${CourseData[0].matchOn.split(':')[1]}`;

        values.course = `${courseData[0].type}-${courseData[0].range}`;
        values.type = CourseData[0].type;
        values.range = `${CourseData[0].noOfSeries}`;
        values.matchDate = matchdate;
        setValues(values);
        setCourse(courseData);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data);
      });
  };

  const getCourseAll = () => {
    axios
      .get(`/api/course/getAll`)
      .then((response) => {
        const courseData = response.data.data;
        const value = [];
        courseData.map((item, index) => {
          const value1 = {
            value: `${item.type}-${item.range}`,
            label: `${item.displayName} ${item.range}m`,
          };
          value.push(value1);
        });
        setCourseAll(value);
        setCourseDetails(courseData);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data);
      });
  };

  const getUser = () => {
    let mail = userDetails.email;
    if (emailId !== undefined){
      mail = emailId
    }
    axios
      .post(`/api/user/getParticularUserByEmail`, { emailID: mail })
      .then((response) => {
        const userData = response.data.data;
        setUser(userData);
        setUserId(userData[0]._id);
        getAcademy(userData)
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data);
      });
  };

  const getAcademy = (data) => {
    axios
      .post(`/api/academy/getParticularAcademy`, {
        "_id": data[0].academyId
      })
      .then((response) => {
        let acadata = response.data.data;
        setAcademyDetails(acadata);
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response.data);
      });
  }

  const matchStatusUpdate = () => {
    axios
      .put(`/api/matches/update`, {
        _id: matchId,
        status: 'Completed',
      })
      .then((response) => {
        matchDetial();
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data);
      });
  };

  const matchDetial = () =>{
    axios
      .post(`/api/matches/getParticularMatches`, {
        _id: matchId
      })
      .then((response) => {
        mailNotification(response.data.data[0])
      })
      .catch((error) => {
        // console.log(error)
        toast.error(error.response.message);
      });
  }

  const handleChangeButton = (event) => {
    setDisable10Button(true);
    if (score === '10') {
      setSliceNumber(4);
    } else {
      setSliceNumber(3);
    }
    if (score.length === 1 || score === '10') {
      setScore(`${score}.${event}`);
    } else {
      setScore(score + event);
    }
  };
  const handleChangeDirection = (event) => {
    if(event >=1 && event <3){
      setDirection("B");
    } else if (event >= 3 && event < 6) {
      setDirection("D");
    } else if (event >= 6 && event < 9) {
      setDirection("C");
    } else if (event >= 9 && event < 12) {
      setDirection("A");
    } else if (event == 12) {
      setDirection("B");
    } else if (event == "x") {
      setDirection("E");
    }
    setDirectionPoint(event);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClose = () => {
    setOpen(false);
    setDelete(false);
  };

  const handleChangeSave = () => {
    if (score === '' || direction === '') {
      toast.error('Enter score and direction.');
      // console.log('** ', match)
    } else {
      setisSubmitting(true);
      const totalShotCount = totalShot + 1;
      const data = {
        matchId: matchId,
        seriesId: seriesDetail._id,
        userId: userId,
        score: parseFloat(score).toFixed(1),
        direction: direction,
        directionPoint: directionPoint,
        name: `shot_${totalShotCount}`,
        displayName: `shot ${totalShotCount}`,
      };
      // console.log(data)
      setTotalShot(totalShotCount);
      // liveCalculation(parseFloat(score).toFixed(1))
      axios
        .post(`/api/shot/add`, data)
        .then((response) => {
          setDisable10Button(false);

          if (match[0].noOfSeries === 0.5 && shot.length + 1 >= 5) {
            // setShotOver(true);
            getMatches();
            setShotOverDashboard(true);
            matchStatusUpdate();
            updateUserPoint(50)
            // toast.success('Successfully done all the shot');
          } else if (series.length === seriesLength + 1 && shot.length + 1 >= 10) {
            // setShotOver(true);
            getMatches();
            setShotOverDashboard(true);
            matchStatusUpdate();
            updateUserPoint(50)
            // toast.success('Successfully done all the shot');
          } else {
            toast.success(response.data.message);
          }

          if (shot.length + 1 >= 10 && series.length >= seriesLength + 1) {
            setShot([]);
            axios
              .put(`/api/series/update`, {
                _id: seriesDetail._id,
                status: 'Completed',
              })
              .then((response) => {
                // const seriesData = response.data.data;
                // setMatchShot(shotData);
                console.log('series status update');
              })
              .catch((error) => {
                console.log(error);
                toast.error(error.response.data);
              });

            setSeriesDetail(series[seriesLength + 1]);
            setSeriesLength(seriesLength + 1);
            getshot(series[seriesLength + 1]);

            axios
              .put(`/api/series/update`, {
                _id: series[seriesLength + 1]._id,
                status: 'Inprogress',
              })
              .then((response) => {
                // const seriesData = response.data.data;
                // setMatchShot(shotData);
                console.log('series status update');
              })
              .catch((error) => {
                console.log(error);
                toast.error(error.response.data);
              });
          } else {
            getshot(seriesDetail);
          }
          getMatchShots(match, series);
          setScore('');
          setDirection('');
          getDirectionCount();
          setisSubmitting(false);
        })
        .catch((error) => {
          setisSubmitting(false);
          console.log(error);
          toast.error(error.response.data.message);
        });
    }
  };

  const handleChangeShot = (props) => (event) => {
    const value = props.split('_');
    const shotNew = shotNewvalues;
    let flag = true;
    shotvalues.map((item, index) => {
      if (item.name === `shot_${value[1]}`) {
        if (shotNew.length === 0) {
          item[`${value[0]}`] = event.target.value;
          shotNew.push(item);
        } else {
          shotNew.some((item1, index1) => {
            if (item1.name === `shot_${value[1]}`) {
              item1[`${value[0]}`] = event.target.value;
              item[`${value[0]}`] = event.target.value;
              flag = false;
            }
          });
          if (flag) {
            item[`${value[0]}`] = event.target.value;
            shotNew.push(item);
          }
        }
        return shotNew;
      }
    });

    setShotNewValues(shotNew);
    setShotValues(shotvalues);
  };
  const updateMatch = (data) => {
    axios
      .put(`/api/matches/update`, data)
      .then((response) => {
        if (response) {
          const data = response.data.data;
          toast.success('Match details updated successfully');
          setOpen(false);
          setisSubmitting(false);
          getMatches();
          getUser();
        }
      })
      .catch((error) => {
        console.log(error);
        setisSubmitting(false);
        toast.error(error.response.data);
      });
  };
  const handleUpdateShot = () => {
    // console.log(`update ${data}`)
    setisSubmitting(true);
    let courseId = '';
    courseDetails.map((value) => {
      const name = `${value.type}-${value.range}`;

      if (name === values.course) {
        courseId = value._id;
      }
      return courseId;
    });
    const data = {
      courseId: courseId,
      noOfSeries: parseFloat(values.range),
      type: values.type,
      matchOn: values.matchDate,
      _id: match[0]._id,
    };
    updateMatch(data);
    shotNewvalues.map((item, index) => {
      axios
        .put(`/api/shot/update`, item)
        .then((response) => {
          if (response) {
            const data = response.data.data;
            getMatches();
            getUser();
            getDirectionCount();

            if (shotNewvalues.length === index + 1) {
              matchDetial();
              toast.success('shot updated successfully');
              setOpen(false);
              setisSubmitting(false);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          setisSubmitting(false);
          toast.error(error.response.data);
        });
    });
  };

  const handleChangeSeries = (props) => (event) => {
    const value = props.split('_');
    const seriesNew = seriesNewvalues;
    let flag = true;
    seriesValues.map((item, index) => {
      if (item.name === `series_${value[1]}`) {
        if (seriesNew.length === 0) {
          item[`${value[0]}`] = event.target.value;
          seriesNew.push(item);
        } else {
          seriesNew.some((item1, index1) => {
            if (item1.name === `series_${value[1]}`) {
              item1[`${value[0]}`] = event.target.value;
              item[`${value[0]}`] = event.target.value;
              flag = false;
            }
          });
          if (flag) {
            item[`${value[0]}`] = event.target.value;
            seriesNew.push(item);
          }
        }
        return seriesNew;
      }
    });
    setSeriesNewValues(seriesNew);
    setSeriesValues(seriesValues);
  };

  const handleUpdateSeries = () => {
    // console.log(`update ${data}`)
    setisSubmitting(true);
    let courseId = '';
    courseDetails.map((value) => {
      const name = `${value.type}-${value.range}`;
      if (name === values.course) {
        courseId = value._id;
      }
      return courseId;
    });
    const data = {
      courseId: courseId,
      noOfSeries: parseFloat(values.range),
      type: values.type,
      matchOn: values.matchDate,
      _id: match[0]._id,
    };
    let shotsCount = 0;
    if (parseFloat(values.range) === 0.5){
      shotsCount = 5
    } else {
      shotsCount = 10
    }
    updateMatch(data);
    seriesNewvalues.map((item, index) => {
      const percent = (item.overAllScore / shotsCount) * 10
      item.percentage = percent;
      axios
        .put(`/api/series/update`, item)
        .then((response) => {
          if (response) {
            const data = response.data.data;
            getMatches();
            getUser();
            getDirectionCount();
            if (seriesNewvalues.length === index + 1) {
               setTimeout(() => {
                  matchDetial();
                  toast.success('series updated successfully');
                  setOpen(false);
                  setisSubmitting(false);
              }, 1000)
            }
          }
        })
        .catch((error) => {
          console.log(error);
          setisSubmitting(false);
          toast.error(error.response.data);
        });
    });
  };
  const handleChange = (props) => (event) => {
    // console.log(props,event.target.value)
    setValues({
      ...values,
      [props]: event.target.value,
    });
  };

  const handleChangeMatch = (props) => (event) => {
    let key = props;
    matchvalues[0][`${key}`] = parseFloat(event.target.value);
    setMatchValues(matchvalues);
  };

  const matchUpdate = () => {
    let courseId = '';
    courseDetails.map((value) => {
      const name = `${value.type}-${value.range}`;

      if (name === values.course) {
        courseId = value._id;
      }
      return courseId;
    });
    const shotsCount = (parseFloat(values.range)) * 10;
    const percent = (matchvalues[0].overAllScore / shotsCount) * 10

    matchvalues[0].courseId = courseId;
    (matchvalues[0].type = values.type), (matchvalues[0].noOfSeries = parseFloat(values.range));
    matchvalues[0].matchOn = values.matchDate;
    matchvalues[0].percentage = percent;

    updateMatch(matchvalues[0]);
    matchDetial();
  };

  const deleteDetails = (level, matchId) => {
    let data = {
      matchId: matchId,
    };
    // console.log(level, matchId, data);
    axios
      .delete(`/api/${level}/delete/matchid`, { data })
      .then((response) => {
        // console.log(`${level} deleted successfully`)
      })
      .catch((error) => {
        console.log(error);
        setisSubmitting(false);
        toast.error(error.response.data);
      });
  };

  const deleteMatch = (matchId) => {
    let data = {
      _id: matchId,
    };
    // console.log(data)
    axios
      .delete(`/api/matches/delete`, { data })
      .then((response) => {
        toast.success('Match deleted successfully');
        setDelete(false);
        window.location.assign(`/dashboard/match`);
      })
      .catch((error) => {
        console.log(error);
        setisSubmitting(false);
        toast.error(error.response.data);
      });
  };

  const matchDelete = () => {
    if (match[0].scoreUpdateLevel === 'shot') {
      deleteDetails('shot', match[0]._id);
      deleteDetails('series', match[0]._id);
      deleteMatch(match[0]._id);
    } else if (match[0].scoreUpdateLevel === 'series') {
      deleteDetails('series', match[0]._id);
      deleteMatch(match[0]._id);
    } else {
      deleteMatch(match[0]._id);
    }
  };

  const mailNotification = (data) => {
    
    axios
      .post(`/api/matches/mailNotification`, {
        emailID: user[0].emailID,
        userName: user[0].userName,
        userDetail: user[0],
        matchDetails: data
      })
      .then((response) => {
        
      })
      .catch((error) => {
        toast.error(error.response);
      });
  }

  const updateUserPoint = (point) => {
    let data = {
      _id: user[0]._id,
      points: user[0].points + point
    }
    
    axios
      .put(`/api/user/update`, data)
      .then((response) => {
        // console.log(response.data)
        toast.success(`Thank you for adding the score. you have been credited with ${point} points for this.`);
      })
      .catch((error) => {
        //   console.log(error)
        toast.error(error.response.data);
      })
  }

  function button(val){
    return (
      <Button variant="contained" onClick={() => {handleChangeDirection(val)}} sx={{padding:0,minWidth:{md:'30px',sm:'30px',xs:'15px'}}}>
       {val}
      </Button>
    )
  }
  let rows = [
    ["","","",button(12),"","",""],
    ["","",button(11),"",button(1),"",""],
    ["",button(10),"","","",button(2),""],
    [button(9), "", "", button("x"), "", "", button(3)],
    ["",button(8),"","","",button(4),""],
    ["","",button(7),"",button(5),"",""],
    ["","","",button(6),"","",""]
  ]

  return (
    <Page title="MatchDetails">
      <Toast />

      <Container>
        {isSubmitting && (
          <CircularProgress
            size={24}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
            }}
          />
        )}
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Stack direction="row" alignItems="center" mb={1}>
            <Typography variant="h4" gutterBottom>
              Match Details
            </Typography>
            {/* <Button variant="outlined" size="small" component={RouterLink}  to="#" startIcon={<Iconify icon="akar-icons:edit" />}>
              Edit Details
            </Button> */}
            {shotOverDashboard === true ? (
              <div>               
                <Iconify
                  icon="akar-icons:edit"
                  sx={{ '&:hover': { cursor: 'pointer', color: 'blue' } }}
                  width={32}
                  height={28}
                  onClick={() => {
                    setOpen(true);
                  }}
                />
                <Iconify
                  icon="ant-design:delete-outlined"
                  sx={{ '&:hover': { cursor: 'pointer', color: 'blue' } }}
                  width={32}
                  height={28}
                  onClick={() => {
                    setDelete(true);
                  }}
                />
              </div>
            ) : null}
          </Stack>
          
          <Iconify
            icon="akar-icons:arrow-back-thick"
            sx={{ '&:hover': { cursor: 'pointer', color: 'blue' } }}
            width={22}
            height={22}
            onClick={() => {
             {emailId !== undefined ? window.location.href = `/dashboard/match/user/${emailId}` : window.location.href = '/dashboard/match'}
            }}
          />
        </Stack>
        <Modal
          open={open}
          // onClose={handleClose}
        >
          <Box
            component="form"
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '46%',
              bgcolor: 'background.paper',
              p: 4,
            }}
            noValidate
            autoComplete="off"
          >
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Edit Details
              </Typography>
            </Stack>
            <Scrollbar style={{ height: '50vh', width: '98.5%', paddingRight: '1rem' }}>
              
              <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="left" justifyContent="space-between"   xs={12} sm={12} md={6} mt={{md:5,xs:2,sm:2}}>
                <TextField
                  id="outlined-select-course"
                  select
                  label="Course"
                  value={values.course}
                  onChange={handleChange('course')}
                  sx={{ width: { md: '25ch', xs: '100%', sm: '100%' }, mt: { md: -2, xs: -1, sm: -1 } }}
                >
                  {CourseAll.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  id="outlined-select-currency-native"
                  select
                  label="Match Type"
                  value={values.type}
                  onChange={handleChange('type')}
                  sx={{ width: { md: '25ch', xs: '100%', sm: '100%' }, mt: { md: -2, xs: 2, sm: -1 } }}
                >
                  {Type.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="outlined-select-currency-range"
                  select
                  label="# of shots"
                  value={values.range}
                  onChange={handleChange('range')}
                  inputProps={{ inputMode: 'numeric', pattern: '[0-6]*' }}
                  sx={{ width: { md: '25ch', xs: '100%', sm: '100%' }, mt: { md: -2, xs: 2, sm: -1 } }}
                >
                  {Range.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="datetime-local"
                  label="Match Date"
                  type="datetime-local"
                  value={values.matchDate}
                  InputProps = {
                    {
                      inputProps: {
                        max: `${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`
                      }
                    }
                  }
                  sx={{ width: 250 }}
                  onChange={handleChange('matchDate')}
                  sx={{ width: { md: '25ch', xs: '100%', sm: '100%' }, mt: { md: -2, xs: 2, sm: -1 } }}
                />
              </Stack>
              {match.length !== 0 && (match[0].scoreUpdateLevel === 'shot' || match[0].scoreUpdateLevel === 'livescore') ? (
                <div>
                  {_.times(parseFloat(shotvalues.length), (i) => (
                    <div>
                      <Typography id="modal-modal-title" variant="h6" component="h2">
                        Shot {i + 1}
                      </Typography>
                      <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        alignItems="left"
                        justifyContent="space-between"
                        xs={12}
                        sm={12}
                        md={12}
                        mt={1}
                      >
                        <TextField
                          required
                          id="outlined-required"
                          // label = {matchShot[i].name}
                          defaultValue={shotvalues[i].score}
                          varient="standard"
                          type="number"
                          inputProps={{ min: 0, max: 10.9, pattern: '^[1-9]d*$' }}
                          helperText="Score"
                          sx={{ m: 1, width: '25ch' }}
                          onChange={handleChangeShot(`score_${i + 1}`)}
                        />
                        <TextField
                          required
                          id="outlined-required"
                          // label = {matchShot[i].name}
                          defaultValue={shotvalues[i].direction}
                          varient="standard"
                          helperText="Direction"
                          type="text"
                          sx={{ m: 1, width: '25ch' }}
                          onChange={handleChangeShot(`direction_${i + 1}`)}
                        />
                      </Stack>
                    </div>
                  ))}
                  <Stack
                    alignItems="center"
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 1, sm: 2, md: 4 }}
                    mt={2}
                    mb={2}
                  >
                    <Button variant="outlined" onClick={handleUpdateShot}>
                      Submit
                    </Button>
                    <Button variant="outlined" color="error" onClick={handleClose}>
                      Cancel
                    </Button>
                  </Stack>
                </div>
              ) : null}
              {match.length !== 0 && match[0].scoreUpdateLevel === 'series' ? (
                <div>
                  {_.times(parseFloat(seriesValues.length), (i) => (
                    <div>
                      <Typography id="modal-modal-title" variant="h6" component="h2">
                        {seriesValues[i].name.replace('_', ' ')}
                      </Typography>
                      <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        alignItems="left"
                        justifyContent="space-between"
                        xs={12}
                        sm={12}
                        md={12}
                        mt={1}
                      >
                        <TextField
                          required
                          id="outlined-required"
                          defaultValue={seriesValues[i].overAllScore}
                          varient="standard"
                          type="number"
                          helperText="Over All Score"
                          onChange={handleChangeSeries(`overAllScore_${seriesValues[i].name.split('_')[1]}`)}
                        />
                        <TextField
                          required
                          id="outlined-required"
                          defaultValue={seriesValues[i].noOf10=== undefined ? 0 :seriesValues[i].noOf10}
                          varient="standard"
                          type="number"
                          helperText="# of 10"
                          onChange={handleChangeSeries(`noOf10_${seriesValues[i].name.split('_')[1]}`)}
                        />
                        <TextField
                          required
                          id="outlined-required"
                          defaultValue={seriesValues[i].overAllScoreDecimal}
                          varient="standard"
                          type="number"
                          helperText="Over All Score Decimal"
                          onChange={handleChangeSeries(`overAllScoreDecimal_${seriesValues[i].name.split('_')[1]}`)}
                        />
                        <TextField
                          required
                          id="outlined-required"
                          defaultValue={seriesValues[i].noOf10x}
                          varient="standard"
                          type="number"
                          helperText="# of 10x"
                          onChange={handleChangeSeries(`noOf10x_${seriesValues[i].name.split('_')[1]}`)}
                        />
                        {/* <TextField
                          required
                          id="outlined-required"
                          defaultValue={seriesValues[i].percentage}
                          varient="standard"
                          type="number"
                          helperText="Percentage"
                          onChange={handleChangeSeries(`percentage_${seriesValues[i].name.split('_')[1]}`)}
                        /> */}
                      </Stack>
                    </div>
                  ))}
                  <Stack
                    alignItems="center"
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 1, sm: 2, md: 4 }}
                    mt={2}
                    mb={2}
                  >
                    <Button variant="outlined" onClick={handleUpdateSeries}>
                      Submit
                    </Button>
                    <Button variant="outlined" color="error" onClick={handleClose}>
                      Cancel
                    </Button>
                  </Stack>
                </div>
              ) : null}
              {(match.length !== 0 && match[0].scoreUpdateLevel === 'match') ||
              (match.length !== 0 && match[0].scoreUpdateLevel === undefined) ? (
                <div>
                  <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    alignItems="left"
                    justifyContent="space-between"
                    xs={12}
                    sm={12}
                    md={6}
                    mt={2}
                  >
                    <TextField
                      required
                      id="outlined-required"
                      label="Over All Score"
                      defaultValue={matchvalues[0].overAllScore}
                      varient="standard"
                      type="number"
                      helperText="Enter over all score"
                      onChange={handleChangeMatch('overAllScore')}
                    />
                    <TextField
                      required
                      id="outlined-required"
                      label="# of 10"
                      defaultValue={matchvalues[0].noOf10===undefined?0:matchvalues[0].noOf10}
                      varient="standard"
                      type="number"
                      helperText="Enter # of 10"
                      onChange={handleChangeMatch('noOf10')}
                    />
                    <TextField
                      required
                      id="outlined-required"
                      label="Over All Score Decimal"
                      defaultValue={matchvalues[0].overAllScoreDecimal}
                      varient="standard"
                      type="number"
                      helperText="Enter over all score decimal"
                      onChange={handleChangeMatch('overAllScoreDecimal')}
                    />                 
                    <TextField
                      required
                      id="outlined-required"
                      label="# of 10x"
                      defaultValue={matchvalues[0].noOf10x}
                      varient="standard"
                      type="number"
                      helperText="Enter # of 10x"
                      onChange={handleChangeMatch('noOf10x')}
                    />
                    {/* <TextField
                      required
                      id="outlined-required"
                      label="Percentage"
                      defaultValue={matchvalues[0].percentage}
                      varient="standard"
                      type="number"
                      helperText="Enter percentage"
                      onChange={handleChangeMatch('percentage')}
                    /> */}
                  </Stack>
                  <Stack
                    alignItems="center"
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 1, sm: 2, md: 4 }}
                    mt={2}
                    mb={2}
                  >
                    <Button variant="outlined" onClick={matchUpdate}>
                      Submit
                    </Button>
                    <Button variant="outlined" color="error" onClick={handleClose}>
                      Cancel
                    </Button>
                  </Stack>
                </div>
              ) : null}
            </Scrollbar>
          </Box>
        </Modal>
        <Modal
          open={remove}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            component="form"
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '46%',
              bgcolor: 'background.paper',
              // border: '2px solid #000',
              // boxShadow: 24,
              p: 4,
            }}
            noValidate
            autoComplete="off"
          >
            {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}> */}
            {/* <Typography id="modal-modal-title" variant="h6" component="h2">
                Delete Match
              </Typography> */}
            <Typography variant="h5" gutterBottom component="div">
              Do you need to delete the match?
            </Typography>
            <Stack
              alignItems="center"
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
              mt={2}
              mb={2}
            >
              <Button variant="outlined" onClick={matchDelete}>
                Yes
              </Button>
              <Button variant="outlined" color="error" onClick={handleClose}>
                No
              </Button>
            </Stack>
            {/* </Stack> */}
          </Box>
        </Modal>
        {shotOverDashboard === true ? (
          <MatchDashboard
            courseDetails={course}
            matchDetails={match}
            shotDetails={matchShot}
            series={series}
            seriesDataFormated={seriesDataFormated}
            directionCount={directionCount}
            academyDetails={academyDetails}
          />
        ) :
        <Grid container spacing={2} mb={2} mt={-5}>
          <Grid item item xs={6} sm={3} md={3} sx={{height:{md:"20vh",xs:'13vh',sm:'20vh'}}}>
             {match.length >0?<AppWidgetSummary sx={{paddingTop:{xs:"1%",sm:"55%",md:"3%"}}} score="" title={`${matchShot.length}/${match[0].noOfSeries*10} shots`} total= {`${liveData.sum}` + `(${liveData.sumDecimal})`} icon={'ant-design:android-filled'} />:null}
          </Grid>  
          <Grid item xs={6} sm={3} md={3}  sx={{height:{md:"20vh",xs:'13vh',sm:"20vh"}}}>
              <AppWidgetSummary sx={{paddingTop:{xs:"1%",sm:"3%",md:"3%"}}} title="All 8s" score="" total= {liveData.avg8} icon={'ant-design:windows-filled'} />
          </Grid> 
          <Grid item xs={6} sm={3} md={3} sx={{height:{md:"20vh",xs:'13vh',sm:"20vh"}}}>
              <AppWidgetSummary sx={{paddingTop:{xs:"1%",sm:"3%",md:"3%"}}} title="All 9s" score="" total= {liveData.avg9} icon={'ant-design:bug-filled'} />
          </Grid> 
          <Grid item xs={6} sm={3} md={3} sx={{height:{md:"20vh",xs:'13vh',sm:"20vh"}}}>
              <AppWidgetSummary sx={{paddingTop:{xs:"1%",sm:"3%",md:"3%"}}} title="Max" score="" total= {liveData.avg10} icon={'ant-design:android-filled'} />
          </Grid> 
        </Grid>
        }
        {shotOverDashboard === false && isSubmitting === false ? (
          <div style={{marginTop:'0%'}}>
            <Typography variant="h6" gutterBottom>
              {seriesDetail.displayName === 'series 0.5' ? <p>series 1</p> : seriesDetail.displayName}
            </Typography>
            <Card>
              <Grid container spacing={2} style={{ margin: '1%' }}>
                <Grid item xs={12} sm={12} md={6}>
                  <div>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }}>
                      <Button
                        variant="contained"
                        aria-label="outlined primary button group"
                        size="small"
                        onClick={() => {
                          handleChangeButton(1);
                        }}
                      >
                        1
                      </Button>
                      <Button
                        variant="contained"
                        aria-label="outlined primary button group"
                        size="small"
                        onClick={() => {
                          handleChangeButton(2);
                        }}
                      >
                        2
                      </Button>
                      <Button
                        variant="contained"
                        aria-label="outlined primary button group"
                        size="small"
                        onClick={() => {
                          handleChangeButton(3);
                        }}
                      >
                        3
                      </Button>
                      <Button
                        variant="contained"
                        aria-label="outlined primary button group"
                        size="small"
                        onClick={() => {
                          handleChangeButton(4);
                        }}
                      >
                        4
                      </Button>
                      <Button
                        variant="contained"
                        aria-label="outlined primary button group"
                        size="small"
                        onClick={() => {
                          handleChangeButton(5);
                        }}
                      >
                        5
                      </Button>
                    </Stack>
                  </div>
                  <div style={{ marginTop: '2%' }}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }}>
                      <Button
                        variant="contained"
                        aria-label="outlined primary button group"
                        size="small"
                        onClick={() => {
                          handleChangeButton(6);
                        }}
                      >
                        6
                      </Button>
                      <Button
                        variant="contained"
                        aria-label="outlined primary button group"
                        size="small"
                        onClick={() => {
                          handleChangeButton(7);
                        }}
                      >
                        7
                      </Button>
                      <Button
                        variant="contained"
                        aria-label="outlined primary button group"
                        size="small"
                        onClick={() => {
                          handleChangeButton(8);
                        }}
                      >
                        8
                      </Button>
                      <Button
                        variant="contained"
                        aria-label="outlined primary button group"
                        size="small"
                        onClick={() => {
                          handleChangeButton(9);
                        }}
                      >
                        9
                      </Button>
                      <Button
                        variant="contained"
                        disabled={disable10Button}
                        aria-label="outlined primary button group"
                        size="small"
                        onClick={() => {
                          handleChangeButton(10);
                        }}
                      >
                        10
                      </Button>
                    </Stack>
                  </div>
                  <div>
                    <Grid container style={{ marginTop: '4%' }}>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography variant="h6" gutterBottom>Select shot direction</Typography>
                          <Table  size="small" aria-label="a dense table" sx={{maxWidth:{md:"84%",sm:"56%",xs:"50%"}}}>
                            <TableBody>
                              {rows.map((row) => (
                                <TableRow>
                                  <TableCell align = "right">{row[0]} </TableCell>
                                  <TableCell align="right">{row[1]}</TableCell>
                                  <TableCell align="right">{row[2]}</TableCell>
                                  <TableCell align="right">{row[3]}</TableCell>
                                  <TableCell align="right">{row[4]}</TableCell>
                                  <TableCell align="right">{row[5]}</TableCell>
                                  <TableCell align="right">{row[6]}</TableCell>
                                </TableRow>
                              ))} 
                            </TableBody>
                          </Table>
                        {/* <div style={{ marginTop: '2%' }}>
                          <Stack direction={{ xs: 'row', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }}>
                            <Button
                              variant="contained"
                              aria-label="outlined primary button group"
                              size="small"
                              onClick={() => {
                                handleChangeDirection('A');
                              }}
                            >
                              A
                            </Button>
                            <Divider orientation="vertical" flexItem />
                            <Button
                              variant="contained"
                              aria-label="outlined primary button group"
                              size="small"
                              onClick={() => {
                                handleChangeDirection('B');
                              }}
                            >
                              B
                            </Button>
                          </Stack>
                        </div>
                        <Divider variant="middle" style={{ marginTop: '2%', width: '80%' }} />
                        <div style={{ marginTop: '2%' }}>
                          <Stack direction={{ xs: 'row', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }}>
                            <Button
                              variant="contained"
                              aria-label="outlined primary button group"
                              size="small"
                              onClick={() => {
                                handleChangeDirection('C');
                              }}
                            >
                              C
                            </Button>
                            <Divider orientation="vertical" flexItem />
                            <Button
                              variant="contained"
                              aria-label="outlined primary button group"
                              size="small"
                              onClick={() => {
                                handleChangeDirection('D');
                              }}
                            >
                              D
                            </Button>
                          </Stack>
                        </div> */}
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <div style={{ marginTop: '2%', width: '84%' }}>
                          <Stack direction={{ xs: 'row', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }}>
                            <TextField
                              disabled
                              id="outlined-basic"
                              label="Score"
                              variant="outlined"
                              size="small"
                              value={score.slice(0, sliceNumber)}
                            />
                            <TextField
                              disabled
                              id="outlined-basic"
                              label="Direction"
                              variant="outlined"
                              size="small"
                              value={`${directionPoint} (${direction})`}
                            />
                            {/* <Button variant="contained" aria-label="outlined primary button group" size="small" onClick={()=>{handleChangeButton('.')}}>.</Button> */}
                          </Stack>
                          <Button
                            style={{ width: '50%', marginTop: '2%' }}
                            variant="outlined"
                            aria-label="outlined primary button group"
                            size="small"
                            onClick={() => {
                              handleChangeSave();
                            }}
                          >
                            Save
                          </Button>
                          <Button
                            style={{ width: '50%', marginTop: '2%' }}
                            variant="outlined"
                            aria-label="outlined primary button group"
                            size="small"
                            onClick={() => {
                              setScore('');
                              setDirection('');
                              setDisable10Button(false);
                            }}
                          >
                            Clear
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid xs={12} sm={12} md={6} mt={{xs:3,sm:3,md:0}}>
                  <Scrollbar>
                    <TableContainer component={Paper}>
                      <Table stickyHeader sx={{ minWidth: 350 }} size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">Series</TableCell>
                            <TableCell align="left">Shot</TableCell>
                            <TableCell align="left">Score</TableCell>
                            <TableCell align="left">Direction</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {matchShot.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                            return (
                              <TableRow tabIndex={-1}>
                                <TableCell align="left">
                                  {' '}
                                  {series.map((item) => {
                                    return item._id === row.seriesId ? item.name==='series_0.5'?<p>series 1</p>:item.displayName : null;
                                  })}{' '}
                                </TableCell>
                                <TableCell align="left">{row.displayName}</TableCell>
                                <TableCell align="left">{row.score}</TableCell>
                                <TableCell align="left">{row.direction}</TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      colSpan={3}
                      // component="div"
                      count={matchShot.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Scrollbar>
                </Grid>
              </Grid>
            </Card>
          </div>
        ) : null}
      </Container>
    </Page>
  );
};
export default MatchDetails;
