import * as React from 'react';
import axios from "axios";
import toast from "react-hot-toast";
import Scrollbar from "react-scrollbar";
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
// material
import {
  Stack,
  Button,
  Typography,MenuItem,
  Container, Modal, Box, TextField, Tabs, Tab,Table,TableRow,TableBody,TableCell
} from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import Toast from "../components/Toast";
import UserRequest from "./AcademyUserRequest";
import AcademyInfo from "./AcademyDetails";
import { EditorState, convertToRaw, convertFromHTML,ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import Markdown from 'markdown-to-jsx';
import "./style.css"
import {userDetails} from '../constants'
import { UserListToolbar } from 'src/sections/@dashboard/user';
import CircularProgress from '@mui/material/CircularProgress';
import {countryPincode} from '../CountryPincode'
import { replace } from 'lodash';

const AcademyConfig = () => {

  const [user, setUser] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [add, setAdd] = React.useState(false);  
  const [edit, setEdit] = React.useState(false);
  const [isSubmitting, setisSubmitting] = React.useState(false);
  const [deleteData, setDeleteData] = React.useState(false);
  const [academyDetails, setAcademyDetails] = React.useState({});
  const [academyLogo, setAcademyLogo] = React.useState({logo:`/static/ishoot.svg`});  
  const [imageList, setImageList] = React.useState(false);
  const [announcement, setAnnouncement] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState([]);
  const [valueTab, setValueTab] = React.useState(0);
  const [value, setValue] = React.useState({
    imageUrl:"",
    announcement:""
  });
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorStateAnnounce, setEditorStateAnnounce] = useState(EditorState.createEmpty());
  const [selectedFile, setselectedFile] = useState("");
  const [logo, setlogo] = useState(false);

  useEffect(() => {
    getUser();
  }, []);

    const getUser = () => {
    axios
      .post(`/api/user/getParticularUserByEmail`, {
        "emailID": userDetails.email
      })
      .then((response) => {
        const userData = response.data.data;
        getDetails(userData[0].academyId)
        setUser(userData);
      })
      .catch((error) => {
        console.log(error)
        toast.error(error);
      });
  }

  const getDetails = (id) => {
    axios
      .post(`/api/academy/getParticularAcademy`, {
        "_id": id
      })
      .then((response) => {
        const details = response.data.data;
        
        setAcademyDetails(details[0]);
        imageCheck(details[0]._id)
        if (details[0].about !== undefined) {
          setEditorState(EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(details[0].about)
            )
          ));
        }
        if (details[0].announcement !== undefined && details[0].announcement.length > 0 ) {
          setEditorStateAnnounce(EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(details[0].announcement[0].note)
            )
          ));
        }
      })
      .catch((error) => {
        console.log(error)
        toast.error(error);
      });
  }

const imageCheck =(id)=>{
    axios.get(`/logoImage/${id}.png`, {responseType: 'blob'})
    .then(res => {
        var imageUrl = URL.createObjectURL(res.data);
        setAcademyLogo({...academyLogo,logo:imageUrl})
    }).
    catch(e=>{
      console.log("error ")
      setAcademyLogo({logo:`/static/ishoot.svg`})
    })  
  }

  const handleChange = (props) => (event) => {
    // console.log(props,event.target.value)
    if(props === "stdCode"){
      let code = academyDetails.contact.split(" ")[1];
      let codeVal = `+${event.target.value} ${code}`
      setAcademyDetails({
        ...academyDetails,
        "contact": codeVal
      });
    }
    else if(props === "contact"){
      let code = academyDetails.contact.split(" ")[0];
      let codeVal = `+${code} ${event.target.value}`
      setAcademyDetails({
        ...academyDetails,
        [props]: codeVal
      });
    }
    else{
      setAcademyDetails({
        ...academyDetails,
        [props]: event.target.value
      });
    }
  };  
 const handleChangeInput = (props) => (event) => {
   setValue({
     ...value,
     [props]: event.target.value
   });
 };
const handleChangeUpdate = (props) => (event) => {
  if(props === 'imageUrl'){
    setSelectedItem({...selectedItem,imagePath:event.target.value})
  }
  else if (props === 'announcement'){
    setSelectedItem({...selectedItem,note:event.target.value})
  }
};
 const updateDetails = () =>{
   academyDetails.about = draftToHtml(convertToRaw(editorState.getCurrentContent()));
   academyDetails.announcement = [{
      "note": draftToHtml(convertToRaw(editorStateAnnounce.getCurrentContent()))
    }]
    axios
      .put(`/api/academy/update`, academyDetails)
      .then((response) => {
        setOpen(false);
        setAdd(false);
        setAnnouncement(false);
        setEditorState(EditorState.createEmpty())
        setEditorStateAnnounce(EditorState.createEmpty())
        getDetails(user[0].academyId)
      })
      .catch((error) => {
        console.log(error)
        toast.error(error);
      });
  }
  
  const addData = (data) => {
    let obj = {}
    if(data === "image"){
     obj ={"image": [{
      "imagePath": value.imageUrl
    }]}
  }
  else{
    obj = {"announcement": [{
      // "note": value.announcement
      "note": draftToHtml(convertToRaw(editorStateAnnounce.getCurrentContent()))
    }]}
  }
    axios
      .post(`/api/academy/addObjects`, {
        value: obj, academyId: academyDetails._id
      })
      .then((response) => {
        setAdd(false);
        setImageList(false);
        setAnnouncement(false);
        setValue({...value,imageUrl:"",announcement:""})
        setEditorStateAnnounce(EditorState.createEmpty())
        getDetails(user[0].academyId)
      })
      .catch((error) => {
        console.log(error)
        toast.error(error);
      });
  }

  const updateData = (data) => {
    let obj = {}
    if(data === "image"){
     obj ={'image.$.imagePath': selectedItem.imagePath}
    }
    else{
      obj = {'announcement.$.note': draftToHtml(convertToRaw(editorStateAnnounce.getCurrentContent()))}
    }
    axios
      .post(`/api/academy/updateObjects`, {
        id: selectedItem._id,
        object:data,
        value: obj
      })
      .then((response) => {
        setEdit(false);
        setImageList(false);
        setAnnouncement(false);
        setValue({...value,imageUrl:"",announcement:""})
        setEditorStateAnnounce(EditorState.createEmpty())
        setSelectedItem({})
        getDetails(user[0].academyId)
      })
      .catch((error) => {
        console.log(error)
        toast.error(error);
      });
  }

  const handleDelete = (data) => {
    let dataValue = {object: data, id: selectedItem._id}
    axios
      .post(`/api/academy/deleteObject`, dataValue)
      .then((response) => {
       setDeleteData(false);
        setImageList(false);
        setAnnouncement(false);
        setSelectedItem({})
        setEditorStateAnnounce(EditorState.createEmpty())
        getDetails(user[0].academyId)
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response.data);
      });
  }
  const onEditorStateChange = (editorState) => {
    // console.log(editorState)
    setEditorState(editorState);
  }

  const onEditorAnnounceChange = (editorState) => {
    // console.log(editorState)
    setEditorStateAnnounce(editorState);
  }

  const onChangeHandlerForZip = (e) => {
    try {
      let extension = e.target.files[0].name.split(".")[1];
      if (extension.toLowerCase() !== "png") {
        toast.error("Please upload the image in .png format");
      } else if (e.target.files[0].size >= 1 * 1024 * 1024) {
        toast.error("Image size should be less than 1 MB");
      }
      else {
        setselectedFile(e.target.files[0]);
      }
    } catch {
      console.log("No File selected");
    }
  };

  const handleUpload = () => {
    setisSubmitting(true);
    const data1 = new FormData();
    data1.append("id", user[0].academyId);
    data1.append("file", selectedFile);
    // console.log(user[0].academyId)
    axios
      .post(`/api/academy/uploadFile`, data1, {})
      .then((res) => {
        if (res.status === 200) {
          setlogo(false);
          setisSubmitting(false)
          setselectedFile('')
          toast.success(res.data.message);
        } else {
          toast.error("Error in uploading the image");
        }
      })
      .catch((e) => {
        // console.log(e.response);
        toast.error(e.response.data.message);
      });
  };
  const removeFile = () => {
    setisSubmitting(true);
    axios
      .post(`/api/academy/removeFile`, {id:user[0].academyId})
      .then((res) => {
          setisSubmitting(false)
          toast.success(res.data.message);
      })
      .catch((e) => {
        // console.log(e.response);
        toast.error(e.response.data.message);
      });
  };

  function TabPanel(props) {
      const { children, value, index, ...other } = props;
      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`vertical-tabpanel-${index}`}
          aria-labelledby={`vertical-tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box sx={{ p: 3 }}>
              {children}
            </Box>
          )}
        </div>
      );
    }

    TabPanel.propTypes = {
      children: PropTypes.node,
      index: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
      return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
      };
    }

    const handleChangeTab = (event, newValue) => {
      setValueTab(newValue);
    };

  const input = () =>{      
      return (
      <Stack direction={{ xs: 'column', sm: 'column' }} alignItems="left" justifyContent="space-between"  xs={12} sm={12} md={6} mt={2}>
        <TextField
          id = "filled-flexible"
          defaultValue = {academyDetails.displayName}
          value={academyDetails.displayName}
          varient="standard"
          label="Academy Display Name"
          type = 'text'
          sx={{mt:2}}
          onChange={handleChange('displayName')}
          />
          <TextField
          id = "filled-flexible"
          defaultValue = {academyDetails.emailID}
          value={academyDetails.emailID}
          varient="standard"
          label="Email ID"
          type = 'text'
          sx={{mt:2}}
          onChange={handleChange('emailID')}
          />
          
          <Stack direction="row">
            <TextField
              id = "outlined-select-course"
              select
              label = "code"
              value={academyDetails.contact.split(" ")[0].replace("+","")}
              onChange={handleChange('stdCode')}
              sx={{mt:2,width:{md:'10%',xs:'30%',sm:'15%'}}}
            >
              {
              countryPincode.map((option) => (
              <MenuItem key={option.iso2} value={option.code}>
                  {option.iso2}
              </MenuItem>
              ))}
            </TextField>
            <TextField
            required            
            id = "filled-flexible"
            defaultValue = {academyDetails.contact.split(" ")[1]}
            value={academyDetails.contact.split(" ")[1]}
            varient="standard"
            label="Contact"
            type = 'number'
            sx={{mt:2,ml:2,width:{md:'87%',xs:'79%',sm:'82%'}}}
            onChange={handleChange('contact')}
            />
          </Stack>
          {/* <TextField
          id = "filled-flexible"
          defaultValue = {academyDetails.contact}
          value={academyDetails.contact}
          varient="standard"
          label="Contact"
          type = 'text'
          sx={{mt:2}}
          onChange={handleChange('contact')}
          /> */}
          <TextField
          id = "filled-flexible"
          defaultValue = {academyDetails.url}
          value={academyDetails.url}
          varient="standard"
          label="Wiki link"
          type = 'text'
          sx={{mt:2}}
          onChange={handleChange('url')}
          />
          <TextField
          id = "filled-flexible"
          defaultValue = {academyDetails.totalStudent}
          value={academyDetails.totalStudent}
          varient="standard"
          label="Total Student"
          type = 'number'
          sx={{mt:2}}
          onChange={handleChange('totalStudent')}
          />
          {/* <TextField
          id = "filled-flexible"
          defaultValue = {academyDetails.about}
          multiline
          maxRows={4}
          value={academyDetails.about}
          varient="standard"
          label="About"
          type = 'text'
          sx={{mt:2}}
          onChange={handleChange('about')}
          /> */}
          <Typography varient="body1" mt={1}>About</Typography>
          <Editor 
              editorState={editorState}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              onEditorStateChange={onEditorStateChange}
          />
          <TextField
          id = "filled-flexible"
          defaultValue = {academyDetails.owner}
          value={academyDetails.owner}
          varient="standard"
          label="Owner Name"
          type = 'text'
          sx={{mt:2}}
          onChange={handleChange('owner')}
          />          
          <TextField
          id = "filled-flexible"
          defaultValue = {academyDetails.code}
          value={academyDetails.code}
          varient="standard"
          label="Pin Code"
          type = 'number'
          sx={{mt:2}}
          onChange={handleChange('code')}
          />
          <TextField
          id = "filled-flexible"
          defaultValue = {academyDetails.state}
          value={academyDetails.state}
          varient="standard"
          label="State"
          type = 'text'
          sx={{mt:2}}
          onChange={handleChange('state')}
          />
          <TextField
          id = "filled-flexible"
          defaultValue = {academyDetails.location}
          value={academyDetails.location}
          varient="standard"
          label="Address"
          type = 'text'
          sx={{mt:2}}
          onChange={handleChange('location')}
          />
      </Stack>
      )}

    const display =()=>{
      return(
        <Stack direction="column" alignItems="left" justifyContent="space-between" mb={2}>  
        <Table>
          <TableBody>
            <TableRow tabIndex={-1}>
              <TableCell align="left"><Typography variant="h6" gutterBottom>Name</Typography></TableCell>
              <TableCell align="left">{academyDetails.name}</TableCell>
            </TableRow>
            <TableRow tabIndex={-1}>
              <TableCell align="left"><Typography variant="h6" gutterBottom>Display Name </Typography></TableCell>
              <TableCell align="left">{academyDetails.displayName}</TableCell>
            </TableRow>
            <TableRow tabIndex={-1}>
              <TableCell align="left"><Typography variant="h6" gutterBottom>Total students</Typography></TableCell>
              <TableCell align="left">{academyDetails.totalStudent}</TableCell>
            </TableRow>
            <TableRow tabIndex={-1}>
              <TableCell align="left"><Typography variant="h6" gutterBottom>Owner </Typography></TableCell>
              <TableCell align="left">{academyDetails.owner}</TableCell>
            </TableRow>      
            <TableRow tabIndex={-1}>
              <TableCell align="left"><Typography variant="h6" gutterBottom>Email Id </Typography></TableCell>
              <TableCell align="left">{academyDetails.emailID}</TableCell>
            </TableRow>
            <TableRow tabIndex={-1}>
              <TableCell align="left"><Typography variant="h6" gutterBottom>contact </Typography></TableCell>
              <TableCell align="left">{academyDetails.contact}</TableCell>
            </TableRow>      
            <TableRow tabIndex={-1}>
              <TableCell align="left"><Typography variant="h6" gutterBottom>Wiki link</Typography></TableCell>
              <TableCell align="left">{academyDetails.url}</TableCell>
            </TableRow>    
            <TableRow tabIndex={-1}>
              <TableCell align="left"><Typography variant="h6" gutterBottom>Location</Typography></TableCell>
              <TableCell align="left">{academyDetails.location}{" "} {academyDetails.state}{" "}{academyDetails.code}</TableCell>
            </TableRow> 
            <TableRow tabIndex={-1}>
              <TableCell align="left"><Typography variant="h6" gutterBottom>About</Typography></TableCell>
              <TableCell align="left"><Markdown >{academyDetails.about === undefined ?"":academyDetails.about}</Markdown></TableCell>
            </TableRow>
          </TableBody>
        </Table>  
          <Typography variant="h6" gutterBottom mt={2}>Image Url's 
            <Iconify icon="eva:plus-fill" sx={{ "&:hover": { cursor: "pointer",color:"blue" } }} ml={1} 
                onClick={()=>{setAdd(true);setImageList(true)}}                            
              /></Typography>
            <Typography variant="body1"> 
            <Stack direction="column" >
            {academyDetails.image !==undefined && academyDetails.image.length >0 ?
                academyDetails.image.map((item,index)=>{
                  return <Stack direction="row"><p style={{marginLeft:'1%',width:"92%",lineBreak:"anywhere"}}>{index+1}. {item.imagePath}</p>
                          <Iconify icon="fa:edit" sx={{ "&:hover": { cursor: "pointer",color:"blue" } }} ml={1} 
                            onClick={()=>{setEdit(true);setImageList(true);setSelectedItem(item)}}                            
                          /><Iconify icon="ant-design:delete-outlined" sx={{ "&:hover": { cursor: "pointer",color:"blue" } }} ml={1} 
                            onClick={()=>{setDeleteData(true);setImageList(true);setSelectedItem(item)}}                            
                          />
                        </Stack>
                }) : <p style={{marginLeft:'1%'}}>No Data</p>}      
                
          </Stack>    
          </Typography>
          <Stack direction="row"><Typography variant="h6" gutterBottom mt={2}>Announcement 
          <Iconify icon="eva:plus-fill" sx={{ "&:hover": { cursor: "pointer",color:"blue" } }} ml={1} 
                onClick={()=>{setAdd(true);setAnnouncement(true)}}    />
              {academyDetails.announcement !==undefined && academyDetails.announcement.length >0 ?
                <span><Iconify icon="fa:edit" sx={{ "&:hover": { cursor: "pointer",color:"blue" } }} ml={1} 
                          onClick={()=>{setEdit(true);setAnnouncement(true);setSelectedItem(academyDetails.announcement[0])}}  />
                    <Iconify icon="ant-design:delete-outlined" sx={{ "&:hover": { cursor: "pointer",color:"blue" } }} ml={1} 
                          onClick={()=>{setDeleteData(true);setAnnouncement(true);setSelectedItem(academyDetails.announcement[0])}}                            
                        /></span>
                :null}</Typography></Stack>
          <Typography variant="body1"> 
            {academyDetails.announcement !==undefined && academyDetails.announcement.length >0 ?
            <Markdown style={{marginLeft:"1%"}}>{academyDetails.announcement[0].note}</Markdown>
                 : <p style={{marginLeft:'1%'}}>No Data</p>}</Typography>
          <Stack direction="row"><Typography variant="h6" gutterBottom mt={2}>Customize Logo
            <Iconify icon="eva:plus-fill" sx={{ "&:hover": { cursor: "pointer",color:"blue" } }} ml={1} 
                onClick={()=>{setlogo(true)}} />
            <Iconify icon="ant-design:delete-outlined" sx={{ "&:hover": { cursor: "pointer",color:"blue" } }} ml={1} 
              onClick={()=>{removeFile()}}/>
          </Typography></Stack>
          <Typography variant="body1">
            <img src={academyLogo.logo} alt="Intellishoot" style={{height:"20%",width:'20%'}} />
          </Typography>
        </Stack>
      )}

  return (
    <Page title="AcademyConfig">
      < Toast />
      <Container>
       <Typography mt={-2} variant="h6" gutterBottom>Academy Config</Typography> 
        <Stack >
        <Stack direction="row" alignItems="flex-start" justifyContent="space-between" spacing={{md:22,sm:7,xs:5}} >
          <Tabs
            variant="scrollable"
            value={valueTab}
            onChange={handleChangeTab}
            aria-label="basic tabs example"
            // sx={{ borderRight: 1, borderColor: 'divider',width:{xs:250,sm:100,md:100} }}
          >
            <Tab wrapped label="Academy Details" {...a11yProps(0)} />
            <Tab wrapped label="Shooter request" {...a11yProps(1)} />
            <Tab wrapped label="Academy Info" {...a11yProps(2)} />
          </Tabs> 
          </Stack>
           <TabPanel value={valueTab} index={0}>
            {/* <Scrollbar style={{ height: "60vh", paddingRight: "4rem" }}>  */}
             {open === true ?null: <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>        
              <Typography variant="h4" gutterBottom>
               Details
              </Typography>  
              <Button variant="contained" onClick={() => {setOpen(true)}} to="#" startIcon={<Iconify icon="fa:edit" />}>
                Edit Academy Details
              </Button>        
            </Stack> }            
              {open === true ?
                  <Stack alignItems="left" direction={{ xs: 'column', sm: 'column' }} spacing={{ xs: 1, sm: 1, md: 1 }}>
                      <Typography id="modal-modal-title" variant="h6" component="h2">
                        Update Academy Details
                      </Typography>
                    {input()}
                    <Stack alignItems="left" direction={{ xs: 'column', sm: 'column',md:'row' }} spacing={{ xs: 1, sm: 1, md: 1 }}>
                      <Button variant="outlined" onClick={updateDetails}>update</Button>
                      <Button variant="outlined" color="error" onClick={() => {setOpen(false); }}> Cancel</Button>
                    </Stack>
                  </Stack>
              :display()}
            {/* </Scrollbar> */}
            </TabPanel>
            <TabPanel value={valueTab} index={1}>
              <UserRequest user={user} academyDetails={academyDetails}/>
            </TabPanel>
            <TabPanel value={valueTab} index={2}>
              <AcademyInfo />
            </TabPanel>
        </Stack>
        <Modal
          open={add}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            component="form"
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '80%',
              bgcolor: 'background.paper',
              p: 4,
            }}
            noValidate
            autoComplete="off"
          >
          <p>Add {imageList === true ? "Image":"Announcement"} </p>              
         {announcement === true ? 
         < Editor
              editorState={editorStateAnnounce}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              onEditorStateChange={onEditorAnnounceChange}
          />:
          <TextField
            id = "filled-flexible"
            defaultValue = {value.imageUrl}
            value={value.imageUrl}
            varient="standard"
            label="Image Url"
            type = 'text'
            sx={{mt:2,width:'100%'}}
            onChange={handleChangeInput('imageUrl')}
          /> }
          <Stack direction="row" mt={2}>
          <Button variant = "outlined" onClick = {() => {announcement === true? updateDetails():addData("image")}} >
            save
          </Button>
          <Button variant = "outlined" color = "error" onClick = {() => {setAdd(false);setImageList(false);setAnnouncement(false)}} >
            close
          </Button>
          </Stack>
        </Box>
        </Modal>
        <Modal
          open={edit}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            component="form"
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '80%',
              bgcolor: 'background.paper',
              p: 4,
            }}
            noValidate
            autoComplete="off"
          >
          <p>Update {imageList === true ? "Image":"Announcement"} </p>
          {imageList === true ?
          <TextField
            id = "filled-flexible"
            defaultValue = {selectedItem.imagePath}
            value={selectedItem.imagePath}
            varient="standard"
            label="Image Url"
            type = 'text'
            sx={{mt:2,width:'100%'}}
            onChange={handleChangeUpdate('imageUrl')}
          />   :
          < Editor
              editorState={editorStateAnnounce}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              onEditorStateChange={onEditorAnnounceChange}
          />}  
          <Stack direction="row" mt={2}>
          <Button variant = "outlined" onClick = {() => {announcement === true? updateData("announcement"):updateData("image")}} >
            save
          </Button>
          <Button variant = "outlined" color = "error" onClick = {() => {setEdit(false);setImageList(false);setAnnouncement(false);setSelectedItem({})}} >
            close
          </Button>
          </Stack>
        </Box>
        </Modal>
        <Modal
          open={deleteData}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            component="form"
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '80%',
              bgcolor: 'background.paper',
              p: 4,
            }}
            noValidate
            autoComplete="off"
          >
            <Typography variant="h5" gutterBottom component="div">
              Do you need to delete?
            </Typography>
            <Stack
              alignItems="center"
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
              mt={2}
              mb={2}
            >
              <Button variant="outlined" onClick= {() => {announcement === true? handleDelete("announcement"):handleDelete("image")}}>
                Yes
              </Button>
              <Button variant = "outlined" color = "error" onClick = {() => {setDeleteData(false);setImageList(false);setAnnouncement(false);setSelectedItem({})}} >
                No
              </Button>
            </Stack>
          </Box>
        </Modal>
        <Modal
          open={logo}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >         
        <Box
          component="form"
          sx = {{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  bgcolor: 'background.paper',
                  boxShadow: 24,
                  p: 4,
              }}
          noValidate
          autoComplete="off"
        >
          <Typography id="modal-modal-title" variant="h6" component="h2" mb={2}>
              Upload new logo
          </Typography>
              <input
                accept = "image/png"
                style={{ display: 'none' }}
                id="raised-button-file"
                onChange={onChangeHandlerForZip}
                type="file"
                name="logo"
              />
                <label htmlFor="raised-button-file">                              
                <Button variant="contained" component="span" mb={2} mt={2} startIcon={<Iconify icon="entypo:upload" />}>
                    Upload
                </Button>
                <Typography variant="body1" mt={1} >
                  {selectedFile ? selectedFile.name : <>select logo</>}
                </Typography>
                </label> 
              <Stack alignItems="center" direction={{ xs: 'column', sm: 'row' }} mt={2} >
                <Button variant="outlined" onClick={handleUpload}>Submit</Button>
                <Button variant="outlined" color="error" onClick={()=>{setlogo(false);setselectedFile("")}}>Cancel</Button>
              </Stack>  
              </Box>
            </Modal>            
         {isSubmitting && (
          <CircularProgress
          size={24}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
          }}
          />
        )}  
      </Container>
    </Page>
  );
}
export default AcademyConfig;