import * as React from 'react';
import axios from "axios";
import toast from "react-hot-toast";
import { useState, useEffect } from 'react';
// material
import {
  Stack,
  Button,
  Typography,
  Container,Modal,Box
} from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import Toast from "../components/Toast";
import "./style.css"
import {userDetails} from '../constants'

const AssetManagement = () => {

  const [user, setUser] = React.useState([]);

  useEffect(() => {
    getUser();
  }, []);

  const getUser = () => {
     axios
       .post(`/api/user/getParticularUserByEmail`, {
         "emailID":userDetails.email
       })
       .then((response) => {
         const userData = response.data.data;
         setUser(userData);
         setUserId(userData[0]._id)
         getNotes(userData[0]._id);
       })
       .catch((error) => {
         console.log(error)
         toast.error(error.response.data);
       });
  }

  return (
    <Page title="AssetManagement">
      < Toast / >
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
        
          <Typography variant="h4" gutterBottom>
            Asset Management
          </Typography>         
        </Stack> 

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2} mt={5}>
        <Typography variant="h5" gutterBottom component="div">
            Please contact support@intellishoot.com to enable this Module!!
          </Typography>             
        </Stack>
       
      </Container>
    </Page>
  );
}
export default AssetManagement;