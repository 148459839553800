import * as React from 'react';
import { useState, useEffect } from 'react';
import axios from "axios";
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import "./style.css";
import { Card, Stack,Typography,Box,Button,Grid,CardContent,CardHeader,Avatar,
  TableContainer, Table, Paper, TableHead, TableRow, TableCell, TableBody, TablePagination,
} from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import {url} from '../constants'

export default function Home() {
  const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
  const [activeStep, setActiveStep] = React.useState(0);

  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  const shooterImage = ["/static/homePage/match.png", "/static/homePage/seriesdata.png", "/static/homePage/shotdata.png", "/static/homePage/goodbadshot.png", "/static/homePage/direction.png"]
  const academyImage = ["/static/homePage/academy1.png", "/static/homePage/academy2.png", "/static/homePage/academy3.png", "/static/homePage/academy4.png", "/static/homePage/booking1.png", "/static/homePage/booking2.png"]

  const [competition, setCompetition] = React.useState([]);

  useEffect(() => {
    getCompetitions();
  }, []);

  const getCompetitions = () => {
    axios
      .get(`/api/competition/getAll`)
      .then((response) => {
        // console.log(response.data.data)
        let matchData = response.data.data
        sortFunction(matchData)
        setCompetition(matchData);
      })
      .catch((error) => {
        // toast.error(error.response.data);
      });
  }

  const sortFunction = (data) => {
    data.sort(function (a, b) {
      return new Date(b.competitionFrom) - new Date(a.competitionFrom);
    })
  }

  const activeComp = () => {
    
    return (
      <Card>
        {/* <CardHeader title="Active Competition" /> */}
        {competition.length>0 ?
          <div>
            
          <TableContainer component={Paper}  sx={{}}>
                    <Table stickyHeader sx={{ minWidth: 350 }} size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">Name</TableCell>
                          {/* <TableCell align="left">Created By</TableCell> */}
                          <TableCell align="left">Competition From</TableCell>
                          <TableCell align="left">Competition To</TableCell>
                          <TableCell align="left">Registration Starts</TableCell>
                          <TableCell align="left">Registration Ends</TableCell>
                          <TableCell align="left">Triple Fees Date</TableCell>
                          <TableCell align="left">Level</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {competition.length>0 && competition
                        // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                          return (
                          row.status === 'active' && (new Date() <= new Date(row.competitionTo))? 
                          <TableRow tabIndex={-1} key={row._id} sx={{ "&:hover": { cursor: "pointer" } }}
                            onClick={() => {window.open(`/public/competition/${row._id}`, '_blank')}} >
                            <TableCell  align="left">{row.name}</TableCell>
                            <TableCell align="left">{row.competitionFrom != undefined ?row.competitionFrom.split("T")[0]: row.competitionOn.split("T")[0]}</TableCell>
                            <TableCell align="left">{row.competitionTo != undefined ?row.competitionTo.split("T")[0]: "-"}</TableCell>
                            <TableCell align="left">{row.registrationStarts != undefined ?row.registrationStarts.split("T")[0]: "-"}</TableCell>
                            <TableCell align="left">{row.registrationEnds != undefined ?row.registrationEnds.split("T")[0]: "-"}</TableCell>
                            <TableCell align="left">{row.cccRegistration != undefined ?row.cccRegistration.split("T")[0]: "-"}</TableCell>
                            <TableCell align="left">{row.level}</TableCell>
                          </TableRow>
                          :null
                          )
                        })
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {/* <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={7}
                    count={competition.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}                      
                  /> */}
        </div> :null}
      </Card>
    )}

  return (
    <Page title="Home" style={{backgroundColor:"white"}}>
      {/* <Card> */}
        <Grid container spacing={2} style={{paddingTop:"1%"}}>
          <Grid item xs={3} md={2} sm={3}>
            <Stack direction="row" justifyContent="center">
             <Box component="img" src={`/static/ishoot.svg`} alt="Intellishoot" sx={{ width: 100, height: 100 }}  />
             </Stack>
          </Grid>
          <Grid item xs={9} md={4} sm={9} >
            <Stack direction="column" alignItem="center" justifyContent="center">
            <Typography variant="h3" gutterBottom style={{textAlign:"center"}}><div><span style={{color: "red"}}>i</span>ntelli<span style={{color: "red"}}>S</span>hoot</div></Typography>
            <Typography variant="subtitle1" gutterBottom style={{textAlign:"center"}}>Pro-level Shooter Performance Management & Analytics</Typography>
          </Stack>
          </Grid> 
          <Grid item xs={12} md={6} sm={10} mt={2} >
            <Stack direction="row" justifyContent={{md:"flex-end",xs:"center",sm:"center"}} spacing={1}>
            <Button variant="text" href="#competitions">Competitions</Button>
              <Button variant="text" onClick={()=>{window.open(`${url}/shooterslist`, '_blank')}}>Intelliverse</Button>
              <Button variant="text" href="#feature">Features</Button>
              {/* <Button variant="text"  href="#shootingevent" disabled>Shooting Event</Button> */}
              <Button variant="text" onClick={()=>{window.open(`${url}/livescore`, '_blank')}}>Live Score</Button>
              <Button variant="text" href="#about">Contact Us</Button>
              <Button variant="contained" onClick={()=>{window.open(`${url}/login`, '_blank')}}>Sign In</Button>
            </Stack>
          </Grid>
        </Grid>
        {/* </Card> */}
        <div id="" >
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={8} sm={8}>
            <Box component="img" src="/static/homepage.png" alt="" sx={{height:{md:"83%",xs:"100%",sm:"100%"},width:"100%"}} />
          </Grid>
          <Grid item xs={12} md={4} sm={4} >
            <Typography variant="body1" gutterBottom mt={{md:'5%',sm:"3%",xs:'1%'}} mb={{md:'10%',sm:"3%",xs:'1%'}} ml={{md:"0%",sm:"0%",xs:"2%"}}>
              Intellishoot is a shooter performance Analytics platform designed to bring the power of data analytics and digitization to the professional shooting community.
            </Typography>
            <Typography variant="body1" gutterBottom mt={{md:'5%',sm:"3%",xs:'1%'}} mb={{md:'10%',sm:"3%",xs:'1%'}} ml={{md:"0%",sm:"0%",xs:"2%"}}>
              Our vision is to empower the shooters and coaches with scientific, data driven insights to improve and enable high performance Shooting
            </Typography>
             <Button variant="contained" size="large" style={{width:"90%",marginLeft:"4%"}} onClick={()=>{window.open('https://intellishoot.com/login', '_blank')}}>Explore</Button>
          </Grid>
        </Grid>
        </div>
        <div id="feature" >
          <Stack direction="row" justifyContent="center" mb={{md:3,xs:0,sm:0}} mt={{md:-5,sm:5,xs:5}}>
            <Typography variant="h3" gutterBottom color="#2065D1">Product Features</Typography>
          </Stack>
          <Stack direction={{md:"row",sm:"column",xs:"column"}} ml={{md:2,sm:1,xs:1}} justifyContent="space-evenly" alignItems={{xs:"center",sm:"center",md:"flex-start"}}>
          <Card sx={{ width: {md:345,xs:360,sm:500},height:420,marginTop:{md:0,xs:2,sm:2} }}>
            <CardHeader style={{backgroundColor:"#00c1a3",color:"white",paddingBottom:"5%",textAlign:"center"}} title="Shooters" subheader=""/>
              <CardContent>
                <Typography variant="body2" color="text.secondary">                  
                <Typography variant="h6" color="text.secondary"  style={{marginBottom:'3%'}}>Free </Typography>
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                      <Grid item><Iconify icon = "mdi:tick-outline" sx={{width:'100%',color:'green'}} /></Grid>
                      <Grid item><Typography variant="body1" gutterBottom >Match Score Tracking</Typography></Grid>
                    </Grid>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                      <Grid item><Iconify icon = "mdi:tick-outline" sx={{width:'100%',color:'green'}} /></Grid>
                      <Grid item><Typography variant="body1" gutterBottom >Online Diary</Typography></Grid>
                    </Grid>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                      <Grid item><Iconify icon = "mdi:tick-outline" sx={{width:'100%',color:'green'}} /></Grid>
                      <Grid item><Typography variant="body1" gutterBottom >Knowledge Links</Typography></Grid>
                    </Grid>
                    <Typography variant="h5" color="text.secondary" style={{color:"#00c1a3",marginBottom:'3%'}}>Premium </Typography>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                      <Grid item><Iconify icon = "mdi:tick-outline" sx={{width:'100%',color:'green'}} /></Grid>
                      <Grid item><Typography variant="body1" gutterBottom >Performance Analysis</Typography></Grid>
                    </Grid> 
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                      <Grid item><Iconify icon = "mdi:tick-outline" sx={{width:'100%',color:'green'}} /></Grid>
                      <Grid item><Typography variant="body1" gutterBottom >Achievement Tracking</Typography></Grid>
                    </Grid>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                      <Grid item><Iconify icon = "mdi:tick-outline" sx={{width:'100%',color:'green'}} /></Grid>
                      <Grid item><Typography variant="body1" gutterBottom >Score alerts to Coach</Typography></Grid>
                    </Grid>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                      <Grid item><Iconify icon = "mdi:tick-outline" sx={{width:'100%',color:'green'}} /></Grid>
                      <Grid item><Typography variant="body1" gutterBottom >Weekly Performance report</Typography></Grid>
                    </Grid>
                </Typography>
              </CardContent>
          </Card>
          <Card sx={{ width: {md:345,xs:360,sm:500},height:420,marginTop:{md:0,xs:6,sm:6} }}>
            <CardHeader style={{backgroundColor:"#226aff",color:"white",paddingBottom:"5%",textAlign:"center"}} title="Academy - Basic" />
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                      <Grid item><Iconify icon = "mdi:tick-outline" sx={{width:'100%',color:'green'}} /></Grid>
                      <Grid item><Typography variant="body1" gutterBottom >Shooter Information Module</Typography></Grid>
                    </Grid>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                      <Grid item><Iconify icon = "mdi:tick-outline" sx={{width:'100%',color:'green'}} /></Grid>
                      <Grid item><Typography variant="body1" gutterBottom >Attendance Tracking</Typography></Grid>
                    </Grid>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                      <Grid item><Iconify icon = "mdi:tick-outline" sx={{width:'100%',color:'green'}} /></Grid>
                      <Grid item><Typography variant="body1" gutterBottom >Coach review of scores</Typography></Grid>
                    </Grid>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                      <Grid item><Iconify icon = "mdi:tick-outline" sx={{width:'100%',color:'green'}} /></Grid>
                      <Grid item><Typography variant="body1" gutterBottom >Leader Board</Typography></Grid>
                    </Grid>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                      <Grid item><Iconify icon = "mdi:tick-outline" sx={{width:'100%',color:'green'}} /></Grid>
                      <Grid item><Typography variant="body1" gutterBottom >Announcement</Typography></Grid>
                    </Grid>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                      <Grid item><Iconify icon = "mdi:tick-outline" sx={{width:'100%',color:'green'}} /></Grid>
                      <Grid item><Typography variant="body1" gutterBottom >Customized Academy Page</Typography></Grid>
                    </Grid>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                      <Grid item><Iconify icon = "mdi:tick-outline" sx={{width:'100%',color:'green'}} /></Grid>
                      <Grid item><Typography variant="body1" gutterBottom >Event info Management</Typography></Grid>
                    </Grid>
                </Typography>
              </CardContent>
              <Stack direction="row" justifyContent="center" alignItems="flex-end" spacing={1}>
              <Typography variant="h6" color="text.secondary" style={{textAlign:"center"}}> @ less than <span style={{color:"#2065D1"}}>INR 3/day</span> </Typography>
              </Stack>
          </Card>
          <Card sx={{ width: {md:345,xs:360,sm:500},height:420,marginTop:{md:0,xs:6,sm:6}}}>
            <CardHeader style={{backgroundColor:"#ff6516",color:"white",paddingBottom:"5%",textAlign:"center"}} title="Academy - Premium" />
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                      <Grid item><Iconify icon = "mdi:tick-outline" sx={{width:'100%',color:'green'}} /></Grid>
                      <Grid item><Typography variant="body1" gutterBottom >Lane Booking</Typography></Grid>
                    </Grid>
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                      <Grid item><Iconify icon = "mdi:tick-outline" sx={{width:'100%',color:'green'}} /></Grid>
                      <Grid item><Typography variant="body1" gutterBottom >Weapon Booking</Typography></Grid>
                    </Grid>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                      <Grid item><Iconify icon = "mdi:tick-outline" sx={{width:'100%',color:'green'}} /></Grid>
                      <Grid item><Typography variant="body1" gutterBottom >Asset management</Typography></Grid>
                    </Grid>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                      <Grid item><Iconify icon = "mdi:tick-outline" sx={{width:'100%',color:'green'}} /></Grid>
                      <Grid item><Typography variant="body1" gutterBottom >Equipment Control</Typography></Grid>
                    </Grid>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                      {/* <Stack direction="row"  spacing={2} mt={1}> */}
                      <Grid item><Iconify icon = "mdi:tick-outline" sx={{color:'green'}}/></Grid>
                      <Grid item><Typography variant="body1" gutterBottom >Personalized Training Program</Typography></Grid>
                      {/* </Stack> */}
                    </Grid>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                      <Grid item><Iconify icon = "mdi:tick-outline" sx={{width:'100%',color:'green'}} /></Grid>
                      <Grid item><Typography variant="body1" gutterBottom >Competition Module</Typography></Grid>
                    </Grid>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                      <Grid item><Iconify icon = "mdi:tick-outline" sx={{width:'100%',color:'green'}} /></Grid>
                      <Grid item><Typography variant="body1" gutterBottom >Academy Gallery</Typography></Grid>
                    </Grid>
                </Typography>
              </CardContent>
              <Typography variant="h6" color="text.secondary" style={{textAlign:"center"}}> Contact us for <span style={{color:"#2065D1"}}>special price</span></Typography>
          </Card>
          </Stack>
        </div>
        
        <div id="competitions" >
          <Stack direction="row" justifyContent="center" mb={{md:1,xs:1,sm:1}} mt={{md:4,sm:5,xs:5}}>
            <Typography variant="h3" gutterBottom color="#2065D1">Active Competitions</Typography>
          </Stack>
          <Grid container spacing={2} >
          <Grid item xs={12} md={11} sm={11} ml={{md:7}}>
            {activeComp()}
            </Grid>
          </Grid>
        </div>

        <div id="about" >
          <Stack direction="row" justifyContent="center" mb={{md:3,xs:1,sm:1}} mt={{md:4,sm:5,xs:5}}>
            <Typography variant="h3" gutterBottom color="#2065D1">Who we are....</Typography>
          </Stack>
        <Grid container spacing={2} >
          <Grid item xs={12} md={8} sm={8}>
            <Stack direction="column" alignItems="baseline" ml={{md:5,sm:5,xs:1}}>
            <Typography variant="h6" gutterBottom style={{width:'50%'}}><Stack direction="row" justifyContent="space-equally" spacing={1} >Contact Us<Iconify icon="logos:whatsapp-icon" sx={{width:{md:"7%",sm:'14%',xs:'15%'},height:{md:"7%",sm:'14%',xs:'15%'},marginLeft:'2%'}} onClick={()=>{window.open('https://api.whatsapp.com/send?phone=919380364274&text=I%20am%20interested%20in%20intellishoot', '_blank')}} /></Stack></Typography>
            <Typography variant="h6" gutterBottom ml={{md:"2%",sm:"0%",xs:"2%"}}>You can reach us through the following</Typography>
            <Typography variant="body1" gutterBottom ml={{md:"2%",sm:"0%",xs:"2%"}}>Phone : +91 9380364274</Typography>
            <Typography variant="body1" gutterBottom ml={{md:"2%",sm:"0%",xs:"2%"}}>Email : <a href="mailto:support@intellishoot.com?subject=Intellishoot Query">support@intellishoot.com</a></Typography>
              <Typography variant="body1" gutterBottom ml={{md:"2%",sm:"0%",xs:"2%"}}>
                <Stack direction="row" justifyContent="space-between" spacing={1}>
                  Social :
                  <Stack direction="row" justifyContent="space-between" spacing={1}>
                    <div></div>
                    <Iconify icon="logos:facebook" sx={{width:"100%",height:"100%"}} />
                    <Iconify icon="logos:instagram-icon" sx={{width:"100%",height:"100%",color:"#f60085"}} />
                    <Iconify icon="logos:youtube-icon" sx={{width:"100%",height:"100%",color:"red"}} />
                  </Stack>
                </Stack>
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4} sm={4} >
            {/* <Typography variant="body1" gutterBottom>
              Intellishoot is a shooter performance Analytics solution designed to bring the power of data analytics and digitzation 
            </Typography> */}
          </Grid>
        </Grid>
        </div>
        <Stack direction="row" justifyContent="flex-end" style={{backgroundColor:"white"}} mt={1}>
          <Typography variant="caption" gutterBottom color="#2065D1">© 2022 intelliShoot. All Rights Reserved.</Typography>
        </Stack>
    </Page>
  );
}
