
import * as React from 'react';
import { useState, useEffect } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import {
    Card,
    Link,
    MenuItem,
    Container,
    Stack,
    Typography, TextField, Button
} from '@mui/material';
import axios from 'axios';
import Scrollbar from "react-scrollbar";

// components
import Page from '../components/Page';
import Logo from '../components/Logo';
import LoginLogoLayout from '../layouts/LoginLogoLayout';
import toast from "react-hot-toast";
import Toast from "../components/Toast";
import useResponsive from '../hooks/useResponsive';
import {userDetails,defaultAcademyId} from '../constants'
import {countryPincode} from '../CountryPincode'

const Course = [{
    value: "pistol",
    label: "Pistol"
}, {
    value: "rifle",
    label: "Rifle"
},
{
  value: "open_sight",
  label: "Open Sight"
}
]

const StudentLevel = [{
    value: "NR",
    label: "NR"
}, {
    value: "ISSF",
    label: "ISSF"
}]

const gender = [{
  value: "male",
  label: "Male"
}, {
  value: "female",
  label: "Female"
}, {
  value: "other",
  label: "Other"
}]
const profileVisibility = [{
  value: "public",
  label: "Public"
}, {
  value: "private",
  label: "Private"
}]

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({
    theme
}) => ({
    top: 0,
    zIndex: 9,
    lineHeight: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    padding: theme.spacing(3),
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
        alignItems: 'flex-start',
        padding: theme.spacing(7, 5, 0, 17),
    },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const SectionStyle = styled(Card)(({
    theme
}) => ({
    width: '100%',
    maxWidth: 464,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(2, 0, 2, 2),
}));

// ----------------------------------------------------------------------

export default function Profile() {
    useEffect(() => {
        getAcademy();
        getAcademyById();
    }, []);
    
    const mdUp = useResponsive('up', 'md');
    const smUp = useResponsive('up', 'sm');

    const [academy, setAcademy] = React.useState([]);
    const [values, setValues] = React.useState({
        userName: userDetails.userName,
        emailId: userDetails.email,
        displayName: '',
        courseType: '',
        studentLevel:'',
        academyName: '',
        gender: '',
        DOB: `${new Date().toISOString()}`,
        profileVisibility: 'private',
        uniqueName:"",
        contactNo:'',
        stdCode:countryPincode[countryPincode.findIndex(x => x.iso2 ==="IN")].code,
        nraiId:""

    });
    const [disable, setDisable] = React.useState({
      userName: userDetails.userName,
      emailId: userDetails.email,
    });


    const getAcademy = () => {
    axios
      .get(`/api/academy/getAll`)
      .then((response) => {
        const academyData = response.data.data;
        academyData.sort((a, b) => a.name.localeCompare(b.name))
        setAcademy(academyData);
      })

      .catch((error) => {
        console.log(error)
        toast.error(error.response.data);
      });
  }  

  const getAcademyById = () => {
    axios
      .post(`/api/academy/getParticularAcademy`, {
        _id: defaultAcademyId
      })
      .then((response) => {
        // console.log("****",response.data.data)
        setValues({
          ...values,
          ["academyName"]: response.data.data[0]._id,
        });
      })
      .catch((error) => {
        console.log(error)
        setValues({
          ...values,
          ["academyName"]: '',
        });
        toast.error(error.response.data);
      });
  }

  const handleChange =(props)=> (event) => {
    
     if (props === 'uniqueName') {
       if (Boolean(event.target.value.match(/^[A-Za-z0-9]*$/))) {
         setValues({
           ...values,
           [props]: event.target.value.toLowerCase()
         });
       } else {
         toast.error('Special Characters are not allowed in the unique name');
       }
     } else {
       setValues({
         ...values,
         [props]: event.target.value
       });
     }
  };

  const handleSubmit = () =>{
    
    let regex =/^[0-9]{9,14}$/
      if (values.userName != '' && values.emailId != '' && values.displayName != '' && values.courseType != '' && values.studentLevel != '' && values.academyName != '' && values.DOB != '' && values.profileVisibility != '' && values.uniqueName != ''&& values.contactNo != '') {
        axios
          .post(`/api/user/getParticularUserByEmail`, {
            "uniqueName": values.uniqueName.toLowerCase()
          })
          .then((response) => {
              const userData = response.data.data;
              if (userData.length > 0) {
                toast.error(`Unique Name ${values.uniqueName} already exsits. Please provide different name.`);
              } 
              else if (!regex.test(values.contactNo)){
                toast.error("Contact Number should be 9-15 digits")
              }else {
                const data = {
                  "emailID": values.emailId.toLowerCase(),
                    "userName": values.userName,
                    "displayName": values.displayName,
                    "academyId": values.academyName,
                    "courseType": values.courseType,
                    "studentLevel": values.studentLevel,
                    "gender": values.gender,
                    "DOB": values.DOB,
                    "profileVisibility": values.profileVisibility,
                    "uniqueName": values.uniqueName.toLowerCase(),
                    "points": 100,
                    "contactNo": `+${values.stdCode} ${values.contactNo}`,
                    "nraiId": values.nraiId,
                }
                let loginMethod = localStorage.getItem("loginMethod");
                let _id = localStorage.getItem("_id");
                if(loginMethod === "local") {
                  data["_id"] = _id;
                  axios
                    .put(`/api/user/update`,data)
                    .then((response) => {
                        window.location.assign(`/dashboard/app`)
                    })
                    .catch((error) => {
                      //   console.log(error)
                        toast.error(error.response.data);
                    });
                } else {
                  axios
                    .post(`/api/user/add`,data)
                    .then((response) => {
                        window.location.assign(`/dashboard/app`)
                    })      
                    .catch((error) => {
                      //   console.log(error)
                        toast.error(error.response.data);
                    });
                }
            }
        })
        .catch((error) => {
          console.log(error)
          toast.error(error.response.data);
        });
      }
      else{
        toast.error("Fill all the input fields before submitting!!");
      }
  }

  return (
    <Page title="Profile">
        <Toast />
        <RootStyle>
         <LoginLogoLayout/>
          {!smUp && (<div>
          <Typography variant="h2" gutterBottom sx={{ ml:{md:21,xs:13,sm:10},mt:{md:9,sm:5,xs:3} }}>
              <div><span style={{color: "red"}}>i</span>ntelli<span style={{color: "red"}}>S</span>hoot</div>
            </Typography>
            <Typography variant="h5" gutterBottom sx={{ ml:{md:16,xs:3,sm:10},textAlign:"center" }}>
              Pro-level Shooter Performance Management & Analytics
            </Typography></div>
            )}

        <Container>
          <ContentStyle>
           <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: { md: -2, xs: -13 } }}>
           Register User
              {/* <Link variant="subtitle2" component={RouterLink} to="/login">
                Login
              </Link> */}
            </Typography>
            <Scrollbar style={{ height: "55vh", width: "98.5%", paddingRight: "1rem" }} >
            <Stack direction={{ xs: 'column', sm: 'column' }} alignItems="left" justifyContent="space-between"  xs={12} sm={12} md={6} mt={2}>
                  
                <TextField
                  required
                  id = "outlined-required"
                  defaultValue = {values.userName}
                  value={values.userName}
                  varient="standard"
                  label="User Name"
                  type = 'text'
                  disabled= {disable.userName!==""?true:false}
                  // helperText = "Enter User Name"
                  onChange={handleChange('userName')}
                  />
                  <TextField
                  required
                  id = "outlined-required"
                  defaultValue = {values.emailId}
                  value = {values.emailId}
                  label="Email Id"
                  varient="standard"
                  disabled= {disable.emailId!==""?true:false}
                  type='text'
                  // helperText = "Enter Email Id"
                  onChange={handleChange('emailId')}
                  sx={{mt:2}}
                  />
                  <Stack direction="row">
                    <TextField
                      id = "outlined-select-course"
                      select
                      label = "code"
                      value={values.stdCode !=""?values.stdCode:countryPincode[countryPincode.findIndex(x => x.iso2 ==="IN")].code}
                      onChange={handleChange('stdCode')}
                      sx={{mt:2,width:"25%"}}
                    >
                      {
                      countryPincode.map((option) => (
                      <MenuItem key={option.iso2} value={option.code}>
                          +{option.code}
                      </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                    required
                    id = "outlined-required"
                    defaultValue = {values.contactNo}
                    value = {values.contactNo}
                    label="Contact No"
                    varient="standard"
                    type='number'
                    onChange={handleChange('contactNo')}
                    sx={{mt:2,ml:1,width:{md:'75%',xs:'79%',sm:'82%'}}}
                    />
                  </Stack>
                  <TextField
                  required
                  id = "outlined-required"
                  label = "Display Name"
                  defaultValue = {values.displayName}
                  varient="standard"
                  type='text'
                  onChange={handleChange('displayName')}
                  sx={{mt:2}}
                  />
                  <TextField
                    required
                    id = "outlined-required"
                    defaultValue = {values.uniqueName}
                    value = {values.uniqueName}
                    varient="standard"
                    type='text'
                    label="Profile Unique Name"
                    helperText = "Enter unique name without space/special characters"
                    onChange={handleChange('uniqueName')}
                    sx={{mt:2}}
                    />
                     <TextField
                      id = "outlined-required"
                      label = "National Shooter ID"
                      defaultValue = {values.nraiId}
                      varient="standard"
                      type='text'
                      onChange={handleChange('nraiId')}
                      sx={{mt:2}}
                      />
                    <TextField
                      id = "outlined-select-course"
                      select
                      label = "Profile Visibility"
                      value={values.profileVisibility}
                      onChange={handleChange('profileVisibility')}
                      sx={{mt:2}}
                    >
                      {
                      profileVisibility.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                          {option.label}
                      </MenuItem>
                      ))}
                  </TextField>
                  <TextField
                      id = "outlined-select-course"
                      select
                      label = "Gender"
                      value={values.gender}
                      onChange={handleChange('gender')}
                      // helperText="Select your Student Level"
                      sx={{mt:2}}
                    >
                      {
                      gender.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                          {option.label}
                      </MenuItem>
                      ))}
                  </TextField>
                  <TextField
                    id = "outlined-select-course"
                    select
                    label = "Academy"
                    disabled
                    value={values.academyName}
                    onChange={handleChange('academyName')}
                    // helperText="Select your Academy"
                    sx={{mt:2}}
                  >
                    {
                    academy.map((option) => (
                    <MenuItem key={option._id} value={option._id}>
                        {option.displayName}
                    </MenuItem>
                    ))}
                </TextField>
                  <TextField
                    id="date"
                    type="date"
                    value={values.DOB}
                    // sx={{ width: 250 }}
                    onChange={handleChange('DOB')}
                    helperText = "Select the DOB"
                    sx={{mt:2}}
                  />
                  <TextField
                    id = "outlined-select-course"
                    select
                    label = "Course Type"
                    value={values.courseType}
                    onChange={handleChange('courseType')}
                    // helperText="Select your Course Type"
                    sx={{mt:2}}
                  >
                    {
                    Course.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                    ))}
                </TextField>
                <TextField
                    id = "outlined-select-course"
                    select
                    label = "Student Level"
                    value={values.studentLevel}
                    onChange={handleChange('studentLevel')}
                    // helperText="Select your Student Level"
                    sx={{mt:2}}
                  >
                    {
                    StudentLevel.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                    ))}
                </TextField>
                </Stack>
                </Scrollbar>
                <Stack alignItems="center" direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }} mt={2} mb={2}>
                <Button variant="outlined" onClick={handleSubmit} >Submit</Button>
                <Button variant="outlined" onClick={()=> {window.location.href = '/login'}} color="error" >Cancel</Button>
                </Stack>

          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
