import * as React from 'react';
import { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { Button, Typography, Container, Autocomplete,Grid,TextField,Stack,
  Card, CardHeader,Box,Modal,MenuItem,
Table, TableBody, TableCell, TableContainer, Paper, TableHead, TableRow, TablePagination
} from '@mui/material';
import Scrollbar from "react-scrollbar";
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import toast from "react-hot-toast";
import Toast from "../components/Toast";
import {Type,Range,userDetails,defaultAcademy} from '../constants';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function LiveScore() {  

  useEffect(() => {
    getMatch();
    getCourse();
    getUser();
  }, []);
  
   const [values, setValues] = React.useState({
    course: '',
    type: '',
    range: '',
    matchDate: `${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`,
  });
  
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [Course, setCourse] = React.useState([]);
  const [courseDetails, setCourseDetails] = React.useState([]);
  const [user, setUser] = React.useState([{displayName:''}]);
  const [matchData, setMatchData] = React.useState([]);
  const [academyDetails, setAcademyDetails] = React.useState([]);
  const [isSubmitting, setisSubmitting] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState({});

  const getMatch = () => {
    axios
      .post(`/api/matches/getMatchesByData`,{scoreUpdateLevel:'livescore'})
      .then((response) => {
        const data = response.data.data;
        setMatchData(...matchData,data);
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response.data);
      });
  }

  const getCourse = () => {
    axios
      .get(`/api/course/getAll`)
      .then((response) => {
        const courseData = response.data.data;
        setCourseDetails(courseData);
        const value = [];
        courseData.map((item, index) => {
          const value1 = {
            value: `${item.type}-${item.range}`,
            label: `${item.displayName} ${item.range}m`,
          }
          value.push(value1)
        })
        setCourse(value);
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response.data);
      });
  }

  const getUser = () => {    
    axios
      .post(`/api/user/getParticularUserByEmail`,{"emailID":userDetails.email})
      .then((response) => {
        const userData = response.data.data;
        setUser(userData);
        getAcademy(userData)
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response.data);
      });
  }

    const getAcademy = (data) => {
      axios
        .post(`/api/academy/getParticularAcademy`, {
          "_id": data[0].academyId
        })
        .then((response) => {
          let acadata = response.data.data;
          setAcademyDetails(acadata);
        })
        .catch((error) => {
          console.log(error)
          toast.error(error.response.data);
        });
    }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = (props) => (event) => {
    if (props === 'score' && (parseFloat(event.target.value)>10.9)){
      toast.error("Score cannot be greater than 10.9");
    }else{
      let value = event.target.value;
      if(props === 'score'){value = parseFloat(event.target.value)}
      setValues({
        ...values,
        [props]: value
      });
    }
  };
  const AddMatch = () => {
    
    if(values.course === '' || values.range === "" || values.type === ''){
      toast.error("Select course, range and type to proceed");
    }else{
      setisSubmitting(true)
      setValues({...values,"noOfShot":parseFloat(values.range*10)})
      let courseId = ''
      courseDetails.map((value)=>{
        const name = `${value.type}-${value.range}`
        if (name === values.course){
          courseId = value._id; 
        }
        return courseId
      })
      const data = {
        "courseId": courseId,
        "userId": user[0]._id,
        "noOfSeries": parseFloat(values.range),
        "type": values.type,
        "matchOn": values.matchDate,
        "scoreUpdateLevel": 'livescore',
        "scoreAddedBy": 'student'
      }
      axios
        .post(`/api/matches/add`, data)
        .then((response) => {
          if (response) {
            const data1 = response.data.data
            setisSubmitting(false);
            setOpen(false);
            window.location.assign(`/dashboard/livescoreuser/${data1[0].matchId}`)
          }
        })
        .catch((error) => {
          console.log("handle save",error)
          setisSubmitting(false);
          toast.error(error);
        });
    }
  }


  return (
    <Page title="Live Score">
      <Toast />
      <Container>
        <Stack direction={{md:"row",sx:'row',xs:"column"}} alignItems="center" justifyContent="space-between" >          
          <Typography variant="h4" gutterBottom>Live Score</Typography> 
          <Button variant="outlined" color="secondary" onClick={()=>{setOpen(true)}}>New Match</Button>
        </Stack> 

        <Scrollbar style={{ height: "70vh", width: "98.5%", paddingRight: "1rem",marginTop:'2%' }}>
          <TableContainer component={Paper}>
          <Table stickyHeader sx={{ minWidth: 350 }} size="small" aria-label="a dense table">
            <TableHead>
            <TableRow>
            <TableCell align="left">Type</TableCell>
            <TableCell align="left"># of shots</TableCell>
            <TableCell align="left">Score</TableCell>
            <TableCell align="left">Score Decimal</TableCell>
            <TableCell align="left"># of 10x</TableCell>
            <TableCell align="left">Percentage</TableCell>
            <TableCell align="left">Match On</TableCell>
            <TableCell align="left">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {matchData.length >0 && matchData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item,index)=>{
              return (
                <TableRow onClick={()=>{window.location.assign(`/dashboard/livescoreuser/${item._id}`)}} sx={{'&:hover': { cursor: 'pointer', color: 'blue' } }}>
                  <TableCell align="left">{item.type.toUpperCase()}</TableCell>
                  <TableCell align="left">{item.noOfSeries * 10}</TableCell>
                  <TableCell align="left">{item.overAllScore}</TableCell>
                  <TableCell  align="left">{item.overAllScoreDecimal.toFixed(2)}</TableCell>
                  <TableCell align="left">{item.noOf10x}</TableCell>
                  <TableCell align="left">{Math.round(item.percentage)}%</TableCell>
                  <TableCell align="left">{item.matchOn.split("T")[0]}</TableCell>
                  <TableCell align="left">{item.status}</TableCell>
                  </TableRow>
              ) 
            })}
          </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5,10,20,30,40,60]}
          colSpan={3}
          count={matchData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        </Scrollbar>

        <Modal
          open={open}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          // sx={{width:{md:'50%'}}}
        >         
          <Box
            component="form"
            sx={style}
            noValidate
            autoComplete="off"
          >
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Add Match Details
              </Typography>
              </Stack>
              
              <Scrollbar style={{ height: "50vh", width: "98.5%", paddingRight: "1rem" }} >
              <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="left" justifyContent="space-between"  xs={12} sm={12} md={6} mt={{md:5,xs:2,sm:2}}>
              <TextField
                id = "outlined-select-course"
                select
                label = "Course"
                value={values.course}
                onChange={handleChange('course')}
                // helperText="Select your Course"

                sx={{width:{md:'25ch',xs:'100%',sm:'100%'},mt: { md: -2, xs: 2,sm:-1 }}}
              >
                {
                  Course.map((option) => (
                  <MenuItem key={option.value} value={option.value}
                  sx={{maxHeight:{xs:3*4.5,sm:5*4.5,md:5*4.5}}}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              
              <TextField
                id = "outlined-select-currency-native"
                select
                label = "Match Type"
                value={values.type}
                onChange={handleChange('type')}
                
                sx={{width:{md:'25ch',xs:'100%',sm:'100%'},mt: { md: -2, xs: 2,sm:-1 }}}
                // helperText="Select your Type of match"
              >
                {
                  Type.map((option) => (
                  <MenuItem key={option.value} value={option.value} 
                  sx={{maxHeight:{xs:3*4.5,sm:5*4.5,md:5*4.5}}}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id = "outlined-select-currency-range"
                select
                label = "# of shots"
                value={values.range}
                onChange={handleChange('range')}
                
                sx={{width:{md:'25ch',xs:'100%',sm:'100%'},mt: { md: -2, xs: 2,sm:-1 }}}
                // helperText="Select # of shots "
                inputProps={{ inputMode: 'numeric', pattern: '[0-6]*' }} 
              >
                {
                  Range.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>    
                        
              <TextField
                id="datetime-local"
                label="Match Date"
                type="datetime-local"
                value={values.matchDate}
                InputProps = {
                  {
                    inputProps: {
                      max: `${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`
                    }
                  }
                }
                sx={{width:{md:'25ch',xs:'100%',sm:'100%'},mt: { md: -2, xs: 2,sm:-1 }}}
                onChange={handleChange('matchDate')}
                // helperText = "Select the match date & time"
              />
              </Stack>
               <Stack alignItems="center" direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }} mt={2}>
                <Button variant="outlined" onClick={()=>{AddMatch()}} >Submit</Button>
                <Button variant="outlined" color="error" onClick={()=>{setOpen(false)}}>Cancel</Button>
              </Stack>
            </Scrollbar>
         </Box>
        </Modal>
        {isSubmitting && (
          <Modal
            open={isSubmitting}
          >         
            <Box
              component="form"
              sx = {{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '80%',
                height:'80%',
                bgcolor: 'background.paper',
              }}
              noValidate
              autoComplete="off"
            >
          <CircularProgress
            size={24}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              bgcolor: 'background.paper',
            }}
          />
          </Box>
          </Modal>
        )}
      </Container>
    </Page>
  );
}
