import { Link as RouterLink } from 'react-router-dom';
import * as React from 'react';
import { useState, useEffect } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import {
    Card,
    Link,
    MenuItem,
    Container,
    Stack,
    Typography, TextField, Button
} from '@mui/material';
import axios from 'axios';
import Scrollbar from "react-scrollbar";

// components
import Page from '../components/Page';
import Logo from '../components/Logo';
import LoginLogoLayout from '../layouts/LoginLogoLayout';
import toast from "react-hot-toast";
import Toast from "../components/Toast";
import useResponsive from '../hooks/useResponsive';
import {userDetails,defaultAcademyId} from '../constants'


const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({
    theme
}) => ({
    top: 0,
    zIndex: 9,
    lineHeight: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    padding: theme.spacing(3),
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
        alignItems: 'flex-start',
        padding: theme.spacing(7, 5, 0, 17),
    },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const SectionStyle = styled(Card)(({
    theme
}) => ({
    width: '100%',
    maxWidth: 464,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(2, 0, 2, 2),
}));

// ----------------------------------------------------------------------

export default function Profile() {
    useEffect(() => {
        
    }, []);
    
    const mdUp = useResponsive('up', 'md');
    const smUp = useResponsive('up', 'sm');

   const [values, setValues] = React.useState({
        emailId: userDetails.email,
        password: '',
        confirmPassword:'',
    });
    const [confirmation,setConfirmation]=React.useState(false)

  const handleChange =(props)=> (event) => {
    
    setValues({
      ...values,[props]: event.target.value
    });
  };

  const validate =()=>{
    if (values.password === values.confirmPassword){
      handleSubmit();
    }else{
      toast.error("New password & Confirm password should be same");
    }
  }

  const handleSubmit = () =>{
    
      if (values.emailId != '' ) {
        const data = {
          "emailID": values.emailId
        }
        axios
          .post(`/api/user/changePassword/confirm`, data)
          .then((response) => {
            // console.log(response.data)
            toast.success(response.data.message);
            setValues({...values,emailId: userDetails.email})
            if (response.data.data.confirmation === true){
              setConfirmation(true)
            }
          })
          .catch((error) => {
            // console.log(error)
              toast.error(error.response.data.message);
          });
      }
      else{
        toast.error("Fill all the input fields before submitting!!");
      }
  }

  return (
    <Page title="Change Password">
        <Toast />
        <RootStyle>
         <LoginLogoLayout/>
          {!smUp && (<div>
          <Typography variant="h2" gutterBottom sx={{ ml:{md:21,xs:13,sm:10},mt:{md:9,sm:5,xs:3} }}>
              <div><span style={{color: "red"}}>i</span>ntelli<span style={{color: "red"}}>S</span>hoot</div>
            </Typography>
            <Typography variant="h5" gutterBottom sx={{ ml:{md:16,xs:3,sm:10},textAlign:"center" }}>
              Pro-level Shooter Performance Management & Analytics
            </Typography></div>
            )}

        <Container>
          <ContentStyle>
           <Typography id="modal-modal-title" variant="h4" component="h2" sx={{ mt: { md: -2, xs: -13 } }}>
           Change Password
              {/* <Link variant="subtitle2" component={RouterLink} to="/login">
                Login
              </Link> */}
            </Typography>
            {confirmation === true ?
             <Typography variant="h4" gutterBottom mt={{xs:3,md:8,sm:3}} sx={{color:"green"}}>
                Please login to your mail id and confirm the change password. 
              </Typography>
             :<div>
            <Scrollbar style={{ height: {md:"35vh",xs:'45vh',sm:"15vh"}, width: "98.5%", paddingRight: "1rem" }} >
            <Stack direction={{ xs: 'column', sm: 'column' }} alignItems="left" justifyContent="space-between"  xs={12} sm={12} md={6} mt={2}>
             <TextField
                required
                id = "outlined-required"
                defaultValue = {values.emailId}
                value = {values.emailId}
                label="Email Id"
                varient="standard"
                type='text'
                onChange={handleChange('emailId')}
                sx={{mt:2}}
              />
            </Stack>
            </Scrollbar>
            <Stack alignItems="center" direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }} mb={2} mt={2}>
              <Button variant="outlined" onClick={validate} >Submit</Button>
              <Button variant="outlined" onClick={()=> {window.location.href = '/login'}} color="error" >Cancel</Button>
            </Stack></div>
            }

          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
