
import * as React from 'react';
import toast from "react-hot-toast";
import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useSearchParams } from "react-router-dom";
import Scrollbar from "react-scrollbar";
import _ from "lodash";
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Modal, Box, TextField, MenuItem, Paper, TableHead, CardHeader
} from '@mui/material';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
// components
import Page from '../components/Page';
import Toast from "../components/Toast";
import Iconify from '../components/Iconify';
import {Type,Range,userDetails,defaultAcademy} from '../constants';
import {
  UserDashboardTable,
} from '../sections/@dashboard/app';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: '80%',
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

 const Match =() => {
   useEffect(() => {
     getRedirectDetails();
     
   }, []);

   const [page, setPage] = useState(0);
   const { emailId } = useParams(undefined);
   const [attachmentOpen, setAttachmentOpen] = React.useState(false);
   const [selectedFile, setselectedFile] = useState([]);
   const [selectedFileName, setselectedFileName] = useState([]);
   const [userEmail, setUserEmail] = React.useState(userDetails.email);
   const [redirect, setRedirect] = React.useState(false);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [open, setOpen] = React.useState(false);   
   const [match, setMatch] = React.useState([]);
   const [academyDetails, setAcademyDetails] = React.useState([{name:""}]);
   const [Course, setCourse] = React.useState([]);
   const [courseDetails, setCourseDetails] = React.useState([]);
   const [user, setUser] = React.useState([{displayName:''}]);
   const [values, setValues] = React.useState({
     course: '',
     type: '',
     range: '',
    //  matchDate: `${new Date().toISOString().split(':')[0]}:${new Date().toISOString().split(':')[1]}`
     matchDate: `${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`
   });
   const [seriesVal, setSeriesVal] = React.useState(values.range);

   const [matchvalues, setMatchValues] = React.useState({
     overAllScore: 0,
     overAllScoreDecimal: 0,
     noOf10x: 0, percentage: 0,
     noOf10:0,
     xposition:0,yposition:0,cld:0
   });
   const [seriesvalues, setSeriesValues] = React.useState({});
   const [isSubmitting, setisSubmitting] = React.useState(false);
   const [alignment, setAlignment] = React.useState('match');
   const [tableHeader, setTableHeader] = React.useState(["60", "40", "30", "20", "10", "5"]);
   const [matchCount, setMatchCount] = React.useState([]);
   const [attachmentList, setAttachmentList] = React.useState([]);

  const handleChangeToggle = (event, newAlignment) => {
     setAlignment(newAlignment);
  };

  const getRedirectDetails = () => {
    // console.log("*********",emailId)
    if (emailId !== undefined && emailId.length >1){
      axios
        .post(`/api/user/validateUser`, {
          "coachEmailID": userEmail,
          "userEmailID": emailId
        })
        .then((response) => {
          // console.log(response.data.data)
          const userData = response.data.data;
          if (userData.length > 0 && userData[0].user.length >0){
              setUserEmail(emailId);
              setRedirect(true);
              getMatches(emailId);
              getAttachmentsList(emailId);
            	getCourse();
            	getUser(emailId);
          }else{
            toast.error('user details is not correct');
            window.location.assign(`/dashboard/leaderboard`)
          }
          setUser(userData);
        })
        .catch((error) => {
          console.log(error)
          window.location.assign(`/dashboard/leaderboard`)
          toast.error(error.response.data);
        });
    }
    else {
      getMatches(userEmail);
      getAttachmentsList(userEmail);
      getCourse();
      getUser(userEmail);
    }
  }

  const getMatches=(mail)=> {
    // console.log(mail)
    axios
      .post(`/api/matches/getParticularMatchesOfUser`, {
        emailID: mail
      })
      .then((response) => {
        const MatchData = response.data.data[0].matches;
        sortFunction(MatchData)
        setMatchCount(response.data.data[0])
        // setMatch(MatchData.reverse());
        setMatch(MatchData)
      })

      .catch((error) => {
        console.log(error)
        toast.error(error.response.data);
      });
  } 
  
  const getAttachmentsList = (mail) => {
    axios
      .post(`/api/matches/getAttachmentList`, {
        emailID: mail
      })
      .then((response) => {
        setAttachmentList([...response.data.data])
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response.data);
      });
  }

  const sortFunction = (data) => {
    data.sort(function (a, b) {
      return new Date(b.matchOn) - new Date(a.matchOn);
    })
  }

  const getCourse = () => {
    axios
      .get(`/api/course/getAll`)
      .then((response) => {
        const courseData = response.data.data;
        setCourseDetails(courseData);
        const value = [];
        courseData.map((item, index) => {
          const value1 = {
            value: `${item.type}-${item.range}`,
            label: `${item.displayName} ${item.range}m`,
          }
          value.push(value1)
        })
        setCourse(value);
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response.data);
      });
  }

  const getUser = (mail) => {
    
    axios
      .post(`/api/user/getParticularUserByEmail`,{"emailID":mail})
      .then((response) => {
        const userData = response.data.data;
        // console.log(userData)
        setUser(userData);
        getAcademy(userData)
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response.data);
      });
  }

  const getAcademy = (data) => {
    axios
      .post(`/api/academy/getParticularAcademy`, {
        "_id": data[0].academyId
      })
      .then((response) => {
        let acadata = response.data.data;
        setAcademyDetails(acadata);
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response.data);
      });
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClose = () => setOpen(false);

  const handleChange =(props)=> (event) => {
    setValues({
      ...values,[props]: event.target.value
    });
    if (props === 'range' && event.target.value === '0.5'){
      setSeriesVal(1);
    }
    else if (props === 'range' && event.target.value !== '0.5') {
      setSeriesVal(event.target.value);
    }
  };
  const handleChangeMatch = (props) => (event) => {
    // console.log(props, event.target.value, matchvalues)
    setMatchValues({
      ...matchvalues,
      [props]: event.target.value
    });
  };

  const handleChangeSeries = (props) => (event) => {
    
    setSeriesValues({
      ...seriesvalues,
      [props]: event.target.value
    });
    
  };
  const AddMatch =(data)=>{ 
    axios
        .post(`/api/matches/add`,data)
        .then((response) => {
          if(response){
            const data1 = response.data.data
            
            if (alignment === 'series') {
              handleSeries(data1)
            }else{  
              handleUpload(data1[0].matchId)
              if (alignment !== 'shot'){
                updateUserPoint(10)
                mailNotification(data);
              }  
              setTimeout(() => {
              setisSubmitting(false);
              if(redirect === true){
                window.location.assign(`/dashboard/match/${data1[0].matchId}/user/${userEmail}`)
              }
              else{
                window.location.assign(`/dashboard/match/${data1[0].matchId}`)
              }
              }, 2000)
            }
          }
        })
        .catch((error) => {
          console.log(error)
          setisSubmitting(false);
          toast.error(error.response.data);
        });
  }
  const updateSeries = (data) => {
    // console.log(`update ${data}`)
    axios
      .put(`/api/series/update`, data)
      .then((response) => {
        if (response) {          
          const data = response.data.data
          // console.log("add series ", data);   
          // toast.success("Series Added")
        }
      })
      .catch((error) => {
        console.log(error)
        setisSubmitting(false);
        toast.error(error.response.data);
      });
  }
  
const handleMatch =()=>{
  let scoreAdd = 'student'
  if(values.course === '' || values.range === "" || values.type === ''){
    toast.error("Select course, range and type to proceed");
  }else{
    let courseId = ''
    let userId = ''
    if(redirect === true){
      scoreAdd = 'coach'
    }
    courseDetails.map((value)=>{
      const name = `${value.type}-${value.range}`
      if (name === values.course){
         courseId = value._id; 
      }
       return courseId
    })
    userId = user[0]._id
    const data = {
      "courseId": courseId,
      "userId": userId,
      "noOfSeries": parseFloat(values.range),
      "type": values.type,
      "matchOn": values.matchDate,
      "scoreUpdateLevel": alignment,
      "scoreAddedBy": scoreAdd
    }
    const shotsCount = (parseFloat(values.range))*10;
    if (courseId === ""){
      toast.error("Course Id not present")
    }
    else if (userId === "") {
      toast.error("User Id not present")
    } 
    else {
      setisSubmitting(true);
      if (alignment === 'match'){
        const percent = (matchvalues.overAllScore/shotsCount)*10
        data.status = 'Completed'
        data.scoreUpdateLevel ='match'
        data.overAllScore = matchvalues.overAllScore
        data.overAllScoreDecimal = matchvalues.overAllScoreDecimal
        data.noOf10x = matchvalues.noOf10x
        data.noOf10 = matchvalues.noOf10
        // data.percentage = matchvalues.percentage
        data.percentage = percent
        data.xposition = matchvalues.xposition
        data.yposition = matchvalues.yposition
        data.cld = matchvalues.cld
        
        // console.log(data)
        AddMatch(data)
      }
      else if (alignment === 'series') {
         data.status = 'Completed'
         data.scoreUpdateLevel = 'series'
         AddMatch(data)
      }
      else{
        AddMatch(data)
      }
    }
  }
}

const handleSeries = (matchData) => {

      setisSubmitting(true); 
      axios
        .post(`/api/series/getSeriesByMatchId`, {
          'matchId': matchData[0].matchId
        })
        .then((response) => {
          
          if (response.data.data.length > 0) {
            const seriesData = response.data.data;
            let shotsCount = 0;
            if (parseFloat(values.range) === 0.5){
              shotsCount = 5
            } else {
              shotsCount = 10
            }
            for (let i = 0; i < parseFloat(seriesVal); i++) {
              const data = {}
              seriesData.map((item,index) => {  
                if (item.name === `series_${i+1}` || item.name === `series_0.5`) {
                  const percent = (seriesvalues[`series-${i+1}-overAllScore`] / shotsCount) * 10
                  data._id = item._id
                  data.matchId = matchData[0].matchId
                  data.overAllScore = seriesvalues[`series-${i+1}-overAllScore`]
                  data.overAllScoreDecimal = seriesvalues[`series-${i+1}-overAllScoreDecimal`]
                  data.noOf10x = seriesvalues[`series-${i+1}-noOf10x`]
                  data.noOf10 = seriesvalues[`series-${i+1}-noOf10`]
                  // data.percentage = seriesvalues[`series-${i+1}-percentage`]
                  data.percentage = percent
                  data.status = "Completed"    
                  data.xposition = seriesvalues[`series-${i+1}-xposition`]
                  data.yposition = seriesvalues[`series-${i+1}-yposition`]
                  data.cld = seriesvalues[`series-${i+1}-cld`]        
                  updateSeries(data)
                  if (parseFloat(seriesVal) === index+1){
                    updateUserPoint(15)
                    setTimeout(() => {
                      handleUpload(matchData[0].matchId)
                      getMatchesById(matchData[0].matchId)
                      setisSubmitting(false)
                      redirect === true?
                        window.location.assign(`/dashboard/match/${matchData[0].matchId}/user/${userEmail}`)
                      : window.location.assign(`/dashboard/match/${matchData[0].matchId}`)                      
                    }, 10000)
                  }
                }
              })
              // return data
            }
          }
        })
        .catch((error) => {
          toast.error(error.response);
        });
}

const getMatchesById = (matchId) => {
  axios
    .post(`/api/matches/getParticularMatches`, {
      _id: matchId
    })
    .then((response) => {
      
      mailNotification(response.data.data[0]);
    })
    .catch((error) => {
      // console.log(error)
      toast.error(error.response.message);
    });
};

const mailNotification = (data) =>{
  
   axios
     .post(`/api/matches/mailNotification`, {
       emailID: user[0].emailID,
       userName: user[0].userName,
       userDetail: user[0],
       matchDetails: data
     })
     .then((response) => {
      })
      .catch((error) => {
        toast.error(error.response);
      });
}

const onChangeHandlerForZip = (e) => {
  try {
    let ext = e.target.files
    let name = selectedFileName;
    let files = selectedFile;
    for (let i = 0; i < ext.length; i++) {
      if (ext[i].name.split(".")[1].toLowerCase() !== "png" && ext[i].name.split(".")[1].toLowerCase() !== "jpg" && ext[i].name.split(".")[1].toLowerCase() !== "jpeg" && ext[i].name.split(".")[1].toLowerCase() !== "pdf") {    
        toast.error("Please upload the file/image in .png/.jpeg/.jpg/.pdf format");
      }
      else if (ext[i].size >= 1 * 1024 * 1024) {
        toast.error("Files size should be less than 1 MB");
      }
      else{  
        name.push(ext[i].name)
        files.push(ext[i])
        if(i+1 === ext.length){
          setselectedFileName([...name])  
          setselectedFile([...files]);
          // setAttachmentOpen(false);
          toast.success("Files Uploaded successfully!!");
        }
      }
    }
  } catch(e) {
    console.log("No File selected",e);
  }
};

const handleUpload = (data) => {
  if (selectedFile.length>0){
    setisSubmitting(true);
    const data1 = new FormData();
    data1.append("matchId", data);
    for (const single_file of selectedFile) {
      data1.append('file', single_file)
    }
    
    axios
    .post(`/api/matches/uploadFile`, data1)
    .then((res) => {
      // console.log(res)
      if (res.status === 200) {
        setAttachmentOpen(false);
        setisSubmitting(false)
        setselectedFile('')
        toast.success(res.data);
      } else {
        toast.error("Error in uploading the file");
      }
    })
    .catch((e) => {
      setAttachmentOpen(false);
      setisSubmitting(false)
      // console.log(e.response.data);
      toast.error(e.response.data);
    });
  }else{
    // toast.error("Error in uploading the file");
  }
};

const removeAttachment = (name) =>{
  
  const indexOfObject = selectedFileName.findIndex(object => {
    return object === name;
  });
  selectedFileName.splice(indexOfObject, 1);
  setselectedFileName([...selectedFileName])
  const indexOfObject1 = selectedFile.findIndex(object1 => {
    return object1.name === name;
  });
  selectedFile.splice(indexOfObject1, 1);
  console.log(selectedFile);
  setselectedFile([...selectedFile])

}

const updateUserPoint = (point)=>{
  let data ={
    _id:user[0]._id,
    points: user[0].points+point
  }
  
  axios
    .put(`/api/user/update`, data)
    .then((response) => {
      // console.log(response.data)
      toast.success(`Thank you for adding the score. you have been credited with ${point} points for this.`);
    })
    .catch((error) => {
      //   console.log(error)
      toast.error(error.response.data);
    })
}

  return (
    <Page title="Match">      
      < Toast />
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {/* {redirect === true?<span>Matches of {user[0].displayName}</span>:<span>Matches</span>} */}
            Matches of {user[0].displayName}
          </Typography>
          <Button variant="contained" component={RouterLink} onClick={() => {setOpen(true)}} to="#" startIcon={<Iconify icon="eva:plus-fill" />}>
            New Match
          </Button>
        </Stack>
        <Modal
          open={open}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          // sx={{width:{md:'50%'}}}
        >         
          <Box
            component="form"
            sx={style}
            noValidate
            autoComplete="off"
          >
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Add Match Details
              </Typography>
              <ToggleButtonGroup
                color="primary"
                value={alignment}
                exclusive
                onChange={handleChangeToggle}
                >
                <ToggleButton value="match">Match</ToggleButton>
                <ToggleButton value="series">Series</ToggleButton>
                <ToggleButton value="shot">Shot</ToggleButton>
              </ToggleButtonGroup>
              </Stack>
              
              <Scrollbar style={{ height: "50vh", width: "98.5%", paddingRight: "1rem" }} >
              <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="left" justifyContent="space-between"  xs={12} sm={12} md={6} mt={{md:5,xs:2,sm:2}}>
              <TextField
                id = "outlined-select-course"
                select
                label = "Course"
                value={values.course}
                onChange={handleChange('course')}
                // helperText="Select your Course"

                sx={{width:{md:'25ch',xs:'100%',sm:'100%'},mt: { md: -2, xs: 2,sm:-1 }}}
              >
                {
                  Course.map((option) => (
                  <MenuItem key={option.value} value={option.value}
                  sx={{maxHeight:{xs:3*4.5,sm:5*4.5,md:5*4.5}}}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              
              <TextField
                id = "outlined-select-currency-native"
                select
                label = "Match Type"
                value={values.type}
                onChange={handleChange('type')}
                
                sx={{width:{md:'25ch',xs:'100%',sm:'100%'},mt: { md: -2, xs: 2,sm:-1 }}}
                // helperText="Select your Type of match"
              >
                {
                  Type.map((option) => (
                  <MenuItem key={option.value} value={option.value} 
                  sx={{maxHeight:{xs:3*4.5,sm:5*4.5,md:5*4.5}}}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id = "outlined-select-currency-range"
                select
                label = "# of shots"
                value={values.range}
                onChange={handleChange('range')}
                
                sx={{width:{md:'25ch',xs:'100%',sm:'100%'},mt: { md: -2, xs: 2,sm:-1 }}}
                // helperText="Select # of shots "
                inputProps={{ inputMode: 'numeric', pattern: '[0-6]*' }} 
              >
                {
                  Range.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>    
                        
              <TextField
                id="datetime-local"
                label="Match Date"
                type="datetime-local"
                value={values.matchDate}
                InputProps = {
                  {
                    inputProps: {
                      max: `${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[0]}:${(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split(':')[1]}`
                    }
                  }
                }
                sx={{width:{md:'25ch',xs:'100%',sm:'100%'},mt: { md: -2, xs: 2,sm:-1 }}}
                onChange={handleChange('matchDate')}
                // helperText = "Select the match date & time"
              />
              </Stack>
              {alignment === 'match'?<div>
              <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="left" justifyContent="space-between"  xs={12} sm={12} md={6} mt={{md:5,xs:2,sm:2}}>
                  <TextField
                  required
                  id = "outlined-required"
                  label = "Over All Score"
                  defaultValue = ""
                  varient="standard"
                  type = 'number'
                  sx={{width:{md:'40%',xs:'100%',sm:'100%'},mt: { md: -2, xs: 2,sm:-1 }}}
                  // helperText = "Enter over all score"
                  onChange={handleChangeMatch('overAllScore')}
                  />
                  <TextField
                  required
                  id = "outlined-required"
                  label = "# of 10"
                  defaultValue = ""
                  varient="standard"
                  type = 'number'
                  sx={{width:{md:'40%',xs:'100%',sm:'100%'},mt: { md: -2, xs: 2,sm:-1 }}}
                  // helperText = "Enter # of 10"
                  onChange={handleChangeMatch('noOf10')}
                  />
                  <TextField
                  id = "outlined-required"
                  label = "Over All Score Decimal"
                  defaultValue = ""
                  varient="standard"
                  type='number'
                  sx={{width:{md:'40%',xs:'100%',sm:'100%'},mt: { md: -2, xs: 2,sm:-1 }}}
                  // helperText = "Enter over all score decimal"
                  onChange={handleChangeMatch('overAllScoreDecimal')}
                  />
                  <TextField
                  required
                  id = "outlined-required"
                  label = "# of 10x"
                  defaultValue = ""
                  varient="standard"
                  type = 'number'
                  sx={{width:{md:'40%',xs:'100%',sm:'100%'},mt: { md: -2, xs: 2,sm:-1 }}}
                  // helperText = "Enter # of 10x"
                  onChange={handleChangeMatch('noOf10x')}
                  />
                  {/* <TextField
                  required
                  id = "outlined-required"
                  label = "Percentage"
                  defaultValue = ""
                  varient="standard"
                  type = 'number'
                  sx={{width:{md:'25%',xs:'100%',sm:'100%'},mt: { md: -2, xs: 2,sm:-1 }}}
                  // helperText = "Enter percentage"
                  onChange={handleChangeMatch('percentage')}
                  /> */}
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="left" justifyContent="space-between"  xs={12} sm={12} md={6} mt={{md:5,xs:2,sm:2}}>
              <TextField
                  id = "outlined-required"
                  label = "X Position"
                  defaultValue = ""
                  varient="standard"
                  type = 'number'
                  sx={{width:{md:'31%',xs:'100%',sm:'100%'},mt: {  md: -2, xs: 2,sm:-1 }}}
                  onChange={handleChangeMatch('xposition')}
                  />
                  <TextField
                  id = "outlined-required"
                  label = "Y Position"
                  defaultValue = ""
                  varient="standard"
                  type = 'number'
                  sx={{width:{md:'31%',xs:'100%',sm:'100%'},mt: {  md: -2, xs: 2,sm:-1 }}}
                  onChange={handleChangeMatch('yposition')}
                  />
                  <TextField
                  id = "outlined-required"
                  label = "CLD"
                  defaultValue = ""
                  varient="standard"
                  type = 'number'
                  sx={{width:{md:'31%',xs:'100%',sm:'100%'},mt: {  md: -2, xs: 2,sm:-1 }}}
                  onChange={handleChangeMatch('cld')}
                  />
              </Stack>
              </div>
              :null}
              {alignment === 'series'?
              <div>
              {_.times(parseFloat(seriesVal), (i) => (
                <div>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    Series {i+1}
                  </Typography>
                  <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="left" justifyContent="space-between"  xs={12} sm={12} md={6} mt={2}>
                  <TextField
                  required
                  id = "outlined-required"
                  label = "Over All Score"
                  defaultValue = ""
                  varient="standard"
                  type = 'number'
                  sx={{width:{md:'40%',xs:'100%',sm:'100%'},mt: { md: -2, xs: 2,sm:-1 }}}
                  // helperText = "Enter over all score"
                  onChange={handleChangeSeries(`series-${i+1}-overAllScore`)}
                  />
                  <TextField
                  required
                  id = "outlined-required"
                  label = "# of 10"
                  defaultValue = ""
                  varient="standard"
                  type = 'number'
                  sx={{width:{md:'40%',xs:'100%',sm:'100%'},mt: { md: -2, xs: 2,sm:-1 }}}
                  // helperText = "Enter # of 10x"
                  onChange={handleChangeSeries(`series-${i+1}-noOf10`)}
                  />
                  <TextField
                  id = "outlined-required"
                  label = "Over All Score Decimal"
                  defaultValue = ""
                  varient="standard"
                  type='number'
                  sx={{width:{md:'40%',xs:'100%',sm:'100%'},mt: { md: -2, xs: 2,sm:-1 }}}
                  // helperText = "Enter over all score decimal"
                  onChange={handleChangeSeries(`series-${i+1}-overAllScoreDecimal`)}
                  />
                  <TextField
                  required
                  id = "outlined-required"
                  label = "# of 10x"
                  defaultValue = ""
                  varient="standard"
                  type = 'number'
                  sx={{width:{md:'40%',xs:'100%',sm:'100%'},mt: { md: -2, xs: 2,sm:-1 }}}
                  // helperText = "Enter # of 10x"
                  onChange={handleChangeSeries(`series-${i+1}-noOf10x`)}
                  />
                  {/* <TextField
                  required
                  id = "outlined-required"
                  label = "Percentage"
                  defaultValue = ""
                  varient="standard"
                  type = 'number'
                  sx={{width:{md:'25%',xs:'100%',sm:'100%'},mt: { md: -2, xs: 2,sm:-1 }}}
                  // helperText = "Enter percentage"
                  onChange={handleChangeSeries(`series-${i+1}-percentage`)}
                  /> */}
              </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="left" justifyContent="space-between"  xs={12} sm={12} md={6} mt={{md:5,xs:2,sm:2}}>
              <TextField
                  id = "outlined-required"
                  label = "X Position"
                  defaultValue = ""
                  varient="standard"
                  type = 'number'
                  sx={{width:{md:'31%',xs:'100%',sm:'100%'},mt: {  md: -2, xs: 2,sm:-1 }}}
                  onChange={handleChangeSeries(`series-${i+1}-xposition`)}
                  />
                  <TextField
                  id = "outlined-required"
                  label = "Y Position"
                  defaultValue = ""
                  varient="standard"
                  type = 'number'
                  sx={{width:{md:'31%',xs:'100%',sm:'100%'},mt: {  md: -2, xs: 2,sm:-1 }}}
                  onChange={handleChangeSeries(`series-${i+1}-yposition`)}
                  />
                  <TextField
                  id = "outlined-required"
                  label = "CLD"
                  defaultValue = ""
                  varient="standard"
                  type = 'number'
                  sx={{width:{md:'31%',xs:'100%',sm:'100%'},mt: {  md: -2, xs: 2,sm:-1 }}}
                  onChange={handleChangeSeries(`series-${i+1}-cld`)}
                  />
              </Stack>
              </div>
              ))}
              </div>
              :null}
              <Stack alignItems="center" direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }} mt={2}>
              {academyDetails[0].name.toLowerCase() === defaultAcademy?null:
              <Button variant="outlined" onClick={()=>{setAttachmentOpen(true)}}>Add Match Card</Button>}
              <Button variant="outlined" onClick={handleMatch}>Submit</Button>
              <Button variant="outlined" color="error" onClick={handleClose}>Cancel</Button>
              </Stack>
              </Scrollbar>            
          </Box>
        </Modal>
        {isSubmitting && (
          <Modal
            open={isSubmitting}
          >         
            <Box
              component="form"
              sx = {{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '80%',
                height:'80%',
                bgcolor: 'background.paper',
              }}
              noValidate
              autoComplete="off"
            >
          <CircularProgress
            size={24}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              bgcolor: 'background.paper',
            }}
          />
          </Box>
          </Modal>
        )}
  
        <Scrollbar >
          <UserDashboardTable title='Match Summary' header={tableHeader} data={matchCount} />
        <Card sx={{mt:2}}>
          <CardHeader title="Match Data" />
            <div>
            <TableContainer component={Paper}  sx={{mt:2}}>
              <Table stickyHeader sx={{ minWidth: 350 }} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Type</TableCell>
                    <TableCell align="left"># of shots</TableCell>
                    <TableCell align="left">Score</TableCell>
                    <TableCell align="left">Score Decimal</TableCell>
                    <TableCell align="left"># of 10x</TableCell>
                    <TableCell align="left">Percentage</TableCell>
                    <TableCell align="left">Match On</TableCell>
                    <TableCell align="left">Score Level</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {match
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                    <TableRow tabIndex={-1} key={row._id} sx={{ "&:hover": { cursor: "pointer" } }}
                    onClick={() => {redirect === true ? window.location.assign(`/dashboard/match/${row._id}/user/${userEmail}`) :
                          window.location.assign(`/dashboard/match/${row._id}`)  }}>
                      <TableCell  align="left">
                      <Stack direction="row" spacing={1}> 
                        {row.type.toUpperCase().replace("_"," ")}   
                         <Iconify
                          icon = {row.comment !== undefined && row.comment.length>0?"fluent:comment-checkmark-28-regular":null}
                          sx={row.comment !== undefined && row.comment.length>0 ? {color: 'green'}:{color:"black"} }
                          width={32}
                          height={28}
                        />
                        <Iconify
                          icon = {attachmentList.includes(row._id)?"mdi:target":null}
                          sx={attachmentList.includes(row._id)? {color: 'green'}:{color:"black"} }
                          width={32}
                          height={28}
                        />
                        </Stack>
                      </TableCell>
                      <TableCell align="left">{row.noOfSeries * 10}</TableCell>
                      <TableCell align="left">{row.overAllScore}</TableCell>
                      <TableCell  align="left">{row.overAllScoreDecimal.toFixed(2)}</TableCell>
                      <TableCell align="left">{row.noOf10x}</TableCell>
                      <TableCell align="left">{Math.round(row.percentage)}%</TableCell>
                      <TableCell align="left">{row.matchOn.split("T")[0]}</TableCell>
                      <TableCell align="left">{row.scoreUpdateLevel?row.scoreUpdateLevel.toUpperCase():'MATCH'}</TableCell>
                    </TableRow>
                    )
                  })
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              colSpan={7}
              count={match.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}                      
            />
          </div>
        </Card>
        <Modal
          open={attachmentOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          // sx={{width:{md:'50%'}}}
          >         
          <Box
            component="form"
            sx = {{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}
            noValidate
            autoComplete="off"
          >
            <Typography id="modal-modal-title" variant="h6" component="h2" mb={2}>
                Upload the files ( supported formats png, jpeg, jpg, pdf)
            </Typography>            
              <input
                accept = "image/png, image/jpeg,image/jpg, .pdf "
                style={{ display: 'none' }}
                id="raised-button-file"
                onChange={onChangeHandlerForZip}
                type="file"
                name="file"
                multiple
              />
                <label htmlFor="raised-button-file">                              
                <Button variant="contained" component="span" mb={2} mt={2} startIcon={<Iconify icon="entypo:upload" />}>
                    Upload
                </Button>             
                </label> 
                 <Typography variant="body1" mt={1} >
                  {selectedFileName.length>0 ? 
                    selectedFileName.map((item)=>{
                      
                      return (
                        <Stack alignItems="center" direction={{ xs: 'column', sm: 'row' }}  >
                        <Typography id="modal-modal-title" variant="h6" component="h2" >
                          {item}
                        </Typography> 
                        <Iconify
                          icon = "ep:close-bold"
                          sx={{ '&:hover': { cursor: 'pointer', color: 'red' } }}
                          width={32}
                          height={28}
                          onClick={()=>{removeAttachment(item)}}
                        />
                      </Stack>
                      ) 
                    })                  
                  : <>Choose files to upload</>}
                </Typography>
              <Stack alignItems="center" direction={{ xs: 'column', sm: 'row' }} mt={2} >
                {/* <Button variant="outlined" onClick={handleUpload}>Submit</Button> */}
                <Button variant="outlined" color="error" onClick={()=>{setAttachmentOpen(false)}} >Close</Button>
              </Stack>  
          </Box>
          </Modal>
        </Scrollbar>
      </Container>
    </Page>
  );
}
export default Match;
