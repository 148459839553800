import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast from "react-hot-toast";
import Toast from "../../../components/Toast";
// material
import { Stack, TextField, IconButton, InputAdornment,Typography,Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

export default function RegisterForm(props) {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [register, setRegister] = useState(false);

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('First name required'),
    lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
    },
    validationSchema: RegisterSchema,
    onSubmit: (values, { setSubmitting }) => {
      try {
        registerUser(values, setSubmitting);
      } catch (err) {
        console.error(err);
      }
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  const registerUser = (values, setSubmitting) =>{
    console.log("in register user")
    if (values.firstName != '' && values.lastName != '' && values.email != '' && values.password != '' ) {
      const data = {
        "emailID": values.email.toLowerCase(),
        "userName": values.firstName + " " + values.lastName,
        "loginMethod": "local",
        "password": values.password
      }
      
      axios
            .post(`/api/user/add/local`,data)
            .then((response) => {
              // console.log(response );
              if (response.data.message.toLowerCase() !== "emailid already exists"){
                // window.location.assign(`/login`)
                setSubmitting(false);
                toast.success("Please login to your Gmail and confirm the registeration");
                props.notifyUser(true);
                formik.resetForm()
                setRegister(true)
              }
              else {
                setSubmitting(false);
                toast.error(response.data.message);
              }
            })

            .catch((error) => {
                console.log(error)
                toast.error(error.response.data.message);
                setSubmitting(false)
            });
    }
    else{
      toast.error("Fill all the input fields before submitting!!");
      setSubmitting(false)
    }
}

  return (
    <FormikProvider value={formik}>
    <Toast />
    {register === true ?
      <div>
        <Typography variant="h4" sx={{color:"green"}} >Please login to your mail account and confirm the registration</Typography>
         <Button variant="outlined" onClick={()=> {window.location.assign(`/login`)}}>Login</Button>
      </div>
    :
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="First name"
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />

            <TextField
              fullWidth
              label="Last name"
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Stack>

          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />

          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
            Register
          </LoadingButton>
        </Stack>
      </Form>
      }
    </FormikProvider>
  );
}
