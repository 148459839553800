
import { styled } from '@mui/material/styles';
import { useState, useEffect } from 'react';
import * as React from 'react';
import { Card, Link, Container, Typography,Modal,Box,Stack,TextField,Button,MenuItem } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
import Logo from '../components/Logo';
import LoginLogoLayout from '../layouts/LoginLogoLayout';
// sections
import AuthSocial from '../sections/auth/AuthSocial';
import { LoginForm } from '../sections/auth/login';

import Iconify from '../components/Iconify';
import axios from "axios";
import toast from "react-hot-toast";
import Scrollbar from "react-scrollbar";
import {countryPincode} from '../CountryPincode'
import CircularProgress from '@mui/material/CircularProgress';

import { Link as RouterLink } from 'react-router-dom';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 17),
  },
}));

const SectionStyle = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 484,
  display: 'flex',
  maxHeight:'90vh',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  maxHeight: '90vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(4, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');
  const [academyAddModel, setAcademyAddModel] = useState(false);
  const [isSubmitting, setisSubmitting] = React.useState(false);
  const [values, setValues] = React.useState({
    name: '',
    displayName: '',
    about: '',
    url: '',
    owner:'',
    code: '',
    emailID: '',
    location: '',
    state: '',
    totalStudent:'',
    contact:'',
    stdCode:countryPincode[countryPincode.findIndex(x => x.iso2 ==="IN")].code,
});

  const handleChange =(props)=> (event) => {
    setValues({
      ...values,
      [props]: event.target.value
    });
};

  const handleAddAcademy = () =>{
    if(values.name != '' || values.displayName != '' || values.about != '' || values.url != '' || values.owner !='' || values.code != '' || values.emailID != '' || values.location != '' || values.state != '' || values.totalStudent !='' || values.contact !='' ){
      values.contact = `${values.stdCode} ${values.contact}`
      // console.log(values)
      setisSubmitting(true)
      axios
      .post(`/api/academy/add`, values)
      .then((response) => {
        setisSubmitting(false);
        setValues({
          name: '',
          displayName: '',
          about: '',
          url: '',
          owner:'',
          code: '',
          emailID: '',
          location: '',
          state: '',
          totalStudent:'',
          contact:'',
          stdCode:countryPincode[countryPincode.findIndex(x => x.iso2 ==="IN")].code,
      });
      setAcademyAddModel(false)
        toast.success(`Your registration is success. Your academy added successfully`);
      })
      .catch((error) => {
        setisSubmitting(false)
        // console.log(error)
        toast.error(error.response.data.message)
      });
    }
    else{
      toast.success(`Please enter all the fields`);
    }
  }

  return (
    <Page title="Login">
      <RootStyle>
         {/* <HeaderStyle>
         {mdUp && ( <Logo user={undefined} disabledLink={true}/>)}

          
          {smUp && (
            <Typography variant="body2" sx={{ mt: { md: -2 } }}>
              Don’t have an account? {''}
              <Link variant="subtitle2" component={RouterLink} to="/register/user">
                Get started
              </Link>
            </Typography>
          )}
        </HeaderStyle> */}

        <LoginLogoLayout/>

        <Container maxWidth="sm">
          <ContentStyle>
            <Typography variant="h2" gutterBottom sx={{ ml:{md:21,xs:10,sm:10},mt:{md:9,sm:5,xs:3} }}>
              <div><span style={{color: "red"}}>i</span>ntelli<span style={{color: "red"}}>S</span>hoot</div>
            </Typography>
            {!smUp && (
            <Typography variant="h5" gutterBottom sx={{ ml:{md:16,xs:3,sm:10},textAlign:"center" }}>
              Pro-level Shooter Performance Management & Analytics
            </Typography>
            )}
            <Typography gutterBottom sx={{ ml:{md:16,xs:3,sm:10} }}>
            <LoginForm />
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                Don’t have an account?{' '}
                <Link variant="subtitle2" component={RouterLink} to="/register/user">
                  Get started
                </Link>
              </Typography>
            <AuthSocial  msg="Sign in"/>
            </Typography>
            <Typography gutterBottom sx={{ ml:{md:16,xs:3,sm:10} }}>
            <Typography variant="body2" align="center" sx={{ mt: 3 }} onClick={() =>{setAcademyAddModel(true)}}>
               Add your Academy{' '} <span style={{color: "blue",cursor:"pointer"}}> Click Here </span>
            </Typography></Typography>
          </ContentStyle> 
          <Modal
          open={academyAddModel}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            component="form"
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '80%',
              bgcolor: 'background.paper',
              p: 4,
            }}
            noValidate
            autoComplete="off"
          >
            <Typography id="modal-modal-title" variant="h6" component="h2" mb={2} mt={2}>
              Add Academy
            </Typography>
            <Scrollbar style={{ height: "55vh", width: "98.5%", paddingRight: "1rem" }} >
            <Stack direction={{ xs: 'column', sm: 'column' }} alignItems="left" justifyContent="space-between"  xs={12} sm={12} md={6} mt={2}>
            <TextField
              required
              id = "outlined-required"
              defaultValue = {values.name}
              value={values.name}
              varient="standard"
              label="Academy Name"
              type = 'text'
              onChange={handleChange('name')}
              />
              <TextField
              sx={{mt:2}}
              required
              id = "outlined-required"
              defaultValue = {values.displayName}
              value={values.displayName}
              varient="standard"
              label="Academy Display Name"
              type = 'text'
              onChange={handleChange('displayName')}
              />
              <TextField
              sx={{mt:2}}
              required
              id = "outlined-required"
              defaultValue = {values.about}
              value = {values.about}
              label="About Academy"
              varient="standard"
              type='text'
              onChange={handleChange('about')}
              />
              <TextField
              sx={{mt:2}}
              required
              id = "outlined-required"
              defaultValue = {values.url}
              value={values.url}
              varient="standard"
              label="Academy reference link"
              type = 'text'
              onChange={handleChange('url')}
              />
              <TextField
              sx={{mt:2}}
              required
              id = "outlined-required"
              defaultValue = {values.owner}
              value={values.owner}
              varient="standard"
              label="Academy Owner"
              type = 'text'
              onChange={handleChange('owner')}
              />
              <TextField
              sx={{mt:2}}
              required
              id = "outlined-required"
              defaultValue = {values.code}
              value={values.code}
              varient="standard"
              label="Academy Location Pincode"
              type = 'text'
              onChange={handleChange('code')}
              />
              <TextField
              sx={{mt:2}}
              required
              id = "outlined-required"
              defaultValue = {values.emailID}
              value={values.emailID}
              varient="standard"
              label="Academy emailID"
              type = 'text'
              onChange={handleChange('emailID')}
              />
              <TextField
              sx={{mt:2}}
              required
              id = "outlined-required"
              defaultValue = {values.location}
              value={values.location}
              varient="standard"
              label="Academy Location"
              type = 'text'
              onChange={handleChange('location')}
              />
              <TextField
              sx={{mt:2}}
              required
              id = "outlined-required"
              defaultValue = {values.state}
              value={values.state}
              varient="standard"
              label="Academy State"
              type = 'text'
              onChange={handleChange('state')}
              />
              <TextField
              sx={{mt:2}}
              required
              id = "outlined-required"
              defaultValue = {values.totalStudent}
              value={values.totalStudent}
              varient="standard"
              label="Total No of Students in academy"
              type = 'number'
              onChange={handleChange('totalStudent')}
              />
              <Stack direction="row">
                <TextField
                  id = "outlined-select-course"
                  select
                  label = "code"
                  value={values.stdCode !=""?values.stdCode:countryPincode[countryPincode.findIndex(x => x.iso2 ==="IN")].code}
                  onChange={handleChange('stdCode')}
                  sx={{mt:2,width:"25%"}}
                >
                {
                countryPincode.map((option) => (
                <MenuItem key={option.iso2} value={option.code}>
                    +{option.code}
                </MenuItem>
                ))}
              </TextField>
              <TextField
              required
              id = "outlined-required"
              defaultValue = {values.contact}
              value = {values.contact}
              label="Contact No"
              varient="standard"
              type='number'
              onChange={handleChange('contact')}
              sx={{mt:2,ml:1,width:{md:'75%',xs:'79%',sm:'82%'}}}
              />
            </Stack>
            </Stack>
            </Scrollbar>
            <Stack
              alignItems="center"
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
              mt={2}
              mb={2}
            >
              <Button variant="outlined" onClick={handleAddAcademy}>
                Submit
              </Button>
              <Button variant = "outlined" color = "error" onClick = {() => {setAcademyAddModel(false);setValues({name: '',stdCode:countryPincode[countryPincode.findIndex(x => x.iso2 ==="IN")].code,
                    displayName: '',  about: '',  url: '',  owner:'',  code: '',  emailID: '',  location: '',  state: '',  totalStudent:'',contact:''})}} >
                Cancel
              </Button>
            </Stack>
          </Box>
        </Modal>
        {isSubmitting && (
          <CircularProgress
          size={24}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
          }}
          />
        )}
        </Container>
      </RootStyle>
    </Page>
  );
}
