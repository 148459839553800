// @mui
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
import {
    Card,
    Box,CardHeader
} from '@mui/material';

MatchAreaChart.propTypes = {
    Details: PropTypes.array,
    seriesDataFormated: PropTypes.array
};

export default function MatchAreaChart({
    Details,
    seriesDataFormated
    
}) {
    // console.log(Details)
    const series = seriesDataFormated
    const options = {
        chart: {
            height: 350,
            type: 'heatmap',
        },
        plotOptions: {
            heatmap: {
                shadeIntensity: 0.5,
                radius: 0,
                enableShades: false,
                useFillColorAsStroke: true,
                colorScale: {
                    ranges: [{
                            from: 1,
                            to: 7.9,
                            name: '1-7',
                            color: '#f31919'
                        },
                        {
                            from: 8,
                            to: 8.9,
                            name: '8',
                            color: '#ebde46'
                        },
                        {
                            from: 9,
                            to: 9.9,
                            name: '9',
                            color: '#50f919'
                        }, {
                            from: 10,
                            to: 10.9,
                            name: '10',
                            color: '#3e9b1f'
                        }
                    ]
                }
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: 1
        },
        xaxis: {
            type: 'string',
            categories: Details.length === 5 ?[1,2,3,4,5] :[1,2,3,4,5,6,7,8,9,10]
        },
    }
    return (
        <Card>
            <CardHeader title="Good & Bad shot Details" />
            <Box sx={{ p: 3, pb: 1 }} dir="ltr">
            <ReactApexChart options={options} series={series} type="heatmap" height={350} />
            </Box>
        </Card>
    );
}
