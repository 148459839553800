import * as React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import toast from 'react-hot-toast';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Typography } from '@mui/material';
// components
import Iconify from '../../components/Iconify';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import {useEffect} from 'react';
import { userDetails } from '../../constants';
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const [user, setUser] = React.useState([]);
  const [state, setState] = React.useState(false);
  useEffect(() => {
    if(!localStorage.getItem('email')) {
      window.location.assign(`/login`)
    }else{
      if(state === false){
        getUser()
        setState(true)
      }
    }
  })
  const getUser = () => {
    let mail = userDetails.email;
    axios
      .post(`/api/user/userAcademyDetails`, {
        emailID: mail
      })
      .then((response) => {
        const userData = response.data.data;
        setUser(userData);
        // setState(false)
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data);
      });
  };
  return (
    <RootStyle mb={{md:0,xs:5,sm:0}}>
      <ToolbarStyle>
        <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary', display: { lg: 'none' } }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        {/* <Searchbar /> */}
        <Stack direction="column">
        {user.length>0 ?
          <Stack direction="row" spacing={{ xs: 0.5, sm: 0.5,md:0.5 }}>
          <Typography variant="h3" color="black" sx={{fontSize:{xs:"0.8rem",sm:"1.0rem",md:'1.3rem'}}}>
            {user[0].academy[0].url!==undefined?
            <a href= {user[0].academy[0].url} target="_blank" style={{textDecoration: "none",color: "inherit"}}><span  style={{cursor:"pointer"}}>{user[0].academy[0].displayName}</span></a>:
            <span>{user[0].academy[0].displayName}</span>}
          </Typography> 
          </Stack>
        :
        <Stack direction="row">
          <Typography variant="h3" color="red">
            i
          </Typography>
          <Typography variant="h3" color="black">
           ntelli
          </Typography>
          <Typography variant="h3" color="red">
            S
          </Typography>
          <Typography variant="h3" color="black">
           hoot
          </Typography>
          </Stack>
          }
      <Typography variant="caption" color="red">
      {/* <marquee> */}
        {user.length > 0 && Object.keys(user[0]).includes("contactNo")? (user[0].contactNo == undefined || user[0].contactNo == "")?<Typography variant="caption" gutterBottom>"Update your contact number in your profile to get the notification in telegram"</Typography>:null:<Typography variant="caption" gutterBottom>"Update your contact number in your profile to get the notification in telegram"</Typography>}
      {/* </marquee> */}
      </Typography>
      </Stack>
        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 0.5,md:0.5 }}>
          <AccountPopover />
        </Stack>
      </ToolbarStyle>    
    </RootStyle>
  );
}
