import * as React from 'react';
import {
    forwardRef
} from 'react';
import PropTypes from 'prop-types';
import axios from "axios";
import toast from "react-hot-toast";
import CircularProgress from '@mui/material/CircularProgress';
import { useState, useEffect } from 'react';
// material
import {
  Stack,
  Button,
  Typography,
  Container,Modal,Box
} from '@mui/material';
// components
import Iconify from '../components/Iconify';
import "./style.css"
import {userDetails} from '../constants'

const MatchAttachmentUpload = forwardRef(({
            children,
            matchId,
            ...other
        }, ref) => {

  const [attachmentOpen, setAttachmentOpen] = React.useState(true);
  const [selectedFile, setselectedFile] = useState([]);
  const [selectedFileName, setselectedFileName] = useState([]);
  const [isSubmitting, setisSubmitting] = React.useState(false);

  useEffect(() => {
    setAttachmentOpen(true);
  }, []);

 const onChangeHandlerForZip = (e) => {
  try {
    
    let ext = e.target.files
    console.log(ext)
    let name = selectedFileName;
    let files = selectedFile;
    for (let i = 0; i < ext.length; i++) {
      if (ext[i].name.split(".")[1].toLowerCase() !== "png" && ext[i].name.split(".")[1].toLowerCase() !== "jpg" && ext[i].name.split(".")[1].toLowerCase() !== "jpeg" && ext[i].name.split(".")[1].toLowerCase() !== "pdf") {    
        toast.error("Please upload the file/image in .png/.jpeg/.jpg/.pdf format");
      }
      else if (ext[i].size >= 1 * 1024 * 1024){
        toast.error("Files size should be less than 1 MB");
      }
      else{  
        name.push(ext[i].name)
        files.push(ext[i])
        if(i+1 === ext.length){
          setselectedFileName([...name])  
          setselectedFile([...files]);
          // setAttachmentOpen(false);
          toast.success("Files Uploaded successfully!!");
        }
      }
    }
  } catch(e) {
    console.log("No File selected",e);
  }
};

const handleUpload = (data) => {
  if (selectedFile.length>0){
    setisSubmitting(true);
    const data1 = new FormData();
    data1.append("matchId", data);
    for (const single_file of selectedFile) {
      data1.append('file', single_file)
    }
    
    axios
    .post(`/api/matches/uploadFile`, data1)
    .then((res) => {
    //   console.log(res)
      if (res.status === 200) {
        setAttachmentOpen(false);
        setisSubmitting(false)
        setselectedFile('')
        toast.success(res.data);
      } else {
        toast.error("Error in uploading the file");
      }
    })
    .catch((e) => {
      setAttachmentOpen(false);
      setisSubmitting(false)
      // console.log(e.response.data);
      toast.error(e.response.data);
    });
  }else{
    toast.error("No files selected");
  }
};

const removeAttachment = (name) =>{
  
  const indexOfObject = selectedFileName.findIndex(object => {
    return object === name;
  });
  selectedFileName.splice(indexOfObject, 1);
  setselectedFileName([...selectedFileName])
  const indexOfObject1 = selectedFile.findIndex(object1 => {
    return object1.name === name;
  });
  selectedFile.splice(indexOfObject1, 1);
  console.log(selectedFile);
  setselectedFile([...selectedFile])

}

  return (<div>
      
      <Modal
          open={attachmentOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          // sx={{width:{md:'50%'}}}
          >         
          <Box
            component="form"
            sx = {{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}
            noValidate
            autoComplete="off"
          >
            <Typography id="modal-modal-title" variant="h6" component="h2" mb={2}>
                Upload the files ( supported formats png, jpeg, jpg, pdf)
            </Typography>            
              <input
                accept = "image/png, image/jpeg,image/jpg, .pdf "
                style={{ display: 'none' }}
                id="raised-button-file"
                onChange={onChangeHandlerForZip}
                type="file"
                name="file"
                multiple
              />
                <label htmlFor="raised-button-file">                              
                <Button variant="contained" component="span" mb={2} mt={2} startIcon={<Iconify icon="entypo:upload" />}>
                    Upload
                </Button>             
                </label> 
                 <Typography variant="body1" mt={1} >
                  {selectedFileName.length>0 ? 
                    selectedFileName.map((item)=>{                      
                      return (
                        <Stack alignItems="center" direction={{ xs: 'column', sm: 'row' }}  >
                        <Typography id="modal-modal-title" variant="h6" component="h2" >
                          {item}
                        </Typography> 
                        <Iconify
                          icon = "ep:close-bold"
                          sx={{ '&:hover': { cursor: 'pointer', color: 'red' } }}
                          width={32}
                          height={28}
                          onClick={()=>{removeAttachment(item)}}
                        />
                      </Stack>
                      ) 
                    })                  
                  : <>Choose files less than 1MB to upload</>}
                </Typography>
              <Stack alignItems="center" direction={{ xs: 'column', sm: 'row' }} mt={2} >
                <Button variant="outlined" onClick={()=>{handleUpload(matchId)}}>Submit</Button>
                <Button variant="outlined" color="error" onClick={()=>{setAttachmentOpen(false)}} >Close</Button>
              </Stack>  
          </Box>
          </Modal>
          {isSubmitting && (
          <Modal
            open={isSubmitting}
          >         
            <Box
              component="form"
              sx = {{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '80%',
                height:'80%',
                bgcolor: 'background.paper',
              }}
              noValidate
              autoComplete="off"
            >
          <CircularProgress
            size={24}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              bgcolor: 'background.paper',
            }}
          />
          </Box>
          </Modal>
        )}
        </div>
      );
});
MatchAttachmentUpload.propTypes = {
    children: PropTypes.node.isRequired,
    matchId: PropTypes.string,
};
export default MatchAttachmentUpload;